import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Accordion from './Accordion';
import {toast} from 'react-toastify';
import LoadingSpinner from './LoadingSpinner';
import { TrashIcon } from '@heroicons/react/24/outline';
import CurrencyFormat from './CurrencyFormat';
import { debounce } from 'lodash';



const EstimateFCSAdd = ({ pageTitle, items, log }) => {
  const [selected, setSelected] = useState([]);
  const [quantity, setQuantity] = useState({});
  const [components, setComponents] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [openIndex, setOpenIndex] = useState(null); // State for left column
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [updateList, setUpdateList] = useState([]); // State for right column


  const toggleAccordion = useCallback((index) => {
    if (typeof index !== 'number') {
      //console.error('Invalid index passed to toggleAccordion:', index);
      return;
    }
    setOpenIndex(openIndex === index ? null : index);
  }, [openIndex]);

  const handleDelete = async (id, event) => {
     event.stopPropagation();
    try {
      const response = await axios.post(`${apiUrl}/estimate_crud.php`, {
        status: 'deleteDeviceSelectedFSD',
        data: { added_id: id },
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data[0] === 'success') {
        toast.success('Deleted', {
          position: 'top-center',
        });
        
      // Reset openIndex if necessary
      if (openIndex !== null && updateList[openIndex]?.id === id) {
        setOpenIndex(null);
      }
      setUpdateList((prevList) => prevList.filter((item) => item.id !== id));

      } else {
        toast.error(response.data.message || 'Failed to delete.', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.', {
        position: 'top-center',
      });
    }
  };

  const selectedAdd = async (deviceID, quantity) => {
    try {
      const response = await axios.post(`${apiUrl}/estimate_crud.php`, {
        status: 'addEsDevice',
        estimate_id: id,
        data: { deviceID: deviceID, quantity: quantity },
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.data[0] === 'success') {
        toast.success('Device Added', {
          position: 'top-center',
        });

        // Assuming the response contains the added item, you can append it to the updateList
        const addedItem = response.data[1]; // Adjust this according to your actual API response structure
        
        setUpdateList((prevList) => [...prevList, addedItem]);
           // Remove the selected item from the components list
        setComponents((prevComponents) =>
          prevComponents.map((component) => ({
            ...component,
            list: component.list.filter((item) => item.id !== deviceID),
          })).filter(component => component.list.length > 0) // Remove empty components
        );

        // Optionally reset openIndex if needed
        if (openIndex !== null && updateList[openIndex]?.id === id) {
          setOpenIndex(null);
        }

      } else {
        toast.error(response.data.message || 'Failed to add device.', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.', {
        position: 'top-center',
      });
    }
  };

  const handleQuantityChange = debounce((id, value) => {
    setUpdateList((prevList) =>
      prevList.map((item) =>
        item.id === id ? { ...item, quantity: value } : item
      )
    );
  }, 200);

  const updateQty = async (id, quantity) => {
    try {
      const response = await axios.post(`${apiUrl}/estimate_crud.php`, {
        status: 'updateDeviceSelectedFSD',
        data: { id: id, quantity: quantity },
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data[0] === 'success') {
        toast.success('Updated', {
          position: 'top-center',
        });
       
        if (openIndex !== null && updateList[openIndex]?.id === id) {
          setOpenIndex(null);
        }
        setUpdateList((prevList) =>
          prevList.map((item) =>
            item.id === id ? { ...item, price: response.data[1] } : item
          )
        );        
      } else {
        toast.error(response.data.message || 'Failed to update quantity.', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.', {
        position: 'top-center',
      });
    }
  };

  useEffect(() => {
    const fetchEstimates = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/estimate_crud.php`, {
          params: {
            fsd_component: id,
          },
        });
        //console.log(response.data);
        setComponents(response.data[1]);
      } catch (error) {
        toast.error('Oops minor issue with the data.', {
          position: 'top-center',
        });

        if (error.response && error.response.status === 401) {
          toast.error('Session expired. Please login again.', {
            position: 'top-center',
          });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
            position: 'top-center',
          });
        }
      } finally {
        setLoading(false);
      }
    };

  fetchEstimates();
  }, [apiUrl, id, navigate]);

  useEffect(() => {
    const fetchUpdatedEstimates = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/estimate_crud.php`, {
          params: {
            fsd_component_add: id,
          },
        });
        //console.log(response.data, 'updated list');
        setUpdateList(response.data[1] || []);
      } catch (error) {
        toast.error('Oops minor issue with the data.', {
          position: 'top-center',
        });

        if (error.response && error.response.status === 401) {
          toast.error('Session expired. Please login again.', {
            position: 'top-center',
          });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
            position: 'top-center',
          });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUpdatedEstimates();
  }, [apiUrl, id, navigate]);

  return (
    <>
      <div className="max-w-[1200px] mx-auto grid grid-cols-1 md:grid-cols-2 gap-x-[2px]">
        {/* Left Column */}
        <div className="">
          <h2 className="text-xl font-bold p-6">Panel Components</h2>
          {loading ? (
            <LoadingSpinner children={'Loading...'} />
          ) : (
            <div className="max-w-lg mx-auto mt-10">
              {components.map((component, index) => (
                <Accordion
                  key={index}
                  title={component.cat}
                  content={
                    <div className="divide-y-2 p-2">
                      {component.list.map((item) => (
                        <div key={item.id} className="grid grid-cols-[55%_15%_15%_15%] gap-2 py-2 items-center">
                          {/* Device and Description - 55% */}
                          <div>
                            <span className="font-bold underline">{item.device}</span>
                            <br />
                            <span className="text-xs">{item.description}</span>
                          </div>

                          {/* Price - 15% */}
                          <div>
                            <span>${item.price.toFixed(2)}</span>
                          </div>

                          {/* Quantity Input - 15% */}
                          <div>
                            <input
                              type="number"
                              className="border border-gray-300 rounded p-1 text-md w-16"
                              value={quantity[item.id] || ''}
                              onChange={(e) =>
                                setQuantity({ ...quantity, [item.id]: e.target.value })
                              }
                            />
                          </div>

                          {/* Add Button - 15% */}
                          <div>
                            <button
                              className="bg-blue-500 text-white text-md p-2 rounded w-12"
                              onClick={() => selectedAdd(item.id, quantity[item.id])}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                  isOpen={openIndex === index}
                  toggle={(e) => toggleAccordion(index)}
                />
              ))}
            </div>
          )}
        </div>

        {/* Right Column */}
        <div className="mt-20">
          {loading ? (
            <LoadingSpinner children={'Loading...'} />
          ) : (
            <>
              <div className="max-w-lg mx-auto mt-9">
                {updateList.map((item) => (
                  <div key={item.id}>
                    <div className="grid grid-cols-[55%_15%_15%_10%] gap-2 items-center bg-white border rounded-md p-2">
                      {/* Device and Price */}
                      <div className="truncate">
                        <div href={item.id} className="text-sm text-gray-900 hover:text-gray-600">
                          {item.device}
                        </div>
                        <p className="text-xs text-gray-500">
                          <CurrencyFormat value={item.price} />
                        </p>
                      </div>

                      {/* Quantity update Input */}
                      <div>
                        <input
                          type="number"
                          className="border border-gray-300 rounded p-1 text-md w-16"
                          value={item.quantity || ''}
                          onChange={(e) =>
                            handleQuantityChange(item.id, e.target.value)
                          }
                        />
                      </div>

                      {/* Update Button */}
                      <div>
                        <button
                          className="bg-green-500 text-white text-md p-2 rounded w-20"
                          onClick={(e) => updateQty(item.id, item.quantity)}
                        >
                          Update
                        </button>
                      </div>

                      {/* Delete Button */}
                      <div className="flex justify-end">
                        <button
                          type="button"
                          onClick={(e) => handleDelete(item.id, e)}
                          className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          <span className="sr-only">Delete item</span>
                          <TrashIcon className="h-5 w-5 text-red-600" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EstimateFCSAdd;
