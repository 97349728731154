import React, { useState, useEffect } from 'react';
import PhoneNumber from '../components/PhoneNumber';
import { NavLink } from 'react-router-dom';
import AvatarLocation from '../components/AvatarLocation';
import { CalendarIcon, DocumentMagnifyingGlassIcon, PencilIcon, PencilSquareIcon, TrashIcon, TruckIcon } from '@heroicons/react/24/outline';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid';
import BadgeFieldOffice from './BadgeFieldOffice';
import { useUserData } from '../context/UserData';

const EmployeeList = ({ searchTerm }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const userData = useUserData()

  function ylasttwo(dateString) {
    const year = new Date(dateString).getFullYear().toString();
    const lastTwoDigits = year.slice(-2);
    return lastTwoDigits;
  }

  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const res = await fetch(`${apiUrl}/employee_crud.php?getList=1`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        });
        
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        const data = await res.json();
        setEmployees(data);
        setFilteredEmployees(data);
      } catch (error) {
        console.log('Error fetching data', error);
      }
    };
    fetchEmployees();
  }, [apiUrl]);

  useEffect(() => {
    const filtered = employees.filter(employee =>
      employee.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      employee.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      employee.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      employee.department.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredEmployees(filtered);
  }, [searchTerm, employees]);

  return (
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 max-w-4xl">
      {filteredEmployees.map((employee) => (
        <li key={employee.id}
          className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
          <div className="flex flex-1 flex-col p-8">
            <span className='absolute text-gray-400 text-sm relative top-0'>
              ID: {employee.id}-{ylasttwo(employee.date_hire)}
            </span>
            <AvatarLocation className="mx-auto h-32 w-32 flex-shrink-0 rounded-full" avatar={employee.avatar} />
            <h3 className="mt-6 text-sm font-medium text-gray-900">{employee.first_name} {employee.last_name}</h3>
            <dl className="mt-1 flex flex-grow flex-col justify-between">
              <dt className="sr-only">Title</dt>
              <dd className="text-sm text-gray-500">{employee.title} <br/> <span className='text-blue-400'>{employee.department}</span></dd>
              <dd className="text-sm text-gray-500">{<PhoneNumber number={employee.phone} />}</dd>
              <dd className="text-sm text-gray-500">{employee.email}</dd>
              <dt className="sr-only">Role</dt>
              <dd className="mt-3">
                <BadgeFieldOffice location={employee.o_f_type} />
              </dd>
              <dd className="mt-3">
                {employee.fleet.vnumber ? (
                  <div className="flex items-center">
                    <TruckIcon className="h-5 w-5 text-gray-400" />
                    <span>{": " + employee.fleet.vnumber}</span>
                  </div>
                ) : (
                  ""
                )}
              </dd>
            </dl>
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              {userData?.users.department === 'Admin'? (
              <div className="flex w-0 flex-1">
                <NavLink to={`/employee_edit/${employee.id}`} className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center
                                          gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-800"
                >
                  <PencilIcon className="h-5 w-5 text-green-700" aria-hidden="true" />
                  Edit
                </NavLink>
              </div>
              ) : ('')}
              <div className="-ml-px flex w-0 flex-1">
                <NavLink to={`/profile/${employee.id}`} className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                >
                  <DocumentMagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  Profile
                </NavLink>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default EmployeeList;
