import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Accordion from './Accordion';
import {toast} from 'react-toastify';
import LoadingSpinner from './LoadingSpinner';
import { TrashIcon } from '@heroicons/react/24/outline';
import CurrencyFormat from './CurrencyFormat';
import { debounce } from 'lodash';


const EstimateAddNotes = () => {
  const [checkedItems, setCheckedItems] = useState({});
  const [loading, setLoading] = useState();
  const apiUrl = process.env.REACT_APP_API_URL;
  const {id} = useParams();
  const navigate = useNavigate();
  const [updateList, setUpdateList] = useState();
  const [serv, setServ] = useState([]);
  const [tnc, setTnc] = useState([]);
  const [ptnc, setPtnc] = useState([]);
  const [editMode, setEditMode] = useState(false);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/estimate_crud.php`, {
          params: {
            getSystemNotes: true,
          },
        });
        
        if (response.data[0] === 'success') {
          setServ(response.data[1]);
          setTnc(response.data[2]);
          setPtnc(response.data[3]);
          const notesResponse = await axios.get(`${apiUrl}/estimate_crud.php`, {
            params: {
              notesByEstimate: id,
            },
          });          
          if (notesResponse.data[1].length > 0) {
            const editValue = {};
            notesResponse.data[1].forEach(element => {
              const notesId = parseInt(element.notes_id, 10);
              editValue[notesId] = true;
            });
            setCheckedItems(editValue);
            setEditMode(true);
          }
        } else {
          toast.error('No Estimate', {
            title: 'Error',
            position: 'top-center',
          });
          navigate('/estimate/new');
        }
      } catch (error) {
        console.error(error);
        toast.error('Oops minor issue with the data.', {
          position: 'top-center',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiUrl, id, navigate]);

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [id]: !prevCheckedItems[id],
    }));
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    const bodyData = {
      noteList: checkedItems,
      status: 'addItemNotes',
      estimate_id: id,
      edit: editMode
    };
    
    try {
      const response = await axios.post(`${apiUrl}/estimate_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });
      
      if (response.data[0] === 'success') {
        toast.success('Note saved!', {
          position: 'top-center',
        });
        // navigate(`/installation_details/${id}`);
      } else {
        toast.error(response.data.error || 'Failed updating.', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error("Oops minor issue with the data.", {
      position: "top-center"
      });
      
      if (error.response && error.response.status === 401) {
      toast.error("Session expired. Please login again.", {
      position: "top-center"
      });
      navigate('/login');
      } else if (error.response && error.response.status === 403) {
      toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
      position: "top-center"
      });
      }
    }
  };

  return (
    <div className="mx-auto max-w-[800px] px-6 py-8 bg-white">
      <h3 className="border-b border-black text-md mb-2 font-semibold">Services Notes</h3>
      <form name="addingForm" onSubmit={handleSubmit} className="space-y-4">
        {serv.map((item) => (
          <div key={item.id} className="flex items-center border-b border-dotted border-gray-300 py-2">
            <input
              type="checkbox"
              checked={!!checkedItems[item.id]}
              onChange={() => handleCheckboxChange(item.id)}
              className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
            <p className="ml-4 text-sm text-gray-900">{`${item.id}: ${item.notes}`}</p>
          </div>
        ))}

        <h3 className="border-b border-black text-md mb-2 font-semibold mt-6">Term and Conditions Notes</h3>
        {tnc.map((item) => (
          <div key={item.id} className="flex items-center border-b border-dotted border-gray-300 py-2">
            <input
              type="checkbox"
              checked={!!checkedItems[item.id]}
              onChange={() => handleCheckboxChange(item.id)}
              className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
            <p className="ml-4 text-sm text-gray-900">{item.notes}</p>
          </div>
        ))}

        <h3 className="border-b border-black text-md mb-2 font-semibold mt-6">Payment Term and Conditions Notes</h3>
        {ptnc.map((item) => (
          <div key={item.id} className="flex items-center border-b border-dotted border-gray-300 py-2">
            <input
              type="checkbox"
              checked={!!checkedItems[item.id]}
              onChange={() => handleCheckboxChange(item.id)}
              className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
            <p className="ml-4 text-sm text-gray-900">{item.notes}</p>
          </div>
        ))}

        <div className="mt-6 flex justify-end">
          <button
            type="submit"
            className="btn btn-primary text-sm font-semibold leading-6 text-white bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default EstimateAddNotes;
