import { TrashIcon, PencilIcon, CheckIcon, PlusIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from "react";
import DateFormat from "./DateFormat";

const ProfileDaysListViewCp = ({ list, payPeriod, access, employee_id }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [dates, setDates] = useState(list || []);
  const [editMode, setEditMode] = useState({});
  const [newDate, setNewDate] = useState({
    date_out: "",
    date_end: "",
    date_request: "Vacation Day",
    status: "",
    notes: ""
  });

  useEffect(() => {
    setDates(list);
  }, [list, access]);

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };

  const getColorForEmployee = (event) => {
    const colors = {
      'Vacation Day': 'bg-blue-400',
      'in': 'bg-green-400',
      'No Pay': 'bg-red-400',
      'Excused Pay': 'bg-yellow-400',
      'Partial Hour': 'bg-purple-400',
      'Sick Day': 'bg-pink-400',
      'Half Vacation Day': 'bg-orange-400'
    };
    return colors[event];
  };

  const handleDeleteDate = async (date_id) => {
    try {
      const response = await axios.post(`${apiUrl}/attendance_crud.php`, {
        status: 'delete_vacation_dates',
        data: { date_id: date_id },
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        toast.success("Date deleted successfully!", {
          position: "top-center"
        });
        setDates(dates.filter(date => date.id !== date_id));
      } else {
        toast.error(response.data.message || "Failed to delete date.", {
          position: "top-center"
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.", {
        position: "top-center"
      });
    }
  }

  const handleEditToggle = (id) => {
    setEditMode((prevEditMode) => ({
      ...prevEditMode,
      [id]: !prevEditMode[id]
    }));
  };

  const handleInputChange = (id, name, value) => {
    setDates((prevDates) => 
      prevDates.map((date) =>
        date.id === id ? { ...date, [name]: value } : date
      )
    );
  };

  const handleSave = async (date) => {
    try {
      const response = await axios.post(`${apiUrl}/attendance_crud.php`, {
        status: 'update_vacation_dates',
        data: date,
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.data[0]==='success') {
        toast.success("Date updated successfully!", {
          position: "top-center"
        });
        setEditMode((prevEditMode) => ({
          ...prevEditMode,
          [date.id]: false
        }));
      } else {
        toast.error(response.data.message || "Failed to update date.", {
          position: "top-center"
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.", {
        position: "top-center"
      });
    }
  }

  const handleNewDateChange = (e) => {
    const { name, value } = e.target;
    setNewDate((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddNewDate = async () => {
    if(newDate.date_out ==='' || newDate.date_end === ''){
       toast.error("missing dates", {
         position: "top-center"
        });
      return
    }
    if(newDate.date_out === newDate.date_end && newDate.date_request === 'Vacation Day'){
       toast.error("date out and date end cant be the same date", {
         position: "top-center"
        });
      return
    }

    try {
      const response = await axios.post(`${apiUrl}/attendance_crud.php`, {
        status: 'add_vacation_dates_no_pay',
        data: newDate,
        employee_id: employee_id,
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data[0] === 'success') {
        toast.success("Date added successfully!", {
          position: "top-center"
        });
        
        if(newDate.date_request === 'No Pay')
        {
          window.location.reload()
        }
        else if(newDate.date_request === 'Excused Pay')
        {
          window.location.reload()
        }
        else if(newDate.date_request === 'Sick Day')
        {
          window.location.reload()
        }
        else 
        {
           setDates([...dates, { ...response.data[1], id: response.data[1].id }]);
        }
        setNewDate({
          date_out: "",
          date_end: "",
          date_request: "Vacation Day",
          status: "",
          notes: ""
        });
      } else {
        toast.error(response.data.message || "Failed to add date.", {
          position: "top-center"
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.", {
        position: "top-center"
      });
    }
  };

  return (
    <div>
      
      <div className="mt-8 flow-root overflow-hidden">
        <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
          <p>YTD</p><br />
          <table className="w-full text-left mb-20">
            <thead className="bg-white  border-b border-b-gray-200">
              <tr>
                <th scope="col" className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 px-3">
                  {access && ('Edit')}
                </th>

                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Date Request
                </th>
                <th scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Date out
                </th>
                <th scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell">
                  Date end
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell">
                  PH
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell">
                  Status
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell">
                  Notes
                </th>
                {access ? (
                  <th scope="col" className="relative py-3.5 pl-3 px-3">
                    <span>Edit</span>
                  </th>
                ) : ('')}
              </tr>
            </thead>
            <tbody>

              {dates?.map((date_request, index) => (
                <tr key={date_request.id} className={`divide-y-2 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                  
                  {/* edit mode*/}
                  <td className="relative text-sm font-medium text-gray-900">
                    {access && (  
                    <TrashIcon
                        className="h-5 text-red-700 hover:cursor-pointer hover:text-green-500 px-4"
                        onClick={(e) => handleDeleteDate(date_request.id, e)}
                      />)}
                  </td>

                  <td className={classNames(`px-3 py-4 text-sm text-gray-800`, getColorForEmployee(date_request.date_request))}>
                    {editMode[date_request.id] ? (
                      <select
                        name="date_request"
                        value={date_request.date_request}
                        disabled={true}
                        onChange={(e) => handleInputChange(date_request.id, e.target.name, e.target.value)}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      >
                        <option value="Vacation Day">Vacation Day</option>
                        <option value="No Pay">No Pay</option>
                        <option value="Excused Pay">Excused Pay</option>
                        <option value="Sick Day">Sick/Flex</option>
                        <option value="Half Vacation Day">1&frasl;2 Vac Day</option>
                        <option value="Half Sick Day">1&frasl;2 Sick Day</option>
                      </select>
                    ) : (
                      <>
                        <p>{date_request.date_request}</p>
                        <p>{date_request.partial_hours > 0? ('PH('+date_request.partial_hours+') hrs') : ('')}</p>
                      </>
                    )}
                  </td>

                  {/* date  out*/}
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {editMode[date_request.id] ? (
                      <input
                        type="date"
                        name="date_out"
                        value={date_request.date_out}
                        disabled={date_request.date_request === 'No Pay' || 
                                  date_request.date_request === 'Excused Pay' || 
                                  date_request.date_request === 'Sick Day' || 
                                  date_request.date_request === 'Half Vacation Day'}
                        onChange={(e) => handleInputChange(date_request.id, e.target.name, e.target.value)}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    ) : (
                      <>
                        <DateFormat date={date_request.date_out} /><br/>
                       <span className="sm:hidden"> 
                          <DateFormat date={date_request.date_end} />
                        </span>
                      </>
                    )}
                  </td>

                  {/* date end */}
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {editMode[date_request.id] ? (
                      <input
                        type="date"
                        name="date_end"
                        value={date_request.date_request === 'Half Vacation Day' ? date_request.date_end : date_request.date_out ||
                               date_request.date_request === 'Sick Day' ? date_request.date_end : date_request.date_out}
                        disabled={date_request.date_request === 'No Pay' ||  
                                  date_request.date_request === 'Excused Pay' || 
                                  date_request.date_request === 'Half Vacation Day' ||
                                  date_request.date_request === 'Sick Day'}
                        onChange={(e) => handleInputChange(date_request.id, e.target.name, e.target.value)}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    ) : (
                       <DateFormat date={date_request.date_end} />
                    )}
                  </td>

                  {/* PH */}
                  <td className={classNames(`px-3 py-4 text-sm text-gray-800 text-center`)}>
                    {editMode[date_request.id] ? (
                      <input
                        type="number"
                        name="partial_hours"
                        value={date_request.partial_hours}
                        onChange={(e) => handleInputChange(date_request.id, e.target.name, e.target.value)}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    ) : (
                      date_request.partial_hours ? (
                        <span>{date_request.partial_hours}</span>
                      ) : (
                        ''
                      )
                    )}
                  </td>

                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {date_request.status}
                  </td>

                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {editMode[date_request.id] ? (
                      <input
                        type="text"
                        name="notes"
                        value={date_request.notes}
                        onChange={(e) => handleInputChange(date_request.id, e.target.name, e.target.value)}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    ) : (
                      date_request.notes
                    )}
                  </td>

                  {access ? (
                    <td className="relative py-4 pl-3 text-right text-sm font-medium">
                      {editMode[date_request.id] ? (
                        <CheckIcon
                          className="h-5 text-green-700 hover:cursor-pointer hover:text-green-500"
                          onClick={() => handleSave(date_request)}
                        />
                      ) : (
                        <PencilIcon
                          className="h-5 text-blue-700 hover:cursor-pointer hover:text-blue-500"
                          onClick={() => handleEditToggle(date_request.id)}
                        />
                      )}
                    </td>                  
                  ) : ('')}

                </tr>
              ))}
              
              {access && (
                <tr className="divide-y-2 bg-gray-100">

                  <td className="relative py-4 pr-3 text-sm font-medium text-gray-900">
                    <PlusIcon
                      className="h-5 text-green-700 hover:cursor-pointer hover:text-green-500"
                      onClick={handleAddNewDate}
                    />
                  </td>

                  <td className="px-3 py-4 text-sm text-gray-500">
                    <select
                      name="date_request"
                      value={newDate.date_request}
                      onChange={handleNewDateChange}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="Vacation Day">Vacation Day</option>
                      <option value="Half Vacation Day">Half Vacation</option>
                      <option value="Half Sick Day">Half Sick</option>
                      <option value="No Pay">No Pay</option>
                      <option value="Excused Pay">Excused Pay</option>
                      <option value="Sick Day">Sick/Flex</option>
                      <option value="Partial Hour">Partial Hrs</option>
                    </select>
                  </td>
                  
                  <td className="px-3 py-4 text-sm text-gray-500">
                    <input
                      type="date"
                      name="date_out"
                      value={newDate.date_out}
                      onChange={handleNewDateChange}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </td>

                  {newDate.date_request === 'Half Vacation Day' || newDate.date_request === 'Sick Day' || newDate.date_request === 'Partial Hour'  || newDate.date_request === 'Half Sick Day' ? (
                    <td>
                      { <DateFormat date={newDate.date_end = newDate.date_out}/>}
                    </td>
                  ) : (<>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      <input
                        type="date"
                        name="date_end"
                        value={newDate.date_end}
                        onChange={handleNewDateChange}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </td>
                  </>)}

                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                  {newDate.date_request === 'Half Vacation Day' || newDate.date_request === 'Partial Hour'  || newDate.date_request === 'Half Sick Day' ? (
                    <input
                      type="number"
                      name="partial_hours"
                      value={newDate.partial_hours}
                      onChange={handleNewDateChange}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  ) :
                  ('')}
                  </td>

                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    <input
                      type="text"
                      name="status"
                      value="Approved"
                      disabled={true}
                      onChange={handleNewDateChange}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </td>

                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    <input
                      type="text"
                      name="notes"
                      value={newDate.notes}
                      onChange={handleNewDateChange}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </td>

                  <td className="relative py-4 pl-3 text-right text-sm font-medium">
                    {/* <CheckIcon
                      className="h-5 text-green-700 hover:cursor-pointer hover:text-green-500"
                      onClick={handleAddNewDate}
                    /> */}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ProfileDaysListViewCp;
