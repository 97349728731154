import { CurrencyDollarIcon } from '@heroicons/react/24/outline';
import React from 'react';

const ServiceInvoiceFileLocation = ({ expFile, where }) => {
  const appRoot = process.env.REACT_APP_ROOT_URL;

  // Check if expFile exists and has a .pdf extension
  if (expFile && expFile.toLowerCase().endsWith('.pdf')) {
    const expFileUrl = `${appRoot}${where}/${expFile}`;
    return (
      <a href={expFileUrl} target="_blank" rel="noopener noreferrer">
        <CurrencyDollarIcon className="h-5" />
      </a>
    );
  }

  return null; // Return nothing if the file does not exist or is not a .pdf
};

export default ServiceInvoiceFileLocation;
