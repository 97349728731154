import React from 'react';
import { format, parseISO } from 'date-fns';

const DateFormat = ({ date }) => {
  if(!date)
  {
    return null
  }
  const formattedDate = format(parseISO(date), 'MMMM dd, yyyy');
  return <span>{formattedDate}</span>;
};

export default DateFormat;
