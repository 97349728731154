import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeadingCp from '../components/HeadingCp';
import EstimateBar from '../components/EstimateBar';
import CurrencyFormat from '../components/CurrencyFormat';

const EstimateProposal = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { id } = useParams();
  const navigate = useNavigate();
  const [estimate, setEstimate] = useState({});
  const [notesServiceResult, setNotesServiceResult] = useState([]);
  const [notesTNC, setNotesTNC] = useState([]);
  const [notesPTNC, setNotesPTNC] = useState([]);
  const [serviceCustom, setServiceCustom] = useState([]);
  const [TNCCustom, setTNCCustom] = useState([]);
  const [PTNCCustom, setPTNCCustom] = useState([]);
  const [totalSellingPrice, setTotalSellingPrice] = useState('');
  const [alterPrice, setAlterPrice] = useState({});
  const [fdNumber, setFdNumber] = useState(2);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/estimate_crud.php`, {
          params: { getDetails: id },
        });
        
        if (response.data[0] === 'success') {
          const estimateData = response.data[1];
          setEstimate(estimateData);
          setFdNumber(estimateData.totalDGP > 0 ? 3 : 2);
          setNotesServiceResult(response.data[4] || []);
          setTotalSellingPrice(response.data[7] || '');
          setNotesTNC(response.data[5] || []);
          setNotesPTNC(response.data[6] || []);
          setServiceCustom(response.data[8] || []);
          setTNCCustom(response.data[9] || []);
          setPTNCCustom(response.data[10] || []);
          setAlterPrice(response.data[3] || {});
        } else {
          toast.error('No data found', {
            position: 'top-center',
          });
        }
      } catch (error) {
        toast.error('Oops minor issue with the data.', {
          position: 'top-center',
        });

        if (error.response && error.response.status === 401) {
          toast.error('Session expired. Please login again.', {
            position: 'top-center',
          });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
            position: 'top-center',
          });
        }
      }
    };

    if (id) {
      fetchData();
    }
  }, [id, apiUrl, navigate]);

  return (
    <div className="mx-auto max-w-[1200px] sm:px-6 lg:px-6 py-8 bg-white">
      <EstimateBar id={id} name={'Proposal'} />
      <HeadingCp label={"Estimate Proposal"} />
      <div className="mx-auto max-w-[900px] border p-5">
        {/* Instruction Notes */}
        {estimate.instruction_notes && (
          <>
            <span className="text-blue-600"><b>- Instruction Notes:</b> {estimate.instruction_notes}</span>
            <br /><br />
          </>
        )}

        {/* Notifier Discount */}
        <span className="text-green-600"><b>{estimate.notifier_discount}% Notifier Discount</b></span>
        <br /><br />

        {/* Building Type */}
        <span className="text-gray-700"><b>Building Type: {estimate.building_type}</b></span>
        <br /><br />

        {/* Contact Information */}
        <p className="mt-4">{new Date().toLocaleDateString()}</p>
        <p><b>ATTN: {estimate.att}</b><br /><br />
          {estimate.contact_name} <br />
          {estimate.contact_address} <br />
          {estimate.contact_city}, {estimate.contact_state} {estimate.contact_zip || ''}
        </p>

        <br />
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; RE: {estimate.bidding_address} | {estimate.job_description}</b><br /><br />

        <p>Dear {estimate.att},</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We respectfully submit our proposal, for your consideration and approval to {estimate.supply?.toLowerCase()} fire alarm equipment for the above referenced project.</p>
        <br />
        <b>AILERON SYSTEMS SHALL PROVIDE THE FOLLOWING:</b>
        <br /><br />

        {/* Equipment Section */}
        <div className="bg-gray-200 p-2"><b>A. EQUIPMENT:</b></div>
        <br />
        <div className="ml-5 border-b border-gray-300">
          <b>1. &nbsp;FIRE COMMAND STATION</b><br />
          {estimate.supply} one (1) Notifier fire alarm {estimate.system_type} complete with fused disconnect switch and battery backup.
        </div>
        <br />

        {estimate.totalDGP && (
          <div className="ml-5 border-b border-gray-300">
            <b>2. &nbsp;DGP </b><br />
            {estimate.supply} <mark>XXXX</mark> ({estimate.backBoxDGP}) DGP{estimate.backBoxDGP > 1 && "'s"}:
          </div>
        )}
        <br />

        {estimate.totalFieldQty > 0 && (
          <div className="ml-5">
            <b>3. &nbsp;FIELD DEVICES</b><br />
            {estimate.supply} the following field devices:<br /><br />

            {estimate.fieldDevicesCat?.[0] > 0 && (
              <div className="ml-5">
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NOTIFICATIONS</b>
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-1"><b>Quantity</b></div>
                  <div className="col-span-2"><b>Description</b></div>
                </div>
                {estimate.fieldDevices?.[0]?.NOTIFICATIONS?.map((device, index) => (
                  <div key={index} className="grid grid-cols-3 gap-4">
                    <div className="col-span-1">{device.quantity}</div>
                    <div className="col-span-2">{device.device}</div>
                  </div>
                ))}
              </div>
            )}

            {estimate.fieldDevicesCat?.[1] > 0 && (
              <div className="ml-5">
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DETECTORS</b>
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-1"><b>Quantity</b></div>
                  <div className="col-span-2"><b>Description</b></div>
                </div>
                {estimate.fieldDevices?.[1]?.DETECTORS?.map((device, index) => (
                  <div key={index} className="grid grid-cols-3 gap-4">
                    <div className="col-span-1">{device.quantity}</div>
                    <div className="col-span-2">{device.device}</div>
                  </div>
                ))}
              </div>
            )}

            {estimate.fieldDevicesCat?.[2] > 0 && (
              <div className="ml-5">
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CONTROL MODULES</b>
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-1"><b>Quantity</b></div>
                  <div className="col-span-2"><b>Description</b></div>
                </div>
                {estimate.fieldDevices?.[2]?.CONTROL_MODULE?.map((module, index) => (
                  <div key={index} className="grid grid-cols-3 gap-4">
                    <div className="col-span-1">{module.quantity}</div>
                    <div className="col-span-2">{module.device}</div>
                  </div>
                ))}
              </div>
            )}

            {estimate.fieldDevicesCat?.[3] > 0 && (
              <div className="ml-5">
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MONITOR MODULES</b>
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-1"><b>Quantity</b></div>
                  <div className="col-span-2"><b>Description</b></div>
                </div>
                {estimate.fieldDevices?.[3]?.MONITOR_MODULES?.map((module, index) => (
                  <div key={index} className="grid grid-cols-3 gap-4">
                    <div className="col-span-1">{module.quantity}</div>
                    <div className="col-span-2">{module.device}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        <br />
        {/* Services Section */}
        <div className="bg-gray-200 p-2"><b>B. SERVICES:</b></div>
        <br />

        {notesServiceResult?.map((row, index) => (
          <span key={index}>{index + 1}. &nbsp;{row.notes}<br /></span>
        ))}

        {serviceCustom?.map((row, index) => (
          <span key={index}>{index + 1}.&nbsp;{row.notes}<br /></span>
        ))}

        <br />
        <b>PRICE:</b>&nbsp;&nbsp;The price to provide the aforementioned shall = 
        <b>{totalSellingPrice?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</b> 
        <mark>(XXXXXXX)</mark>. Sales tax is not included.

        {alterPrice?.alternative_price && (
          <div>
            <b>* ALTERNATIVE PRICE:</b><b>&nbsp;&nbsp;&nbsp;{alterPrice.alternative_price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</b>
            &nbsp;&nbsp;&nbsp;description: {alterPrice.description}
          </div>
        )}

        <br /><br />
        <b>TERMS AND CONDITIONS</b><br />
        {notesTNC?.map((row, index) => (
          <span key={index}>{index + 1}. &nbsp;{row.notes}<br /></span>
        ))}
        {TNCCustom?.map((row, index) => (
          <span key={index}>{index + 1}.&nbsp;{row.notes}<br /></span>
        ))}
        <br /><br />

        <b>PAYMENT TERMS & CONDITIONS</b><br />
        <p>1. &nbsp;Capital improvement project, contractor exempt purchase etc. (certificate to be supplied prior to release of any equipment/services).</p>
        <p>2. &nbsp;Tax exempt organization (certificate to be supplied prior to release of any equipment/services).</p>
        <p>3. &nbsp;If project is tax exempt and certificates are not received, the entity to which the goods and services outlined above are being provided shall be liable for payment of said taxes.</p>
        {notesPTNC?.map((row, index) => (
          <span key={index}>{index + 4}. &nbsp;{row.notes}<br /></span>
        ))}
        {PTNCCustom?.map((row, index) => (
          <span key={index}>{index + 4}.&nbsp;{row.notes}<br /></span>
        ))}
        
        <br /><br />
        <span>Thank you for allowing us the opportunity to submit our proposal. Should you have any questions please do not hesitate to contact me.</span>
        <br /><br /><br />
        <span>Sincerely,</span>
      </div>
    </div>
  );
};

export default EstimateProposal;
