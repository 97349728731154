import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TrashIcon } from '@heroicons/react/24/outline';
import HeadingCp from '../components/HeadingCp';
import EstimateBar from '../components/EstimateBar';
import CurrencyFormat from '../components/CurrencyFormat';



const EstimateDetail = () => {
const apiUrl = process.env.REACT_APP_API_URL;
const {id} = useParams();
const [estimate, setEstimate] = useState('');
const {navigate} = useNavigate();
const [result, setResult] = useState('');
const hourlyRate = 118.0;
const laborItems = [
  { description: 'DRAWINGS', data: estimate.Drawings },
  { description: 'PROGRAMMING', data: estimate.Programming },
  { description: 'TURN-ON', data: estimate.TurnOn },
  { description: 'DELIVERY', data: estimate.Delivery },
  { description: 'MEETINGS', data: estimate.Meeting },
  { description: 'FINAL TEST', data: estimate.FinalTest },
  { description: 'FCS /FACP WIRING', data: estimate.FCS },
  { description: 'DGP WIRING', data: estimate.DGP },
  { description: 'FIRE DEPARTMENT TEST', data: estimate.fireDeptTest },
  { description: 'TRAINING', data: estimate.training },
  { description: 'TERMINATE TEST COT', data: estimate.testCot },
];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/estimate_crud.php`, {
          params: { getDetails: id },
        });
        if (response.data[0] === 'success') {
          setEstimate(response.data[1]);
          setResult(Object.entries(response.data[2]) || '');
        }
      } catch (error) {
        toast.error("Oops minor issue with the data.", {
        position: "top-center"
        });

        if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
        navigate('/login');
        } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
        });
        }
      }
    };

    if (id) {
      fetchData();
    }
  }, [id, apiUrl]);  



  return (
    <div className="mx-auto max-w-[1200px] sm:px-6 lg:px-6 py-8 bg-white">
      <EstimateBar id={id} name={'Detail'} />
      <HeadingCp label={"Estimate Details"} />
      <div className='mx-auto max-w-[1000px]'>

        <div className="sm:flex sm:items-center mb-8">
          <div className="sm:flex-auto">
            <p className="text-sm text-gray-700">
              Detailed breakdown of the estimate including services, labor, equipment, and subcontractor details.
            </p>
          </div>
        </div>

        <div className='mb-4'>
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            {estimate.bidding_address}
          </h3>
          <p className="max-w-2xl text-sm leading-6 text-gray-500">
            {estimate.contact_address}, {estimate.contact_city} {estimate.contact_state} {estimate.contact_zip}
          </p>
        </div>


        <div className='text-gray-500 border-b mb-6'>
          <p className='text-gray-800'>{estimate.supply}</p>
          <p className='text-gray-800'>{estimate.system_type}</p>  
        </div>

        <div className='text-gray-700 border-b mb-6'>
          <p>{estimate.job_mark_up ? ('Mark Up: ' + estimate.job_mark_up + '%') : ('')}</p>
          <p>{estimate.sub_contract_mark_up ? ('Sub Mark Up: ' + estimate.sub_contract_mark_up + '%') : ('')}</p>
          <p>{estimate.notifier_discount ? ('Notifier Discount: ' + estimate.notifier_discount + '%') : ('')}</p>
          <p>{estimate.union_labor ? ('Labor: ' + estimate.union_labor) : ('')}</p>
          {/* {estimate.wire_supply && <p>WIRE SUPPLY BY: <b>{estimate.wire_supply}</b><br /></p>} */}
        </div>

          {/* SERVICES */}
          {estimate.serviceSection && (
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 sm:px-3 p-6 max-w-[1000px]">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-400 border border-gray-200">
                  <thead>
                    <tr>
                      <th scope="col"
                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:px-3">
                        Service
                      </th>
                      <th scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    <tr>
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:px-3 border border-gray-300 px-3">
                        {estimate.serviceSection.filling}
                      </td>

                      <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 border border-gray-300 px-3">
                        <CurrencyFormat value={estimate.serviceSection.fillingPrice}/>
                      </td>
                    </tr>
                    <tr>
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pr-3 border border-gray-300 px-3">
                        {estimate.serviceSection.tco}
                      </td>
                      <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 border border-gray-300 px-3">
                        <CurrencyFormat value={estimate.serviceSection.tcoPrice}/>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot className='bg-slate-100'>
                    <tr>
                      <td colSpan="2" className="whitespace-nowrap py-2 text-center text-green-600 border border-gray-300 px-3">
                        Total <CurrencyFormat value={estimate.totalCostService}/>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          )}

          {estimate.totalFCS && (
          <>
            {/* LABOR DETAILS */}
            <div className="pb-8 mt-10">
              <h4 className='font-bold p-2'>Labor Details</h4>
              <div className='text-gray-500 border-b mb-6'>
                <p className='text-gray-800'>Total Hours: {Math.round(estimate.totalLaborHours)}</p>
                <p className='text-gray-800'>Total Field Devices: {estimate.totalFieldQty}</p>  
              </div>
              <div className="pb-10">
                <h4 className="font-bold p-2">Labor Details</h4>
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 sm:px-3 p-3 max-w-[1000px]">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-400 border border-gray-200">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:px-3"
                          >
                            Description
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Hourly Rate
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Hours
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {laborItems.map((item, index) => (
                          <tr key={index}>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:px-3 border border-gray-300 px-3">
                              {item.description}
                            </td>
                            <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 border border-gray-300 px-3">
                              <CurrencyFormat value={hourlyRate} />
                            </td>
                            <td className="whitespace-nowrap py-2 text-sm text-gray-500 border border-gray-300 px-3">
                              {item.data.hours.toFixed(2)}
                            </td>
                            <td className="whitespace-nowrap py-2 text-sm text-gray-500 border border-gray-300 px-3">
                              <CurrencyFormat value={item.data.cost} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot className="bg-slate-100">
                        <tr>
                          <td
                            colSpan="4"
                            className="whitespace-nowrap py-2 text-center text-green-600 border border-gray-300 px-3"
                          >
                            <b>Total <CurrencyFormat value={estimate.totalLaborCost} /></b>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* EQUIPMENT DETAILS */}
            <div className="pb-10">
              <h4 className="font-bold p-2">Equipment Details</h4>
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 sm:px-3 p-6 max-w-[1000px]">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-400 border border-gray-200">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:px-3"
                        >
                          Equipment
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      <tr>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:px-3 border border-gray-300 px-3">
                          FCS /FAPT COMPONENTS
                        </td>
                        <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 border border-gray-300 px-3">
                          <CurrencyFormat value={estimate.totalFCS} />
                        </td>
                      </tr>
                      <tr>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:px-3 border border-gray-300 px-3">
                          FIELD DEVICES COST
                        </td>
                        <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 border border-gray-300 px-3">
                          <CurrencyFormat value={estimate.totalFDEVICE} />
                        </td>
                      </tr>
                      <tr>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:px-3 border border-gray-300 px-3">
                          DATA GATHERING PANEL COST
                        </td>
                        <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 border border-gray-300 px-3">
                          <CurrencyFormat value={estimate.totalDGP} />
                        </td>
                      </tr>
                      <tr>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:px-3 border border-gray-300 px-3">
                          EQUIPMENT COST - NO DISCOUNT
                        </td>
                        <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 border border-gray-300 px-3">
                          <CurrencyFormat value={estimate.totalNoDiscount} />
                        </td>
                      </tr>
                      <tr>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:px-3 border border-gray-300 px-3">
                          EQUIPMENT COST WITH DISCOUNT OF {estimate.notifier_discount}%
                        </td>
                        <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 border border-gray-300 px-3">
                          <CurrencyFormat value={estimate.totalDiscount} />
                        </td>
                      </tr>
                    </tbody>
                    <tfoot className="bg-slate-100">
                      <tr>
                        <td colSpan="2" className="whitespace-nowrap py-2 text-center text-green-600 border border-gray-300 px-3">
                          <b>Total <CurrencyFormat value={estimate.totalEquipment} /> <span>{estimate.totalDiscount > 0 && "With Discount"}</span></b>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </>
          )}

          {/* SUB-CONTRACTOR */}
          {estimate.totalCostContractor && (
            <div className="pb-10">
              <h4 className='font-bold p-2'>Sub-Contractor Details</h4>
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 sm:px-3 p-6 max-w-[1000px]">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-400 border border-gray-200">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:px-3"
                        >
                          Contractor
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      <tr>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:px-3 border border-gray-300 px-3">
                          HVAC CONTRACTOR
                        </td>
                        <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 border border-gray-300 px-3">
                          <CurrencyFormat value={estimate.elecContractor.hvac} />
                        </td>
                      </tr>
                      <tr>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:px-3 border border-gray-300 px-3">
                          SPRINKLER CONTRACTOR
                        </td>
                        <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 border border-gray-300 px-3">
                          <CurrencyFormat value={estimate.elecContractor.sprinkler} />
                        </td>
                      </tr>
                      <tr>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:px-3 border border-gray-300 px-3">
                          SECURITY CONTRACTOR
                        </td>
                        <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 border border-gray-300 px-3">
                          <CurrencyFormat value={estimate.elecContractor.security} />
                        </td>
                      </tr>
                      <tr>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:px-3 border border-gray-300 px-3">
                          GENERAL CONTRACTOR
                        </td>
                        <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 border border-gray-300 px-3">
                          <CurrencyFormat value={estimate.elecContractor.general} />
                        </td>
                      </tr>
                      <tr>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:px-3 border border-gray-300 px-3">
                          ELEVATOR CONTRACTOR
                        </td>
                        <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 border border-gray-300 px-3">
                          <CurrencyFormat value={estimate.elecContractor.elevator} />
                        </td>
                      </tr>
                    </tbody>
                    <tfoot className="bg-slate-100">
                      <tr>
                        <td colSpan="2" className="whitespace-nowrap py-2 text-center text-green-600 border border-gray-300 px-3">
                          <b>Total <CurrencyFormat value={estimate.totalCostContractor} /></b>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          )}

          {/* WIRE */}
          {estimate.es_wire && (
            <div className="pb-10">
                  <h4 className='font-bold p-2'>Wire Details</h4>
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 sm:px-3 p-6 max-w-[1000px]">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-400 border border-gray-200">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:px-3"
                            >
                              Wire
                            </th>
                            <th
                              scope="col"
                              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Total Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          <tr>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:px-3 border border-gray-300 px-3">
                              WIRE #14 1PR
                            </td>
                            <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 border border-gray-300 px-3">
                              <CurrencyFormat value={estimate.es_wire.w14_1pr * 1150} />
                            </td>
                          </tr>
                          <tr>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:px-3 border border-gray-300 px-3">
                              WIRE #14 2PR
                            </td>
                            <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 border border-gray-300 px-3">
                              <CurrencyFormat value={estimate.es_wire.w14_2pr * 1725} />
                            </td>
                          </tr>
                          <tr>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:px-3 border border-gray-300 px-3">
                              WIRE #16 1PR
                            </td>
                            <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 border border-gray-300 px-3">
                              <CurrencyFormat value={estimate.es_wire.w16_1pr * 1150} />
                            </td>
                          </tr>
                          <tr>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:px-3 border border-gray-300 px-3">
                              WIRE #16 2PR
                            </td>
                            <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 border border-gray-300 px-3">
                              <CurrencyFormat value={estimate.es_wire.w16_2pr * 1725} />
                            </td>
                          </tr>
                          <tr>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:px-3 border border-gray-300 px-3">
                              WIRE CI CABLE
                            </td>
                            <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 border border-gray-300 px-3">
                              <CurrencyFormat value={estimate.es_wire.ci_cable * 3450} />
                            </td>
                          </tr>
                        </tbody>
                        <tfoot className='bg-slate-100'>
                          <tr>
                            <td colSpan="2" className="whitespace-nowrap py-2 text-center text-green-600 border border-gray-300 px-3">
                              <b>Total <CurrencyFormat value={estimate.wireSectionCost} /></b>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
            </div>
          )}

          {/* MISCELLANEOUS */}
          {estimate.total_cost_misc && (
            <div className="pb-10">
              <h4 className='font-bold p-2'>Miscellaneous Details</h4>
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 sm:px-3 p-6 max-w-[1000px]">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-400 border border-gray-200">
                    <thead>
                      <tr>
                        <th scope="col"
                            className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:px-3">
                          Description
                        </th>
                        <th scope="col"
                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Total Qty
                        </th>
                        <th scope="col"
                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Total Price
                        </th>
                        <th scope="col"
                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Total Cost
                        </th>
                      </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 bg-white">
                      {estimate.misc_section.map((row, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:px-3 border border-gray-300 px-3">
                            {row.description}
                          </td>
                          <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 border border-gray-300 px-3">
                            {row.quantity.toFixed(2)}
                          </td>
                          <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 border border-gray-300 px-3">
                            <CurrencyFormat value={row.price}/>
                          </td>
                          <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 border border-gray-300 px-3">
                            <CurrencyFormat value={(row.price * row.quantity)}/>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot className='bg-slate-100'>
                      <tr>
                        <td colSpan="4" className="whitespace-nowrap py-2 text-center text-green-600 border border-gray-300 px-3">
                          <b>Total <CurrencyFormat value={estimate.total_cost_misc}/></b>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          )}

          {/* RESULTS */}
          {result && result.length > 0 ? (
            <div className="pb-10 text-sm">
              <div className="container">
                <div className="row">
                  {result.map(([key, value], index) => (
                    <div key={index} className="flex justify-between py-2 border-b border-gray-300">
                      <span className='pr-8'>{key}</span>
                      <b className="text-green-600"><CurrencyFormat value={value}/></b>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}

      </div>
    </div>
  );
};

export default EstimateDetail;
