import React from 'react'

const FormText = ({label, inside, val, onChange}) => {
  return (
    <>
      <div className='sm:col-span-6'>
        <label htmlFor={inside} className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
        <div className="mt-2">
          <textarea
            rows={4}
            name={inside}
            id={inside}
            value={val || ''}
            onChange={onChange}
            className="text-slate-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div> 
    </>
  )
}

export default FormText