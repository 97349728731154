import React, { useState, useEffect } from 'react';
import SideNav from '../components/SideNav';
import DayOff from '../components/DayOff';
import Alerts from '../components/Alerts';
import { NavLink, useParams } from 'react-router-dom';
import AvatarLocation from '../components/AvatarLocation';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import PhoneNumber from '../components/PhoneNumber';
import DateFormat from '../components/DateFormat';
// import EmployeeList from '../components/EmployeeList';
// import TabsEmployee from '../components/TabsEmployee';

const EmployeePage = () => {
const { id } = useParams();
const [EmployeeDetail, setEmployeeDetail] = useState({
                                                      id:"",
                                                      user_id:"",
                                                      first_name:"",
                                                      last_name:"",
                                                      date_hire:"",
                                                      o_f_type:"",
                                                      title:"",
                                                      email:"",
                                                      phone:"",
                                                      department:"",
                                                      pm:"",
                                                      dob:"",
                                                      avatar:"" 
                                                    })
const [employee_certs, setEmployeeCerts] = useState()
const [employee_fleet, setEmployeeFleet] = useState([])

useEffect( () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const fetchEmployeeDetail = async () =>{
      try {
            const res = await fetch(`${apiUrl}/employee_crud.php?getDetail=${id}`, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
              }
            });
                  if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
            const data = await res.json();
            setEmployeeDetail(data['employee_details']);
            setEmployeeCerts(data['employee_certs']);
            setEmployeeFleet(data['employee_fleet']);
      } catch(error) {
        console.log('Error fetching data', error);
      } finally {
        // setLoading(false);
      } 
    }

    fetchEmployeeDetail();
}, [id])

  return (
    <>
    <div className="max-w-[1220px] mx-auto grid grid-cols-1 md:grid-cols-[200px_minmax(540px,_1fr)_260px] gap-x-4">

      {/* <!-- Side Nav: 200px, hidden on small screens --> */}
      <div className="hidden md:block">
        <div className='pt-6 pr-3'>
          <SideNav />
        </div>
      </div>

      {/* <!-- Main Content: Expand on small screens --> */}
      <div className=" p-4">
        <NavLink to="/employee_list">
          <p className='py-3'>
            <ArrowLeftIcon className='-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" float-start mr-2'/>
            Back
          </p>
        </NavLink>
        <h1>
          {
            EmployeeDetail ? (
              `${EmployeeDetail.first_name} ${EmployeeDetail.last_name} Details`
            ) : (
              "Employee Details"
            )
          }
        </h1>
        <br/>

      {/* middle column of the main top */}
        <div className="columns-3xs ...">

          <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <AvatarLocation className="aspect-[3/2] w-full rounded-2xl object-cover"  avatar={EmployeeDetail.avatar}/>
            </div>

            <div className="px-4 py-5 sm:p-6">
              <h3 className="mt-6 text-sm font-medium text-gray-900">{EmployeeDetail.first_name} {EmployeeDetail.last_name}</h3>
              <dl className="mt-1 flex flex-grow flex-col justify-between">
                <dt className="sr-only">Title</dt>
                <dd className="text-sm text-gray-500">{EmployeeDetail.title} <br/> <span className='text-blue-400'>{EmployeeDetail.department}</span></dd>
                <dd className="text-sm text-gray-500">{<PhoneNumber number={EmployeeDetail.phone}/>}</dd>
                <dd className="text-sm text-gray-500">{EmployeeDetail.email}</dd>
                <dd className="text-sm text-gray-500">
                  {EmployeeDetail.date_hire}
                </dd>
                <dt className="sr-only">Role</dt> 
                <dd className="mt-3">
                    {
                      EmployeeDetail.o_f_type === 'Field' ? (
                        <span className="inline-flex items-center rounded-full 
                                      bg-indigo-50 px-2 py-1 text-xs font-medium 
                                      text-indigo-700 ring-1 ring-inset ring-indigo-600/20">
                          {EmployeeDetail.o_f_type}
                        </span>
                      ) : (
                        <span className="inline-flex items-center rounded-full 
                          bg-green-50 px-2 py-1 text-xs font-medium 
                          text-green-700 ring-1 ring-inset ring-green-600/20">
                          {EmployeeDetail.o_f_type}
                        </span>
                      )
                    }
                </dd>
              </dl>
            </div>
          </div>
          
          {
          employee_fleet ? (
          <div className="divide-gray-200 overflow-hidden rounded-lg bg-white shadow mt-3 mb-3">
            <p className='ml-8 mt-4'>Fleet</p>
                <div className="px-4 py-2 sm:p-6">
                <h3 className="mt-6 text-sm font-medium text-gray-900">
                  Vehicle #{employee_fleet.vnumber}
                </h3>
                <dl className="mt-1 flex flex-grow flex-col justify-between">
                  <dt className="sr-only">Title</dt>
                  <dd className="text-sm text-gray-500">{employee_fleet.make} {employee_fleet.model}<br/> 
                  <span className='text-blue-400'>Ins Exp: {employee_fleet.inspection_expires}</span></dd>
                  <dd className="text-sm text-gray-500">Vin: {employee_fleet.vin}</dd>
                  <dd className="text-sm text-gray-500">EzPass: {employee_fleet.ezpass} | {employee_fleet.tag_color} | {employee_fleet.tag_number}</dd>
                </dl>
              </div>
          </div>
          ) : (
               ""
              )
          }
          
          <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
            <p className='ml-8 mt-4'>Certifications</p>
            <div className="px-4 py-5 sm:p-6">
              { 
   
                Array.isArray(employee_certs) ?

                    employee_certs.map((item) => (
                    <div className='pb-2'>
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      + {item.certification}
                    </h3>
                    <dl className="mt-1 flex flex-grow flex-col justify-between">
                      <dd className="text-sm text-gray-500">
                        {<DateFormat date={item.issued}/>} | 
                        <span className='text-blue-400 pl-2'>
                        {<DateFormat date={item.expires}/>} 
                        </span>
                        <p>
                          {item.note}
                        </p>
                      </dd>
                    </dl>
                    <div className="w-full border-t border-gray-300" />
                    </div>
                  ))
                  :
                   <p>No certifications available.</p>
              
              }
            </div>
          </div>

        </div>
      {/* middle column of the main bottom */}

      </div>

      {/* <!-- Widgets: 200px, hidden on small screens --> */}
      <div className="hidden md:block ">
        <div className='pt-4'>
          <div className='border-2'>
            <DayOff />
          </div> 
        <br/>
          <Alerts />
        </div>
      </div>

    </div>
    </>
  )
} 

export default EmployeePage