import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import useDateRequestColorsTxt from '../hooks/useDateRequestColorsTxt';

const DayOff = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dateRequestColorsTxt = useDateRequestColorsTxt()
  const navigate = useNavigate();
  const [access, setAccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentDay, setCurrentDay] = useState('');
  const [currentDate, setCurrentDate] = useState(0);
  const [isOff, setIsOff] = useState([]);

  useEffect(() => {
    const fetchHolidays = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/attendance_crud.php?cal_homepage=1`, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
        });
        //  console.log(response.data);
        const datesOff = Array.isArray(response.data) ? response.data : [];
        setIsOff(datesOff);
      } catch (error) {
        // setAccess(true);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      } finally {
        setLoading(false);
      }
    };

    // Function to get the current day and date
    const updateCurrentDayAndDate = () => {
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const today = new Date();
      const day = daysOfWeek[today.getDay()];
      const date = today.getDate();

      setCurrentDay(day);
      setCurrentDate(date);
    };

    fetchHolidays();
    updateCurrentDayAndDate();
  }, [apiUrl, navigate]);

  return (
    <>
      <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
        <div className="sm:p-6 min-h-40 bg-gray-800 place-content-center">
          <p className="text-white text-center text-3xl">{currentDay}</p>
          <p className="text-white text-center text-8xl">{currentDate}</p>
        </div>
        {loading ? (
          'loading...'
        ) : (
          <>
             {isOff.map((dateOff, index) => (
              <div key={dateOff.id} className={`py-2 sm:px-6 divide-y ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} border-y-black`}>
                <div  className="flex space-x-3">
                    <div className="flex-shrink-0">
                      {/* <img
                        className="h-10 w-10 rounded-full"
                        src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      /> */}
                    </div>
                
                    <div className="min-w-0 flex-1">
                      <p className="text-sm font-semibold text-gray-900">
                          {dateOff.employee_name}
                      </p>
                      <p className="text-sm text-gray-500">
                        <span className={`${dateRequestColorsTxt[dateOff.date_request]}`}>
                          {dateOff.date_request}
                        </span>
                      </p>
                    </div>

                    <div className="flex flex-shrink-0 self-center hover:text-blue-700">
                      <NavLink to={`/profile/${dateOff.employee_id}`} >
                        <UserCircleIcon className='h-6'/>
                      </NavLink>
                    </div>
                </div>
              </div>
             ))}          
          </>
        )}
      </div>
    </>
  );
};

export default DayOff;
