import React from 'react'
import FormState from './FormState';
import FormZip from './FormZip';
import FormCity from '../components/FormCity';

const FormCSZ = ({city, state, zip, onChange}) => {
  return (
    <>
      <FormCity val={city} onChange={onChange}/>
      <FormState val={state} onChange={onChange}/>
      <FormZip val={zip} onChange={onChange} />
    </>
  )
}

export default FormCSZ