import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EstimateBar from '../components/EstimateBar';
import HeadingCp from '../components/HeadingCp';
import { TrashIcon } from '@heroicons/react/24/outline';
import CurrencyFormat from '../components/CurrencyFormat';

const EstimateContractor = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { id } = useParams();
  const [elecContractor, setElecContractor] = useState([]);
  const [submitObject, setSubmitObject] = useState({
    elecID: '',
    elecDescription: '',
    elecPrice: ''
  });

  useEffect(() => {
    // Fetch existing data for editing
    const fetchWireDetail = async () => {
      try {
        const response = await axios.get(`${apiUrl}/estimate_crud.php`, {
          params: { getElecContractor: id },
        });
        if (response.data[0] === 'success') {
          setElecContractor(response.data[1]);
        } 
      } catch (error) {
        toast.error("Oops minor issue with the data.", {
        position: "top-center"
        });

        if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
        navigate('/login');
        } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
        });
        }
      }
    };

    if (id) {
      fetchWireDetail();
    }
  }, [id, apiUrl]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubmitObject((prev) => ({
      ...prev,
      [name]: value || '',
    }));
  };

  const addElecContractor = async() => {
  // e.preventDefault();
    const bodyData = {
      data: submitObject,
      status: 'addElecContractor',
      estimate_id: id
    };
    try {
      const response = await axios.post(`${apiUrl}/estimate_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });
      
      if (response.data[0] === 'success') {
          // Update the list of contractors
        setElecContractor((prev) => [...prev, { 
          ...submitObject, 
          id: response.data[1].id,
          description: response.data[1].description,
          price: response.data[1].price
        }]);
        toast.success('Saved!', {
          position: 'top-center',
        });
        setSubmitObject({});
        // navigate(`/installation_details/${id}`);
      } else {
        toast.error(response.data.error || 'Failed updating.', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error("Oops minor issue with the data.", {
      position: "top-center"
      });
      
      if (error.response && error.response.status === 401) {
      toast.error("Session expired. Please login again.", {
      position: "top-center"
      });
      navigate('/login');
      } else if (error.response && error.response.status === 403) {
      toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
      position: "top-center"
      });
      }
    }    
  };

  const delElecContractor = async(contID) => {
    // e.preventDefault();
      try {
        const response = await axios.post(`${apiUrl}/estimate_crud.php`, {
          status: 'delElecContractor',
          data: { id: contID },
          headers: { 'Content-Type': 'application/json' },
        });
        if (response.data[0] === 'success') {
          toast.success('Deleted', {
            position: 'top-center',
          });
        setElecContractor((prevList) => prevList.filter((item) => item.id !== id));
        } else {
          toast.error(response.data.message || 'Failed to delete.', {
            position: 'top-center',
          });
        }
    } catch (error) {
        toast.error("Oops minor issue with the data.", {
        position: "top-center"
        });

        if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
        navigate('/login');
        } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
        });
        }
    }
  }

  return (
    <>
      <div className="mx-auto max-w-[1200px] sm:px-6 lg:px-6 py-8 bg-white">
        <EstimateBar id={id} name={'Elect Contractor'} />
        <HeadingCp label={"Electric Contractor"} />

        <form className="max-w-[800px] px-8 mx-auto py-8">
          <div className="space-y-10">
            <div className="border-b border-gray-900/10 pb-12">
              <div className="flex flex-wrap gap-4 items-end">
                {/* Hidden Input for Contractor ID */}
                <input
                  type="hidden"
                  id="elecID"
                  name="elecID"
                  value={submitObject.elecID || ''}
                  onChange={handleChange}
                />

                {/* Input for Description */}
                <div className="flex-1 flex flex-col space-y-1 min-w-[70%]"> 
                  <div className='flex flex-1 items-center justify-between'>
                    <label htmlFor="elecDescription" className="text-sm font-medium text-gray-900 pr-3">
                      Contractor Description
                    </label>
                    {submitObject.elecDescription === '' && (
                      <p className="flex-1 truncate text-red-600 text-sm">Required</p>
                    )}    
                  </div>              
                  <input
                    type="text"
                    id="elecDescription"
                    name="elecDescription"
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={submitObject.elecDescription || ''}
                    onChange={handleChange}
                    placeholder="Description"
                    required
                  />
                </div>

                {/* Input for Price */}
                <div className="flex-1 flex flex-col space-y-1 w-[20%]">
                  <div className='flex flex-1 items-center justify-between'>
                    <label htmlFor="elecPrice" className="text-sm font-medium text-gray-900 pr-3">
                      Price
                    </label>
                      {submitObject.elecPrice === '' && (
                      <p className="flex-1 truncate text-red-600 text-sm">Required</p>
                    )}
                  </div>
                  <input
                    type="number"
                    id="elecPrice"
                    name="elecPrice"
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={submitObject.elecPrice || ''}
                    onChange={handleChange}
                    placeholder="$$ Price"
                    required
                  />
                </div>

                {/* Add Button */}
                <div className="mt-2">
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={addElecContractor}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className='pb-6'>
          <ul className="max-w-[600px] mx-auto">
          <p className='pb-3'>List: </p>
            {elecContractor.map((row) => (
              <li key={row.id} className="py-1">
                <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-t
                               border-gray-200 bg-white">
                
                  <div className="flex-1 truncate px-4 py-2 text-sm">
                    <p className="inline-block text-gray-800">
                      {row.description}
                    </p>
                    <span className="inline-block ml-4 font-medium text-blue-500">
                      <CurrencyFormat value={row.price}/>
                    </span>
                  </div>

                  <div className="flex-shrink-0 pr-2">
                    <button
                      type="button"
                      onClick={(e) => delElecContractor(row.id)}
                      className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">Delete</span>
                      <TrashIcon className="h-5 w-5 text-red-600" aria-hidden="true" />
                    </button>
                  </div>

                </div>
              </li>
            ))}
          </ul>
        </div>

      </div>
    </>
  );
};

export default EstimateContractor;
