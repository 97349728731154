import React from 'react'

const BadgeFieldOffice = ({location}) => {
  return (
      location === 'Field' ? (
        <span className="inline-flex items-center rounded-full 
                        bg-indigo-50 px-2 py-1 text-xs font-medium 
                        text-indigo-700 ring-1 ring-inset ring-indigo-600/20">
          {location}
        </span>
      ) : (
        <span className="inline-flex items-center rounded-full 
          bg-green-50 px-2 py-1 text-xs font-medium 
          text-green-700 ring-1 ring-inset ring-green-600/20">
          {location}
        </span>
      )
  )
}

export default BadgeFieldOffice