import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormHeader from '../components/FormHeader';
import SubmitButton from '../components/SubmitButton';
import CustomTabs from '../components/CustomTabs';
import HeadingCp from '../components/HeadingCp';
import EstimateTabs from '../components/EstimateTabs';
import EstimateBar from '../components/EstimateBar';
import { sub } from 'date-fns';

const InstallationDetailPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const {id}= useParams();
  const [submitObject, setSubmitObject] = useState({
    estimate_id: '',
    
    job_mark_up: '',
    sub_contract_mark_up: '',
    notifier_discount: '',
    labor_type: '',
    supply: '',

    filing: '',
    tco_inspection: '',
    
    hvac_cxr: '',
    sprinkler_cxr: '',
    security_cxr: '',
    general_cxr: '',
    elev_cxr: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubmitObject((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  useEffect(() => {
    // Fetch existing data for editing
    const fetchInstall = async () => {
      try {
        const response = await axios.get(`${apiUrl}/estimate_crud.php`, {
          params: { get_install_id: id },
        });
        if (response.data[0] === 'success') {
          setSubmitObject(response.data[1]);
        }
      } catch (error) {
        toast.error('Error fetching data.', {
          position: 'top-center',
        });
      }
    };

    if (id) {
      fetchInstall();
    }
  }, [id, apiUrl]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const bodyData = {
      data: submitObject,
      status: 'addEsSystemDetails',
      estimate_id: id
    };
    
    if(submitObject.notifier_discount > 30) {
      toast.info('Notifier Discount has a max of 30%', {
        position: 'top-center'
      });
      return
    }
    
    if(submitObject.job_mark_up < 15) {
      toast.info('Job mark up cant be less than 15%', {
        position: 'top-center'
      });
      return
    }
    
    if(submitObject.sub_contract_mark_up < 21) {
      toast.info('Sub-contractor mark up cant be less than 21%', {
        position: 'top-center'
      });
      return
    }

    try {
      const response = await axios.post(`${apiUrl}/estimate_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      
      if (response.data[0] === 'success') {
        toast.success('Installation detail successfully saved!', {
          position: 'top-center'
        });
      } else {
        toast.error(response.data.error || 'Failed updating.', {
          position: 'top-center'
        });
      }
    } catch (error) {

      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center'
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error('Oops! No access to edit.', {
          position: 'top-center'
        });
      }
    }
  };

  return (
    <>
    <div className="mx-auto max-w-[1200px] sm:px-6 lg:px-6 py-8 bg-white">
      {/* <FormHeader type={'New'} what={'Installation Detail'} /> */}
      <EstimateBar id={id} name={'Install'}/>
      <HeadingCp label={"Install"} />

      <form onSubmit={handleSubmit} className="w-[800px] px-8 mx-auto">
        <div className="space-y-10">
          <div className="border-b border-gray-900/10 pb-12">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-9">
              <fieldset className="col-span-full">
                
                <h4 className='py-4 font-bold border-b'>Service</h4><br/>

                <h2 className="text-md font-medium text-gray-900 p-2">Filing:</h2>
                <legend className="sr-only">Filing Options</legend>
                <div className="space-y-4">

                  <div className="flex items-center">
                    <input
                      id="filingOnly"
                      name="filing"
                      type="radio"
                      value="Filing Only"
                      checked={submitObject.filing === 'Filing Only'}
                      onChange={handleChange}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label htmlFor="filingOnly" className="ml-3 block text-sm font-medium text-gray-700">
                      Filing Only
                    </label>
                  </div>

                  <div className="flex items-center">
                    <input
                      id="expeditingOnly"
                      name="filing"
                      type="radio"
                      value="Expediting Only"
                      checked={submitObject.filing === 'Expediting Only'}
                      onChange={handleChange}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label htmlFor="expeditingOnly" className="ml-3 block text-sm font-medium text-gray-700">
                      Expediting Only
                    </label>
                  </div>

                  <div className="flex items-center">
                    <input
                      id="filingAndExpediting"
                      name="filing"
                      type="radio"
                      value="Filing and Expediting"
                      checked={submitObject.filing === 'Filing and Expediting'}
                      onChange={handleChange}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label htmlFor="filingAndExpediting" className="ml-3 block text-sm font-medium text-gray-700">
                      Filing and Expediting
                    </label>
                  </div>

                  <div className="flex items-center">
                    <input
                      id="filing"
                      name="filing"
                      type="radio"
                      value="None"
                      checked={submitObject.filing === 'None'}
                      onChange={handleChange}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label htmlFor="filing" className="ml-3 block text-sm font-medium text-gray-700">
                      None
                    </label>
                  </div>
                </div>
              </fieldset>

              <fieldset className="col-span-full">
                <h2 className="text-md font-medium text-gray-900 p-2">TCO Inspection:</h2>
                <legend className="sr-only">TCO Inspection</legend>
                <div className="space-y-4">

                  <div className="flex items-center">
                    <input
                      id="tco"
                      name="tco"
                      type="radio"
                      value="TCO"
                      checked={submitObject.tco === 'TCO'}
                      onChange={handleChange}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label htmlFor="tco" className="ml-3 block text-sm font-medium text-gray-700">
                      TCO
                    </label>
                  </div>

                  <div className="flex items-center">
                    <input
                      id="noTco"
                      name="tco"
                      type="radio"
                      value="No TCO"
                      checked={submitObject.tco === 'No TCO'}
                      onChange={handleChange}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label htmlFor="noTco" className="ml-3 block text-sm font-medium text-gray-700">
                      No TCO
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>

            <h4 className='py-4 font-bold border-b'>Mark-ups</h4><br/>

            <div className="col-span-full flex flex-wrap gap-6 mb-9">
              <div className="flex-1 flex flex-col space-y-1">
                <label htmlFor="job_mark_up" className="text-sm font-medium text-gray-900">
                  Job Mark-up (%):
                </label>
                <input
                  type="number"
                  id="job_mark_up"
                  name="job_mark_up"
                  className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={submitObject.job_mark_up}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="flex-1 flex flex-col space-y-1">
                <label htmlFor="sub_contract_mark_up" className="text-sm font-medium text-gray-900">
                  Sub-contract Mark-up (%):
                </label>
                <input
                  type="number"
                  id="sub_contract_mark_up"
                  name="sub_contract_mark_up"
                  className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={submitObject.sub_contract_mark_up}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="flex-1 flex flex-col space-y-1">
                <label htmlFor="notifier_discount" className="text-sm font-medium text-gray-900">
                  Notifier Discount (%):
                </label>
                <input
                  type="number"
                  id="notifier_discount"
                  name="notifier_discount"
                  className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={submitObject.notifier_discount}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <h4 className='py-4 font-bold border-b'>Install</h4><br/>

            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-9">
              <div className="col-span-full">
                <h2 className="text-md font-medium text-gray-900">Type:</h2>
                <fieldset className="mt-4">
                  <legend className="sr-only">Supply Options</legend>
                  <div className="space-y-4">
                    <div className="flex items-center">
                      <input
                        id="supplyOnly"
                        name="supply"
                        type="radio"
                        value="Supply"
                        checked={submitObject.supply === 'Supply'}
                        onChange={handleChange}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label htmlFor="supplyOnly" className="ml-3 block text-sm font-medium text-gray-700">
                        Supply Only
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="supplyAndInstall"
                        name="supply"
                        type="radio"
                        value="Supply and Install"
                        checked={submitObject.supply === 'Supply and Install'}
                        onChange={handleChange}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label htmlFor="supplyAndInstall" className="ml-3 block text-sm font-medium text-gray-700">
                        Supply and Install
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>

            {submitObject.supply === 'Supply and Install' && (
              <>
                <div className="col-span-full mb-10">
                  <label className="block text-sm font-medium text-gray-900">
                    Labor Type (Reference Only):
                  </label>
                  <fieldset className="mt-2">
                    <legend className="sr-only">Labor Type</legend>
                    <div className="flex items-center space-x-4">
                      <div className="flex items-center">
                        <input
                          id="union_labor"
                          name="union_labor"
                          type="radio"
                          value="Union Labor"
                          checked={submitObject.union_labor === 'Union Labor'}
                          onChange={handleChange}
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label htmlFor="union_labor" className="ml-2 block text-sm font-medium text-gray-700">
                          Union Labor
                        </label>
                      </div>

                      <div className="flex items-center">
                        <input
                          id="union_labor"
                          name="union_labor"
                          type="radio"
                          value="Non Union Labor"
                          checked={submitObject.union_labor === 'Non Union Labor'}
                          onChange={handleChange}
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label htmlFor="union_labor" className="ml-2 block text-sm font-medium text-gray-700">
                          Non-Union Labor
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div className="flex flex-wrap gap-6">
                  <div className="flex-1 flex flex-col">
                    <label htmlFor="hvac" className="text-sm font-medium text-gray-900">
                      Hvac Contractor
                    </label>
                    <input
                      type="number"
                      id="hvac"
                      name="hvac_cxr"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      value={submitObject.hvac_cxr}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex-1 flex flex-col">
                    <label htmlFor="sprinkler_cxr" className="text-sm font-medium text-gray-900">
                      Sprinkler Contractor
                    </label>
                    <input
                      type="number"
                      id="sprinkler_cxr"
                      name="sprinkler_cxr"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      value={submitObject.sprinkler_cxr}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="flex-1 flex flex-col">
                    <label htmlFor="security_cxr" className="text-sm font-medium text-gray-900">
                      Security Contractor
                    </label>
                    <input
                      type="number"
                      id="security_cxr"
                      name="security_cxr"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      value={submitObject.security_cxr}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="flex-1 flex flex-col">
                    <label htmlFor="general_cxr" className="text-sm font-medium text-gray-900">
                      General Contractor
                    </label>
                    <input
                      type="text"
                      id="general_cxr"
                      name="general_cxr"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      value={submitObject.general_cxr}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="flex-1 flex flex-col">
                    <label htmlFor="elev_cxr" className="text-sm font-medium text-gray-900">
                      Elevator Contractor
                    </label>
                    <input
                      type="number"
                      id="elev_cxr"
                      name="elev_cxr"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      value={submitObject.elev_cxr}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={() => navigate('/installation_details')}
          >
            Cancel
          </button>
          <SubmitButton children={'Save'} />
        </div>
      </form>
    </div>
    </>
  );
};

export default InstallationDetailPage;
