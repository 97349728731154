import React from 'react';
import TechWipPage from './TechWipPage';
import GeoAddressLocCp from '../components/GeoAddressLocCp';

const TechMain = () => {
  return (
    <>
      <TechWipPage/>
      {/* <GeoAddressLocCp /> */}
    </>
  );
}

export default TechMain;