import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // If using react-router for navigation
import axios from 'axios';
import { toast } from 'react-toastify'; // Assuming you're using react-toastify for notifications
import SubmitButton from '../components/SubmitButton';
import { useUserData } from '../context/UserData';
import HeadingCp from '../components/HeadingCp';
import CustomTabs from '../components/CustomTabs';


const ServiceCallNew = () => {
  const navigate = useNavigate(); // For cancel navigation
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/service_crud.php`;
  const userData = useUserData();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [activeLink, setActiveLink] = useState('service_new');
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [employees, setEmployees] = useState([]);
  const [serviceCallForm, setServiceCallForm] = useState({
                                                            TROUBLE: '',
                                                            CUSTOMER: '',
                                                            ADDRESS: '',
                                                            CALLED_BY: '',
                                                            PHONE: '',
                                                            TECH: '',
                                                            NOTES: '',    
                                                            TIME: '',
                                                            SCHEDULE_DATE: '',
                                                          });

  useEffect(() => {
    setLoading(true);
    const serviceCallList = async () => {
      if(id !== 'new')
      {
        try {
          const response = await axios.get(`${apiUrl}/service_crud.php?getDetail=${id}`, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
          });
          if (response.data[0] === 'success') {          
            setServiceCallForm(response.data[1]);
          } else {
            toast.error("No service calls found!", { position: "top-center" });
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            toast.error("Session expired. Please login again.", { position: "top-center" });
            navigate('/login');
          } else if (error.response && error.response.status === 403) {
            toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: "top-center" });
          }
        } finally {
          setLoading(false);
        }      
      }
    };
    serviceCallList();
  }, [apiUrl, navigate]);                                                          

  useEffect(() => {
    setLoading(true);
    const employees = async () => {
      try {
        const response = await axios.get(`${apiUrl}/service_crud.php?employee_list=true`, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        });
        const serviceCalls = Array.isArray(response.data) ? response.data : [];
        if (serviceCalls.length === 0) {
          toast.error("No service calls found!", { position: "top-center" });
        }
        setEmployees(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", { position: "top-center" });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: "top-center" });
        }
      } finally {
        setLoading(false);
      }
    };

    employees();
  }, [apiUrl, navigate]);                                                          

  useEffect(() => {
    if (activeLink === 'service_dashboard') {
      navigate('/service_dashboard')
    } 
  }, [activeLink]);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setServiceCallForm((prevState) => ({
      ...prevState,
      [name]: value || '', // Ensuring the value is an empty string if it’s null or undefined
    }));
  };

  // Handle form submission
  const handleSubmitServiceCall = async (e) => {
    e.preventDefault();

    const bodyData = {
      data: serviceCallForm,
      id: id,
      status: 'addServiceCall',
    };
    console.log(bodyData);
    
    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data[0] === 'success') {
        toast.success('Service call successfully saved!', {
          position: 'top-center',
        });
        navigate(`/service_dashboard/`);
      } else {
        toast.error(response.data.error || 'Failed to save the service call.', {
          position: 'top-center',
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center',
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error('You do not have permission to edit this service call.', {
          position: 'top-center',
        });
      }
    }
  };

  const tabs = [
    { label: 'Service New', onClick: () => setActiveLink('service_new/new') },
    { label: 'Service Dashboard', onClick: () => setActiveLink('service_dashboard') },
    // { label: 'Fleet', onClick: () => setActiveLink('fleet') },
    // { label: 'Users', onClick: () => setActiveLink('users') }
  ];

  const handleSelectChange = (e) => {
    setSelectedEmployee(e.target.value);
  };

  return (

    <div className="mx-auto max-w-[1200px] px-4 sm:px-6 lg:px-8 py-8 bg-white">
      <HeadingCp label={'Service Call'} />
      <CustomTabs tabs={tabs} />
      
      {/* Form Structure */}      
      <form onSubmit={handleSubmitServiceCall} className="w-[800px] px-8 mx-auto">
        <div className="space-y-10">
          <div className="border-b border-gray-900/10 pb-12">
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
     
            {/* Trouble */}
            <div className="col-span-full">              
              <label htmlFor="TROUBLE" className="block text-sm font-medium text-gray-900">
                Trouble <span className='text-red-500'>*</span>
              </label>
              <input
                type="text"
                id="TROUBLE"
                name="TROUBLE"
                value={serviceCallForm.TROUBLE || ''}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                required
              />
            </div>

            {/* Customer */}
            <div className="col-span-full">
              <label htmlFor="CUSTOMER" className="block text-sm font-medium text-gray-900">
                Customer <span className='text-red-500'>*</span>
              </label>
              <input
                type="text"
                id="CUSTOMER"
                name="CUSTOMER"
                value={serviceCallForm.CUSTOMER || ''}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                required
              />
            </div>

            {/* Customer Address */}
            <div className="col-span-full">
              <label htmlFor="ADDRESS" className="block text-sm font-medium text-gray-900">
                Customer Address <span className='text-red-500'>*</span>
              </label>
              <input
                type="text"
                id="ADDRESS"
                name="ADDRESS"
                value={serviceCallForm.ADDRESS || ''}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                required
              />
            </div>

            {/* Called By */}
            <div className="sm:col-span-4">
              <label htmlFor="CALLED_BY" className="block text-sm font-medium text-gray-900">
                Called By <span className='text-red-500'>*</span>
              </label>
              <input
                type="text"
                id="CALLED_BY"
                name="CALLED_BY"
                value={serviceCallForm.CALLED_BY || ''}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                required
              />
            </div>

            {/* Phone */}
            <div className="sm:col-span-2">
              <label htmlFor="PHONE" className="block text-sm font-medium text-gray-900">
                Phone <span className='text-red-500'>*</span>
              </label>
              <input
                type="number"
                id="PHONE"
                name="PHONE"
                maxLength="10"
                minLength="10"
                value={serviceCallForm.PHONE || ''}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                required
              />
            </div>

            {/* tech */}
            <div className="sm:col-span-2">
                <label htmlFor="employeeSelect" className="block text-sm font-medium text-gray-900">Select an Employee:</label>
                <select 
                  id="employeeSelect"
                  name='TECH'
                  value={selectedEmployee || ''} 
                  onChange={handleSelectChange}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="">---</option>
                  {employees.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {employee.first_name} {employee.last_name}
                    </option>
                  ))}
                </select>
            </div>

            {/* Time */}
             <div className="sm:col-span-2">
              <label htmlFor="TIME" className="block text-sm font-medium text-gray-900">
                Schedule Time
              </label>
              <input
                type="time"
                id="TIME"
                name="TIME"
                value={serviceCallForm.TIME || ''}
                onChange={handleInputChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>

            {/* schedule date */}
             <div className="sm:col-span-2">
              <label htmlFor="SCHEDULE_DATE" className="block text-sm font-medium text-gray-900">
                Schedule Date
              </label>
              <input
                type="date"
                id="SCHEDULE_DATE"
                name="SCHEDULE_DATE"
                value={serviceCallForm.SCHEDULE_DATE || ''}
                onChange={handleInputChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>

            {/* Notes */}
            <div className='sm:col-span-6'>
              <label htmlFor="NOTES" className="block text-sm font-medium text-gray-900">
                Notes
              </label>
              <textarea
                id="NOTES"
                name="NOTES"
                value={serviceCallForm.NOTES || ''}
                onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  rows="5"
              />
            </div>

            </div>
          </div>
        </div>
        
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={() => navigate('/service_dashboard')}
          >
            Cancel
          </button>
          <SubmitButton children={'Save'} />
        </div>

      </form>
    </div>
  );
};

export default ServiceCallNew;
