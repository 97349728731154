import React from 'react';

const TechExpenseNotesFileEntry = ({ handleChange, handleFileChange, timesheetLog }) => {
  return (
    <div className="space-y-4 sm:col-span-6">
      <div className="form-group">
        <label htmlFor="file" className="block text-sm font-medium text-gray-700">
          Upload File
        </label>
        <input
          type="file"
          name="file"
          id="file"
          onChange={handleFileChange}
          required
          className="mt-1 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
        />
      </div>
      <hr className="my-4" />

      <div className="form-group">
        <label htmlFor="expense_comment" className="block text-sm font-medium text-gray-700">
          Notes:
        </label>
        <textarea
          id="expense_comment"
          name="expense_comment"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          rows="3"
          value={timesheetLog.expense_comment || ''}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default TechExpenseNotesFileEntry;
