import React from 'react';

const LoadingSpinner = ({children}) => {
  return (
    <div className="flex items-center justify-center space-x-2">
      <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-500"></div>
      <div>{children}</div>
    </div>
  );
}

export default LoadingSpinner;
