import React, { useState } from 'react';
import EstimateFCSAdd from '../components/EstimateFCSAdd';
import EstimateAddNotes from '../components/EstimateAddNotes';
import EstimateAddCustomNotes from '../components/EstimateAddCustomNotes';
import CustomTabs from '../components/CustomTabs';
import EstimateBar from '../components/EstimateBar';
import { useParams } from 'react-router-dom';
import HeadingCp from '../components/HeadingCp';

const EstimateNotes = () => {
  const [activeTab, setActiveTab] = useState('Add Notes'); // State to track the active tab
  const {id} = useParams();

  const tabs = [
    { label: 'Add Notes', onClick: () => setActiveTab('Add Notes') },
    { label: 'Add Custom Notes', onClick: () => setActiveTab('Add Custom Notes') },
  ];

  return (
    <>
      <div className="mx-auto max-w-[1200px] sm:px-6 lg:px-6 py-8 bg-white">
        <EstimateBar id={id} name={'Notes'}/>
        <HeadingCp label={'Notes'} />
        <CustomTabs tabs={tabs} />
        {activeTab === 'Add Notes' && <EstimateAddNotes />}
        {activeTab === 'Add Custom Notes' && <EstimateAddCustomNotes />}
      </div>
    </>
  );
};

export default EstimateNotes;
