import React, { useState } from 'react';

const CustomTabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].label);

  const handleTabClick = (tab) => {
    setActiveTab(tab.label);
    if (tab.onClick) {
      tab.onClick();
    }
  };

  return (
    <div className="border-b border-gray-200">
      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
        {tabs.map((tab) => (
          <span
            key={tab.label}
            className={`flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium 
                        hover:cursor-pointer hover:text-blue-700 
                        ${activeTab === tab.label ? 'border-blue-700 text-blue-700' : 'border-transparent text-gray-500'}`}
            onClick={() => handleTabClick(tab)}
          >
            {tab.label}
          </span>
        ))}
      </nav>
    </div>
  );
};

// Usage example
// const ExampleComponent = () => {
//   const handleCalendarView = () => {
//     console.log('Calendar View clicked');
//   };

//   const handleDetail = () => {
//     console.log('Detail View clicked');
//   };

//   const tabs = [
//     { label: 'Calendar View', onClick: handleCalendarView },
//     { label: 'Detail View', onClick: handleDetail },
//   ];

//   return <Tabs tabs={tabs} />;
// };

export default CustomTabs;
