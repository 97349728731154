import react from "react"
import { useParams } from "react-router-dom"

 const aj = { name: 'Active Job',          href: '/wip_list/aj',  current: false };
 const cu = { name: 'Complete Unpaid',     href: '/wip_list/cu',  current: false };
 const cp =   { name: 'Complete Paid',       href: '/wip_list/cp',  current: false };
 const fil =   { name: 'Filing',              href: '/wip_list/fil',  current: false };
 const af =   { name: 'Awaiting FDNY insp.', href: '/wip_list/af', current: false };
 const full =   { name: 'Full List', href: '/wip_list/full', current: false };

 const tabs = [ aj, cu, cp, fil, af, full];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

 const TabsJob = () => {

  const {key} = useParams();

  if(key == 'cu'){
    cu.current = true;
    
    aj.current = false;
    cp.current = false;
    fil.current = false;
    af.current = false;
    full.current = false;
  }
  else if(key == 'aj'){
    aj.current = true;
    full.current = false;
    cu.current = false;
    cp.current = false;
    fil.current = false;
    af.current = false;
  }
  else if(key == 'cp'){
    cp.current = true;
    full.current = false;
    aj.current = false;
    cu.current = false;
    fil.current = false;
    af.current = false;
  }
  else if(key == 'fil'){
    fil.current = true;
    full.current = false;
    cu.current = false;
    cp.current = false;
    aj.current = false;
    af.current = false;
  }
  else if(key == 'af'){
    af.current = true;
    full.current = false;
    cu.current = false;
    cp.current = false;
    fil.current = false;
    aj.current = false;
  }
  else if(key =='full'){
    full.current = true;
    af.current = false;
    cu.current = false;
    cp.current = false;
    fil.current = false;
    aj.current = false;
  }
  

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current).name}>
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>

      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                )}
                aria-current={tab.current ? 'page' : undefined}>
                {tab.name}
                {tab.count ? (
                  <span
                    className={classNames(
                      tab.current ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-900',
                      'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default TabsJob