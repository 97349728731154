import React, { useState, useEffect } from 'react';
import PhoneNumber from '../components/PhoneNumber';
import { NavLink, useNavigate } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

// axios.defaults.withCredentials = true; // Ensure cookies are sent with every request

const Users = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/users_crud.php?getList=1`, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        });
        if (response.data.location === "field") {
          navigate('/t'); // Redirect to login if the cookie is invalid or expired
          toast.error("Tech Section", {
            position: "top-center"
          });
        } else {
          //console.log(response.data)//delete
          setUsers(response.data.users);
        }
      } catch (error) {
        //console.log('Error fetching data', error);// delete
        if (error.response && error.response.status === 401) {
          // navigate('/login'); // Redirect to login if the cookie is invalid or expired
          toast.error("Session expired. Please login again.", {
            position: "top-center"
          });
        }
      }
    };

    fetchUsers();
  }, [apiUrl, navigate]);

  return (
    <div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mb-6">
        {users.map((user) => (
          <div
            key={user.id}
            className="relative flex items-center space-x-3 rounded-lg border
                       border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 
                       focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
            <TrashIcon className="-mr-1 h-5 w-5 text-red-400 absolute top-4 right-4" aria-hidden="true" />
            <div className="min-w-0 flex-1">
              <p className="text-sm font-medium text-gray-900">ID: {user.id}</p>
              <p className="text-sm font-medium text-gray-900">{user.username}</p>
              <p className="truncate text-sm text-gray-500">{<PhoneNumber number={user.phone}/>}</p>
              <p className="truncate text-sm text-gray-500">{user.email}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Users;
