import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Dialog, Transition } from '@headlessui/react';
import LoadingSpinner from '../components/LoadingSpinner';
import HeadingCp from '../components/HeadingCp';
import DateFormat from '../components/DateFormat';
import { CheckIcon } from '@heroicons/react/24/outline';
import { UtilDateToFullDay } from '../utils/UtilDateToFullDay';

const OfficeTimesheetList = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [payperiod, setPayperiod] = useState(null);
  const [week, setWeek] = useState('active_week');
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    fetchEmployeeList(week);
  }, [week]);

  const formatTime12Hour = (time) => {
    let [hours, minutes] = time.split(':');
    const suffix = hours >= 12 ? "PM" : "AM";
    hours = ((hours % 12) || 12); // Convert to 12-hour format
    return `${hours}:${minutes} ${suffix}`;
  };

  const fetchEmployeeList = async (week) => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/time_attendance_crud.php?office_timesheet_list=${week}`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
      });
        if(response.data.success)
        {
          const employee_list = Array.isArray(response.data.employees) ? response.data.employees : [];
          setEmployees(employee_list);
          setPayperiod(response.data.pay_period.payperiod);
        }
        else
        {
          toast.error(response.data.error, { position: 'top-center' });
        }
    } catch (error) {     
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const approved_ot = async(employee_id, overtimeHrs) => {
    setLoading(true);
    try {
      const response = await axios.post(`${apiUrl}/time_attendance_crud.php`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        status: 'overtime_office_timesheet',
        data: {employee_id: employee_id, overtime_hrs: overtimeHrs, pay_period: payperiod}
      });
      if (response.data.error === 'Authentication required') {
        navigate('/Login');
      } else {
         setEmployees((prevEmployees) =>
          prevEmployees.map((employee) =>
            employee.employee_id === employee_id ? { ...employee, approval: response.data[1] } : employee
          )
        );
      }

      if (response.data.error) {
        toast.error(response.data.error, { position: 'top-center' });
      }
    } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }    
  }

  const handleEmployeeClick = (employee) => {
    setSelectedEmployee(employee);
    setIsOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedEmployee(null);
  };

  return (
    <>
      <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">
        <HeadingCp label={'Office Timesheet List'}  />
        <h6 className="py-8">
          Payroll Date:&nbsp;
          {payperiod ? (
            <DateFormat date={payperiod} />
          ) : (
            'Loading...'
          )}
        </h6>
        {loading ? (
          <LoadingSpinner>Loading...</LoadingSpinner>
        ) : (
          <table className="min-w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg">
            <thead>
              <tr>
                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Approval</th>
                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Employee Name</th>
                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Department</th>
                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Overtime</th>
                <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Timesheet Entries</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {employees.map((employee, index) => (
                <tr key={employee.employee_id} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}>
                  
                  <td className="px-3 py-4 text-sm text-blue-500">
                    {employee.approval.id ? (
                      <CheckIcon className='h-6 text-green-500 px-5' />
                    ) : (
                      <button
                        onClick={() => approved_ot(employee.employee_id, employee.overtime_hrs)}
                        className="inline-flex items-center px-2 py-1 border border-transparent text-sm font-medium 
                                  rounded-md shadow-sm text-white bg-green-500 hover:bg-blue-600 focus:outline-none 
                                  focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                          Approved
                      </button>
                    )}
                  </td>

                  <td className="px-3 py-4 text-sm text-blue-500">
                    <span  className='cursor-pointer' onClick={() => handleEmployeeClick(employee)}>
                      {employee.employee_name}
                    </span>
                  </td>
                  
                  <td className="px-3 py-4 text-sm text-gray-900">
                    {employee.department}
                  </td>
                                    
                  <td className="px-3 py-4 text-sm text-gray-900">
                    {employee.overtime_hrs} Hrs
                  </td>

                  <td className="px-3 py-4 text-sm text-gray-900">
                    {employee.timesheets.length} entries
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Modal for Timesheet Entries */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[800px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    {selectedEmployee?.employee_name} - Timesheet Entries
                  </Dialog.Title>
                  <div className="mt-4">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Event</th>
                          <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Time In</th>
                          <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Time Out</th>
                          <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Overtime</th>
                          <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Date</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {selectedEmployee?.timesheets.map((timesheet, index) => (
                          <tr key={timesheet.id} className={`${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                            <td className="px-3 py-4 text-sm text-gray-900">{timesheet.event}</td>
                            <td className="px-3 py-4 text-sm text-gray-900">{formatTime12Hour(timesheet.time_in)}</td>
                            <td className="px-3 py-4 text-sm text-gray-900">{formatTime12Hour(timesheet.time_out)}</td>
                            <td className="px-3 py-4 text-sm text-gray-900">{timesheet.overtime}</td>
                            <td className="px-3 py-4 text-sm text-gray-900">{UtilDateToFullDay(timesheet.date)} </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="mt-6 flex justify-end">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default OfficeTimesheetList;
