import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon, PencilIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CustomerCardHeading = ({customer}) => {
  const colorhold = customer.hold === 1 ? 'bg-red-400' : 'bg-gray-50';
  
  return (
    <>
        <div className="overflow-hidden rounded-xl border border-gray-200">
        <div className={`flex items-center gap-x-4 border-b border-gray-900/5 ${colorhold} p-6`}>
          <div className="text-sm font-medium leading-6 text-gray-900 text-xl">
            <span className='text-sm'>ID: {customer.id}</span>
            <br/>
            {customer.customer_name}
          </div>
          <NavLink to={`/customer/edit/${customer.id}`}>
            <PencilIcon className='className="-mr-1 h-5 w-5 text-gray-400" text-blue-500 aria-hidden="true"'/>
          </NavLink>
        </div>
        </div>
    </>
  )
}

export default CustomerCardHeading