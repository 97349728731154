import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { UtilConvertDateTodayDate } from '../utils/UtilConvertDateTodayDate';
import FileLocationCp from '../components/FileLocationCp';
import { ArrowUpCircleIcon, DocumentTextIcon, TrashIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { UtilGetToDate } from '../utils/UtilGetToDate';
import HeadingCp from '../components/HeadingCp';
import CurrencyFormat from '../components/CurrencyFormat';

const CreditHold = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [access, setAccess] = useState();
  const [creditHoldList, setCreditHoldList] = useState([]);
  const [editMode, setEditMode] = useState([]);
  const [editObj, setEditObj] = useState([]);
  const [totalHold, setTotalHold] = useState(null);
  const [totalRecord, setTotalRecord] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchHolidays = async () => {
      try {
        const response = await axios.get(`${apiUrl}/wip_crud.php?creditHoldList=1`, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        });
        if (response.data.list) {
          const holdList = response.data;
          setTotalRecord(holdList.records);
          setTotalHold(holdList.total_hold);
          setCreditHoldList(holdList.list);
        } else {
          setTotalRecord('0');
          setTotalHold('0');
          setCreditHoldList([]);
          toast.error("No days found!", {
              position: "top-center"
            });
        }
      } catch (error) {
        setAccess(true);
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
            position: "top-center"
          });
          navigate('/login');
        }
        else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you dont have access to this area speak to the CFO.", {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchHolidays();
  }, [apiUrl, navigate]);

  const handleDelete = async (id) => {
    const bodyData = {
      id: id,
      status: 'delete_credit_hold'
    };
      try {
        const response = await axios.post(`${apiUrl}/wip_crud.php`, bodyData, {
          headers: { 'Content-Type': 'application/json' }
        });
        if (response.data[0] === 'success') {
          toast.success("Hold Removed!", {
            position: "top-center"
          });
          setCreditHoldList((prevCerts) => prevCerts.filter(credit => credit.id !== id));
        } else {
          toast.error(response.data.error || "Failed deleting.", {
            position: "top-center"
          });
        }
      } catch (error) {
          toast.error("Oops minor issue with the data.", {
          position: "top-center"
          });
          
          if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
          position: "top-center"
          });
          navigate('/login');
          } else if (error.response && error.response.status === 403) {
            setAccess(true);
          toast.error("Oops!! no access to edit.", {
          position: "top-center"
          });
          }
      }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditObj((prev) => ({ ...prev, [name]: value }));
  }

  const handleSaveClick = async (e, job_number) => {
    // e.preventDefault()
    // Implement the form submission logic here
    const bodyData = {
      status: 'submit_credit_hold',
      data: editObj,
      jobNumber: editObj.job_number
    }; 
    try {
      const response = await axios.post(`${apiUrl}/wip_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
        // console.log(response.data[0]);
        
      if (response.data[0] === 'success') {
        toast.success('Credit Hold Added!', {
          position: 'top-center'
        });
          setEditMode(null);
          setCreditHoldList(prevList =>
            prevList.map(credit =>
              credit.id === response.data.credit_hold?.id ? { ...credit, ...response.data.credit_hold } : credit
            )
          );
      } else {
        toast.error('Error adding credit hold.', {
          position: 'top-center'
        });
      }
    } catch (error) {
      console.error('Error adding credit hold', error);
      toast.error('Error adding credit hold.', {
        position: 'top-center'
      });
    }
  }

  const handleEditClick = (obj) => {
    setEditMode(obj.id);
    setEditObj({ ...obj });
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const filteredCreditHoldList = creditHoldList.filter((credit) => {
    return (
      credit.job_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
      credit.customer_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      credit.job_site.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <>
      
      <div className="mx-auto max-w-6xl mt-20">
        <HeadingCp label={"Credit Hold Projects"} />
        <br/>
        <div className="flex justify-between items-center">
          <NavLink to={'/wip_list/aj'}>
            <span
              className="inline-flex items-center px-4 py-2 mr-2 border border-transparent text-sm font-medium rounded-md 
                         shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2
                         focus:ring-green-800 hover:cursor-pointer">
              Wip List
            </span>
          </NavLink>
          <div className="relative rounded-md shadow-sm flex-grow">
            <input
              type="text"
              name="search"
              value={searchTerm}
              onChange={handleSearchChange}
              className="block w-full pl-4 pl-12 sm:text-sm border-gray-300 rounded-md"
              placeholder="Filter" />
          </div>
        </div>
        <br/>
        <span className='flex gap-x-4'>
          <span className='text-green-700'>Total {totalRecord > 1 ? 'Records' : 'Record'}: {totalRecord}</span>
          <span className='text-red-700'>Total Hold: ${totalHold}</span>
        </span>
        <div>
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full max-w-4xl mx-auto divide-y divide-gray-300 border">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 bg-gray-200 pl-4 pr-3 text-center border border-gray-300 text-sm font-semibold text-gray-900 sm:pl-0">
                    ID
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    P# | Customer | Job Site
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Amount
                  </th>
                  <th scope="col" className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900">
                    Date
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900 lg:table-cell max-w-xs">
                    Notes
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 bg-gray-200 text-center border border-gray-300 text-sm font-semibold text-gray-900 lg:table-cell">
                    Mode
                  </th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200 bg-white">
                {filteredCreditHoldList.map((credit, index) => (
                  <tr key={credit.id} className={`divide-x divide-gray-200 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                    {editMode === credit.id ? (
                      <>
                        <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 sm:pl-3">
                          <TrashIcon className='h-5 text-red-600 hover:cursor-pointer' onClick={() => handleDelete(credit.id)} />
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                          <input
                            type="text"
                            name="job_number"
                            required={true}
                            disabled={true}
                            value={editObj.job_number || ''}
                            onChange={handleInputChange}
                            className="border-gray-300 rounded-md"
                          />
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                          <input
                            type="number"
                            name="amount"
                            value={editObj.amount || ''}
                            required={true}
                            onChange={handleInputChange}
                            className="border-gray-300 rounded-md"
                          />
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {UtilConvertDateTodayDate(editObj.date)}
                        </td>
                        <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell max-w-xs break-words">
                          <textarea
                            type="text"
                            rows={3}
                            name="notes"
                            value={editObj.notes || ''}
                            onChange={handleInputChange}
                            className="border-gray-300 rounded-md"
                          />
                        </td>
                        <td className="hidden whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium lg:table-cell">
                          <button
                            onClick={(e) => handleSaveClick(credit.id)}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Save
                          </button>
                          <br />
                          <button
                            onClick={() => setEditMode(null)}
                            className="text-red-600 hover:text-indigo-900"
                          >
                            Cancel
                          </button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 sm:pl-3">
                          {credit.id}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                          <p className='font-bold text-blue-500'>
                            <NavLink to={`/wip_detail/${credit.wip_id}`}>
                              {credit.job_number}
                            </NavLink>
                          </p>
                          <p className='text-gray-800 text-sm hover:cursor-pointer'>{credit.customer_name}</p>
                          <p className='text-gray-500 text-sm'>{credit.job_site}</p>
                        </td>
                        <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                          <CurrencyFormat value={credit.amount} />
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {UtilConvertDateTodayDate(credit.date)}
                        </td>
                        <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell max-w-xs break-words">
                          {credit.notes}
                        </td>
                        <td className="hidden whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium lg:table-cell">
                          <button
                            onClick={() => handleEditClick(credit)}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Edit
                          </button>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <br />
      <br />
    </>
  );
}

export default CreditHold;
