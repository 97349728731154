import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopTabs from '../components/TopTabs';
import PhoneNumber from '../components/PhoneNumber';


  const CustomerBillTo = () => {

  const navigate = useNavigate();
  const { id, key } = useParams();
  const [billto, setBillTo] = useState([]);
  const [customer, setCustomer] = useState([]);

  const customerList = { name: 'Customer List', href: '/customers', current: false };
  const customerTab = { name: 'Customer', href: `/customer/edit/${id}`, current: false };
  const billtoTab = { name: 'Bill to',  current: true };
  
  const tabs = [customerList, customerTab, billtoTab]                                             

  function capitalizeFirstLetter(str) {
      if (!str) return str;
      return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect( () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const fetchCustomer = async () =>{
      console.log(key, '< key');//delete
      console.log(id, '< id');//customer id //delete
      
      if(key === 'edit')
      {
        try {
              const res = await fetch(`${apiUrl}/customer_crud.php?getBillto=${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
              });
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        const data = await res.json();
        console.log(data);
        
        if(data.billto === 'new'){
           navigate(`/customer_billto_edit/${data.customer.id}/bill/new`)
        }

        if(data.error){
          // navigate('/customer')
          toast.error(data.error, {
            position:'top-center'
          });
        }
        setBillTo(data.billto);
        setCustomer(data.customer);

        } catch(error) {
          console.log('Error fetching data', error);
        } finally {
          // setLoading(false);
        }
      } else {
        // setBillTo({jobnum:id})
      }
    }

    fetchCustomer();
  }, [id, key, navigate])

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className='w-[800px] bg-white border rounded-lg py-8 px-8'>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">{capitalizeFirstLetter(key)} - ({customer.customer_name}) Bill to address</h2>
          </div>
          <TopTabs tabs={tabs} />
          <p>Customer ID: {customer.id}</p>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              {billto.map((bill) => (
                <div 
                  key={bill.id}
                  className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                >
                  <div className="min-w-0 flex-1">
                    <a href={`/customer_billto_edit/${customer.id}/${bill.id}/edit`} className="focus:outline-none">
                      <span className="absolute inset-0" aria-hidden="true" />
                      <p className="text-sm font-medium text-gray-900">{bill.name}</p>
                      <p className="text-sm font-medium text-gray-900">{bill.primary_contact}</p>
                      <p className="text-sm font-medium text-gray-900">{bill.address_1}, {bill.address_2}</p>
                      <p className="truncate text-sm text-gray-500">{bill.city} {bill.state} {bill.zip_code}</p>
                      <p className="truncate text-sm text-gray-500"><PhoneNumber number={bill.main_phone}/> {bill.main_phone_extension}</p>
                      <p className="truncate text-sm text-gray-500">{bill.main_email}</p>
                      <div className="w-full border-t border-gray-300 my-2"/>
                      <p className="truncate text-sm text-gray-500">{bill.cc_email}</p>
                      <p className="truncate text-sm text-gray-500">{bill.work_phone_contact}</p>
                      <p className="truncate text-sm text-gray-500">{bill.work_phone ? <PhoneNumber number={bill.work_phone}/> : ''} {bill.work_extension && ''}</p>
                      <p className="truncate text-sm text-gray-500">{bill.mobile_contact}</p>
                      <p className="truncate text-sm text-gray-500">{bill.mobile ?<PhoneNumber number={bill.mobile}/> : ''} {bill.mobile_extension ? bill.mobile_extension : ''}</p>
                      <p className="truncate text-sm text-gray-500">{bill.fax ? <PhoneNumber number={bill.fax}/> : ''}</p>
                      <p className="truncate text-sm text-gray-500">{bill.note}</p>
                    </a>
                    
                  </div>
                </div>
              ))}
            </div>
        </div>
      </div>
      
    </div>
  )
}

export default CustomerBillTo