import React, { useState, useEffect, useCallback } from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, NavLink } from 'react-router-dom';
import HeadingCp from './HeadingCp';
import SubmitButton from './SubmitButton';
import FormVarH from './FormVarH';
import TechTimeEntryCp from './TechTimeEntryCp';
import { UtilConvertTimeStampToDate } from '../utils/UtilConvertTimeStampToDate';
import { UtilConvertDateTodayDate } from '../utils/UtilConvertDateTodayDate';

const TimesheetLogCp = ({ date, payperiod }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/tech_crud.php`;
  const navigate = useNavigate();
  const [timesheetLog, setTimesheetLog] = useState({
    id: '',
    tech_id: '',
    date: '',
    time_type_input: 'SC',
    numberid: 'School',
    address: '',
    contractor: '',
    job_status: '',
    over_hrs: '',
    reg_hrs: '',
    time_in: '',
    time_out: '',
    notes: '',
    pay_period: '',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobSelect, setJobSelect] = useState('');
  const [bodyData, setBodyData] = useState({
    status: 'submit_time',
    current_date: '',
    data: {}
  });

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setTimesheetLog((prev) => ({
      ...prev,
      pay_period: payperiod,
      date: date,
      numberid: 'School',
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleWeek = (job) => {
    setTimesheetLog((prev) => ({
      ...prev,
      address: job.job_site,
      contractor: job.customer_name,
      date: date,
      numberid: job.job_number,
      time_type_input: 'SC',
      id: 'New',
      over_hrs: '',
      notes: '',
      pay_period: payperiod
    }));
    setJobSelect(job);
    if (job) {
      setJobs([]);
      setSearchTerm('');
    }
  };

  const handleSearchClick = async () => {
    setLoading(true);

    const bodyData = {
      status: 'search_job',
      data: { search: searchTerm }
    };

    try {
      const response = await axios.post(`${apiUrl}/tech_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.data && Array.isArray(response.data)) {
        setJobs(response.data);
        setJobSelect('');
      } else {
        setJobs([]);
        toast.error('not found ' + searchTerm, {
          position: 'top-center'
        });
      }
    } catch (error) {
      toast.error("Oops minor issue with the data.", {
        position: "top-center"
      });

      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value === '') {
      setJobs(jobs);
    } else {
      const filtered = jobs.filter(jobs =>
        (jobs.customer_name && jobs.customer_name.toLowerCase().includes(value.toLowerCase())) ||
        (jobs.job_number && jobs.job_number.toLowerCase().includes(value.toLowerCase())) ||
        (jobs.job_site && jobs.job_site.toLowerCase().includes(value.toLowerCase()))
      );
      setJobs(filtered);
    }
  };

  const updateBodyData = useCallback(() => {
    setBodyData((prev) => ({
      ...prev,
      current_date: date,
      data: { ...timesheetLog }
    }));
  }, [date, timesheetLog]);

  useEffect(() => {
    updateBodyData();
  }, [timesheetLog, updateBodyData]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    //console.log(bodyData);
    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data[0] === 'success') {
        toast.info('Saved!', {
          position: 'top-center'
          
        });
        setTimeout(() => {
          navigate('/t/twkt');
        }, 1000); // Delay navigation for 2 seconds
      } else {
        toast.error(response.data, {
          position: 'top-center'
        });
        //console.log(response.data);
      }
    } catch (error) {
      toast.error("Oops minor issue with the data.", {
        position: "top-center"
      });

      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      }
    }
  };

  return (
    <>
      <br />
      <HeadingCp label={"School entry"} />
      <>
        <form
          onSubmit={handleFormSubmit}
          className='px-6 bg-white border rounded-lg py-8 mt-4'>
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              {/* <h6 className="text-base font-semibold leading-7 text-gray-600">
                <FormVarH label={'Sr'}
                  inside={'numberid'}
                  val={timesheetLog.numberid}
                  onChange={handleChange} required />
              </h6> */}

              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <FormVarH label={'School Name'}
                  inside={'address'}
                  val={timesheetLog.address}
                  onChange={handleChange} required />

                <TechTimeEntryCp handleChange={handleChange} timesheetLog={timesheetLog} />
              </div>
            </div>
          </div>
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <SubmitButton disabled={''}>Submit</SubmitButton>
          </div>
          <br/>
          <br/>
        </form>
        
      </>
    </>
  );
};

export default TimesheetLogCp;
