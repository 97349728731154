import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TrashIcon } from '@heroicons/react/24/outline';

const EstimateAddCustomNotes = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { id } = useParams();
  const [customeNotes, setCustomNotes] = useState([]);
  
  const [submitObject, setSubmitObject] = useState({
    notes: '',
    cat: 'Service', // Default to 'Service'
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/estimate_crud.php`, {
          params: { getCustomNotes: id },
        });
        if (response.data[0] === 'success') {
          setCustomNotes(response.data[1]);
        } else {
          toast.error('No data found', {
            position: 'top-center',
          });
        }
      } catch (error) {
        toast.error("Oops minor issue with the data.", {
        position: "top-center"
        });

        if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
        navigate('/login');
        } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
        });
        }
      }
    };

    if (id) {
      fetchData();
    }
  }, [id, apiUrl]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubmitObject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addCustomNotes = async () => {
    const bodyData = {
      data: submitObject,
      status: 'addCustomNotes',
      estimate_id: id,
    };
    try {
      const response = await axios.post(`${apiUrl}/estimate_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data[0] === 'success') {
        toast.success('Custom notes added!', {
          position: 'top-center',
        });
        setCustomNotes((prev) => [...prev, { 
          id: response.data[1].id,
          estimate_id: response.data[1].estimate_id,
          notes: response.data[1].notes,
          cat: response.data[1].cat,
          ...submitObject, 

        }]);
        setSubmitObject({
          notes: '',
          cat: 'Service',
        });
      } else {
        toast.error(response.data.error || 'Failed to add custom notes.', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error('Oops minor issue with the data.', {
        position: 'top-center',
      });

      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center',
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: 'top-center',
        });
      }
    }
  };

  const delCustomeNotes = async(customID) => {
    // e.preventDefault();
      try {
        const response = await axios.post(`${apiUrl}/estimate_crud.php`, {
          status: 'delCustomNotes',
          data: { notes_id: customID },
          headers: { 'Content-Type': 'application/json' },
        });
        if (response.data[0] === 'success') {
          toast.success('Deleted', {
            position: 'top-center',
          });
        setCustomNotes((prevList) => prevList.filter((item) => item.id !== customID));
        } else {
          toast.error(response.data.message || 'Failed to delete.', {
            position: 'top-center',
          });
        }
    } catch (error) {
        toast.error("Oops minor issue with the data.", {
        position: "top-center"
        });

        if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
        navigate('/login');
        } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
        });
        }
    }
  }

  return (
    <div className="mx-auto max-w-[800px] sm:px-6 lg:px-6 py-8 bg-white">
      <h6 className="text-md font-semibold border-b border-gray-900/10 pb-8">Services Notes</h6>

      <form onSubmit={(e) => { e.preventDefault(); addCustomNotes(); }} className="space-y-6 pb-7">
        <div className="form-group">
          <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
            Custom Notes:
          </label>
          <textarea
            id="notes"
            name="notes"
            rows="5"
            className="form-control mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={submitObject.notes}
            onChange={handleChange}
            required
          ></textarea>
        </div>

        <div className="form-group pb-10">
          <label htmlFor="cat" className="block text-sm font-medium text-gray-700">
            Category Notes:
          </label>
          <select
            id="cat"
            name="cat"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                     focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm max-w-[300px]"
            value={submitObject.cat}
            onChange={handleChange}
            required
          >
            <option value="Service">Services</option>
            <option value="Terms and Conditions">Terms and Conditions</option>
            <option value="Payment Terms and Conditions">Payment Terms and Conditions</option>
          </select>
        </div>

        <button
          type="submit"
          className="btn btn-primary mt-4 inline-flex justify-center rounded-md border border-transparent 
                   bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none 
                   focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          Add
        </button>
      </form>

      <div className='pb-6'>
        <ul className="max-w-[800px] mx-auto">

        <p className='pb-3'>
          {customeNotes.id ? 'List:':''}
        </p>

          {customeNotes.map((row) => (
            <li key={row.id} className="py-1">
              <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-t
                              border-gray-200 bg-white">
              
                <div className="flex-1 truncate px-4 py-2 text-sm">
                  <p className="inline-block text-gray-800 font-bold">
                    {row.cat}:
                  </p>
                  <span className="inline-block ml-4 font-light text-gray-900">
                    {row.notes}
                  </span>
                </div>

                <div className="flex-shrink-0 pr-2">
                  <button
                    type="button"
                    onClick={(e) => delCustomeNotes(row.id)}
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">Delete</span>
                    <TrashIcon className="h-5 w-5 text-red-600" aria-hidden="true" />
                  </button>
                </div>

              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default EstimateAddCustomNotes;
