import React, { useState } from 'react';
import { UtilConvertDateTodayDate } from '../utils/UtilConvertDateTodayDate';

const ModalTechTimesheet = ({ timesheet_logs, hrs, payperiod, tech_name, onSave }) => {
  const [logs, setLogs] = useState(timesheet_logs); // Local state for the timesheet logs
  const [editIndex, setEditIndex] = useState(null); // Track the index of the item being edited

  const handleInputChange = (index, field, value) => {
    const updatedLogs = logs.map((log, i) => 
      i === index ? { ...log, [field]: value } : log
    );
    setLogs(updatedLogs);
  };

  const handleSave = (index) => {
    onSave(logs[index]); // Pass the updated log to the parent component
    setEditIndex(null); // Exit edit mode after saving
  };

  const toggleEditMode = (index) => {
    setEditIndex(index === editIndex ? null : index); // Toggle edit mode for the selected line item
  };

  return (
    <div className="mx-auto w-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-2xl font-semibold text-gray-500 mb-8">{tech_name}</h1>

      <table className="min-w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg" style={{ padding: '2px' }}>
        <thead>
          <tr className='divide-x divide-gray-400'>
            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-2">
              Date
            </th>
            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
              Type
            </th>
            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-2">
              Time in
            </th>
            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-2">
              Time out
            </th>
            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell max-w-[50px]">
              Reg hrs
            </th>
            <th scope="col" className="hidden pl-2 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell max-w-[50px]">
              OT hrs
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Customer
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {logs.map((fetchData, index) => (
            <tr key={fetchData.id} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-200'} 
                                               ${fetchData.job_status === 'COMPLETED' ? 'bg-green-400' : 'bg-gray-200'} 
                                               ${fetchData.numberid === "OFF" ? 'bg-orange-200' : 'bg-gray-200'} 
                                               divide-x border-gray-500`}>
              <td className="w-full max-w-0 py-2 pl-4 pr-3 text-sm font-medium text-gray-700 sm:w-auto sm:max-w-none sm:pl-2">
                {editIndex === index ? (
                  <input
                    type="text"
                    className="bg-transparent border-b-2 border-gray-300 focus:border-blue-500 outline-none"
                    value={UtilConvertDateTodayDate(fetchData.date)}
                    onChange={(e) => handleInputChange(index, 'date', e.target.value)}
                  />
                ) : (
                  <span className='text-xs'>{UtilConvertDateTodayDate(fetchData.date)}</span>
                )}
              </td>

              <td className="hidden px-3 py-2 text-sm text-gray-500 sm:table-cell">
                {editIndex === index ? (
                  <input
                    type="text"
                    className="bg-transparent border-b-2 border-gray-300 focus:border-blue-500 outline-none max-w-[120px]"
                    value={fetchData.numberid}
                    onChange={(e) => handleInputChange(index, 'numberid', e.target.value)}
                  />
                ) : (
                  fetchData.numberid
                )}
              </td>
              
              <td className="hidden px-3 py-2 text-sm text-gray-500 sm:table-cell">
                {editIndex === index ? (
                  <input
                    type="text"
                    className="bg-transparent border-b-2 border-gray-300 focus:border-blue-500 outline-none max-w-[100px]"
                    value={fetchData.time_in}
                    onChange={(e) => handleInputChange(index, 'time_in', e.target.value)}
                  />
                ) : (
                  fetchData.time_in
                )}
              </td>

              <td className="hidden px-3 py-1 text-sm text-gray-500 lg:table-cell">
                {editIndex === index ? (
                  <input
                    type="text"
                    className="bg-transparent border-b-2 border-gray-300 focus:border-blue-500 outline-none max-w-[100px]"
                    value={fetchData.time_out}
                    onChange={(e) => handleInputChange(index, 'time_out', e.target.value)}
                  />
                ) : (
                  fetchData.time_out
                )}
              </td>

              <td className={`hidden px-3 py-2 text-sm text-gray-500 sm:table-cell max-w-[70px] truncate
                             ${fetchData.reg_hrs === 0 ? 'bg-red-600 text-white' : ''}`}>
                {editIndex === index ? (
                  <input
                    type="text"
                    className="bg-transparent border-b-2 border-gray-300 focus:border-blue-500 outline-none max-w-[70px]"
                    value={fetchData.reg_hrs < 0 || fetchData.reg_hrs == null ? '0' : fetchData.reg_hrs}
                    onChange={(e) => handleInputChange(index, 'reg_hrs', e.target.value)}
                  />
                ) : (
                  `${fetchData.reg_hrs < 0 || fetchData.reg_hrs == null ? '0' : fetchData.reg_hrs} hrs`
                )}
              </td>

              <td className={`hidden px-3 py-2 text-sm text-gray-500 sm:table-cell max-w-[70px] truncate
                             ${fetchData.over_hrs > 4 ? 'bg-yellow-500 text-white' : ''}`}>
                {editIndex === index ? (
                  <input
                    type="number"
                    className="bg-transparent border-b-2 border-gray-300 focus:border-blue-500 outline-none max-w-[70px]"
                    value={fetchData.over_hrs < 0 || fetchData.over_hrs == null ? '0' : fetchData.over_hrs}
                    onChange={(e) => handleInputChange(index, 'over_hrs', e.target.value)}
                  />
                ) : (
                  `${fetchData.over_hrs < 0 || fetchData.over_hrs == null ? '0' : fetchData.over_hrs} hrs`
                )}
              </td>

              <td className="hidden pl-2 py-2 text-sm text-gray-500 sm:table-cell max-w-[350px]">
                {editIndex === index ? (
                  <input
                    type="text"
                    className="bg-transparent border-b-2 border-gray-300 focus:border-blue-500 outline-none"
                    value={fetchData.address}
                    onChange={(e) => handleInputChange(index, 'address', e.target.value)}
                  />
                ) : (
                  <p className='text-xs text-gray-600'>{fetchData.address}</p>
                )}
                <span className='text-xs text-gray-500'>{fetchData.contractor}</span>
              </td>

              <td className="px-3 py-2 text-sm text-gray-500">
                {editIndex === index ? (
                  <>
                    <button
                      onClick={() => handleSave(index)}
                      className="text-green-500 hover:text-green-700 mr-2"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => toggleEditMode(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => toggleEditMode(index)}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    Edit
                  </button>
                )}
              </td>
              
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ModalTechTimesheet;
