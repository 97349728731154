import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import DateFormat from './DateFormat';
import { useNavigate } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Alerts = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [formData, setFormData] = useState({
    id: '',
    comment: '',
    pin: 'Reminder',
    date: '',
    status: 'Pending',
    location: '#',
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setFormData({
      id: '',
      comment: '',
      pin: 'Reminder',
      date: '',
      status: 'Pending',
      location: '#',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'comment' && value.length > 128) {
      toast.error('Message cannot exceed 128 characters', { position: 'top-center' });
      return;
    }
    setFormData((prevData) => ({
      ...prevData, 
      [name]: value 
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Trim the comment to remove any leading or trailing spaces
    const trimmedComment = formData.comment.trim();

    // Get the current date in YYYY-MM-DD format
    const currentDate = new Date().toISOString().split('T')[0];

    // Check if the trimmed comment is empty
    if (trimmedComment === '') {
      toast.error('message cannot be blank or just spaces.', { position: 'top-center' });
      return; // Stop form submission
    }

    // Check if the selected date is the same as the current date
    if (formData.date === currentDate) {
      toast.error('Cannot submit an alert for the same date as today.', { position: 'top-center' });
      return; // Stop form submission
    }

    try {
      const response = await axios.post(`${apiUrl}/alert_crud.php`, { ...formData, status: 'submit' }, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data[0] === 'success') { closeModal(); }
    } catch (error) {
      console.error('Error submitting alert', error);
    }
  };

  const fetchAlerts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/alert_crud.php`, { params: { getAlerts: true } });
      if (response.data && Array.isArray(response.data)) {
        setAlerts(response.data);
      } 
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  const readAlert = async (id, location) => {
    try {
      const response = await axios.post(`${apiUrl}/alert_crud.php`, { status: 'read_alert', id }, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data[0] === 'success') {
        setAlerts(alerts.filter(alert => alert.id !== id));
        navigate(`/${location}`);
      }
    } catch (error) {
      console.error('Error updating alert status', error);
    }
  }

  useEffect(() => {
    fetchAlerts();
  }, []);

  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
      <div className="flex">
        <div className="w-4/5 px-1 py-2 sm:px-2 bg-gray-700">
          <p className="text-center text-white">Alerts / Pending</p>
        </div>
        <div className="w-1/5 px-3 py-2 sm:px-4 bg-green-600 hover:cursor-pointer hover:bg-green-700" onClick={openModal}>
          <p className="text-center text-white"><PlusIcon className='h-6'/></p>
        </div>
      </div>
      <div className="px-1 py-2 sm:p-2 text-center">
        <ul role="list" className="divide-y divide-gray-200">
          {alerts.map((alert) => (
            <li
              key={alert.id}
              className="flex items-center justify-between gap-x-6 py-4 hover:bg-blue-100 p-4 hover:cursor-pointer"
              onClick={() => readAlert(alert.id, alert.location)}
            >
              <div className="min-w-0">
                <div className="flex items-start gap-x-2">
                  <p className="text-sm font-semibold leading-5 text-gray-900">{alert.comment}</p>
                </div>
                <div className="mt-1 flex items-center gap-x-1 text-xs text-gray-500">
                  <p><DateFormat date={alert.date} /></p>
                </div>
                <div className="mt-1 flex items-center gap-x-1 text-xs text-gray-500">
                  <p className="truncate">Pin: {alert.pin}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Create Alert
                  </Dialog.Title>
                  <form onSubmit={handleSubmit} className="mt-4">
                    <div className="space-y-4">
                      <div>
                        <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
                          Message
                        </label>
                        <input
                          type="text"
                          name="comment"
                          id="comment"
                          value={formData.comment}
                          onChange={handleChange}
                           maxLength="128"  // Limit to 128 characters
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          required
                        />
                      </div>

                      <div>
                        <label htmlFor="pin" className="block text-sm font-medium text-gray-700">
                          To-do
                        </label>
                        <input
                          type="text"
                          name="pin"
                          id="pin"
                          value={formData.pin}
                          onChange={handleChange}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          required
                        />
                      </div>

                      <div>
                        <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                          Date
                        </label>
                        <input
                          type="date"
                          name="date"
                          id="date"
                          value={formData.date}
                          onChange={handleChange}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          required
                        />
                      </div>

                      {/* <div>
                        <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                          Location
                        </label>
                        <input
                          type="text"
                          name="location"
                          id="location"
                          value={formData.location}
                          onChange={handleChange}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div> */}
                    </div>

                    <div className="mt-6 flex justify-end space-x-2">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Alerts;
