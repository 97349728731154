import React from 'react';

const ImageLocationCp = ({ image, where, notFound, className }) => {
   const appRoot = process.env.REACT_APP_ROOT_URL;

  if(image){
     var avatarUrl = `${appRoot}/${where}/${image}`;
  } else {
     var avatarUrl = `${appRoot}/${where}/${notFound}`;  
  }

  return <img className={className} src={avatarUrl} alt="Image Missing" />;
};

export default ImageLocationCp;
