import React from 'react'
import { Outlet } from 'react-router-dom';
import NavBar from '../components/NavBar';
import { UserProvider } from '../context/UserData';


const MainLayout = () => {
  return (
    <>
    <UserProvider>
      <NavBar />
      <Outlet />
    </UserProvider>
    </>
  )
}

export default MainLayout