import React, { useState } from 'react';

const Truncate = ({ text, handleNotesView }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = (event) => {
    event.preventDefault();
    setIsExpanded(!isExpanded);
  };

  if (text.length <= 120) {
    return <span>{text}</span>;
  }

  return (
    <span>
      {isExpanded ? text : text.substring(0, 120) + '...'}
      <a onClick={handleNotesView} style={{ color: 'blue', cursor: 'pointer', marginLeft: '5px' }}>
        ...more
      </a>
    </span>
  );
};

export default Truncate;
