import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import SubMenu from '../components/SubMenu';
import DateFormat from '../components/DateFormat';
import SearchBarCP from '../components/SearchBarCP';
import Modal from '../components/Modal';
import FileLocation from '../components/FileLocation';
import ModalWindow from '../components/ModalWindow';
import ModalServiceProposal from '../components/ModalServiceProposal';
import { useUserData } from '../context/UserData';
import FormHeader from '../components/FormHeader';
import FormVarH from '../components/FormVarH';
import useSubmitPost from '../hooks/handleSubmitPost';
import SubmitButton from '../components/SubmitButton';
import LoadingSpinner from '../components/LoadingSpinner';
import FormVarTF from '../components/FormVarTF';
import FormMoney from '../components/FormMoney';
import CurrencyFormat from '../components/CurrencyFormat';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ServiceProposalPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/service_crud.php`;
  const [newDelivery, setNewDelivery] = useState(false);
  const [newServProposal, setnewServProposal] = useState({
    job_number: '',
    notes: '',
  });
  const [fetchItems, setFetchItems] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalFileOpen, setIsModalFileOpen] = useState(false);
  const [selectDeliveryId, setSelectDeliveryId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [notesIdOpen, setNotesIdOpen] = useState(null);
  const userData = useUserData();
  const [bodyDataNotes, setBodyDataNotes] = useState({
    status: 'submit_dt_notes',
    employee_id: null,
    data: {}
  });

  const handleUploadFile = (e) => {
    e.preventDefault();
    handleFormSubmit(file, selectDeliveryId); 
    setFile(null);
    setIsModalFileOpen(false);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      toast.error("No file selected.", { position: 'top-center' });
      setFile(null);
      return;
    }

    const validFileTypes = ['application/pdf'];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (!validFileTypes.includes(selectedFile.type)) {
      toast.error("Invalid file type. Only PDF is allowed.", { position: 'top-center' });
      setFile(null);
      return;
    }

    if (selectedFile.size > maxSize) {
      toast.error("File size exceeds 2MB.", { position: 'top-center' });
      setFile(null);
      return;
    }

    setFile(selectedFile);
    //console.log(selectedFile, 'selected file');
  };

  const handleFormSubmit = async (file, selectDeliveryId) => {
      if (!file) {
          toast.error("Please upload a valid file.", { position: 'top-center' });
          return;
        }
        const formData = new FormData();
        formData.append('status', 'submit_service_proposal');
        formData.append('id', selectDeliveryId); // Include certificate ID
        formData.append('file', file);
        try {
          const response = await axios.post(`${apiUrl}/service_proposal_file.php`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          });
          setFilterItems(object => object.map(item => {
            if (item.id === parseInt(response.data.id)) {
              return { ...item, file: response.data.delivery_receipt.file, 
                                status: response.data.delivery_receipt.status};
            }
            return item;
          }));

          if (response.data.success) {
            toast.info('Saved!', { position: 'top-center' });
          } else {
            toast.error(response.data.error, { position: 'top-center' });
          }
        } catch (error) {
          toast.error("Oops minor issue with the data.", { position: "top-center" });
          if (error.response && error.response.status === 401) {
            toast.error("Session expired. Please login again.", { position: "top-center" });
            navigate('/login');
          } else if (error.response && error.response.status === 403) {
            toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: "top-center" });
          }
        }
  };

  const getLinksForJob = (object) => {
    let links = [
      { name: 'Add Service', click: () => setNotesIdOpen(object.id), key: `add-service-${object.id}` },
      { name: object.status === 'Approved' ? 'Status Pending' : 'Status Approved', 
            click: () => {
              if (object.status === 'Approved') {
                completeStatus(object.id, 'Pending');
              } else {
                completeStatus(object.id, 'Approved');
              }
            }, key: `complete-${object.id}`
          },
     { name: object.file ? 'Update Proposal' : 'Upload Signed Proposal', 
                        click: () => { setIsModalFileOpen(true); setSelectDeliveryId(object.id) }, 
                          key: `upload-delivery-${object.id}` 
                      },
     { name: 'Delete Proposal', click: () => handleDelete(object.id), key: `add-delete-${object.id}` },
    ];
    return links;
  };

  const completeStatus = async (id, status) => {
        setLoading(true);
        const bodyData = {
          status: 'update_status_service_proposal',
          data: { dt_id: id, dt_status: status }
        };

        try {
          const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
            headers: { 'Content-Type': 'application/json' }
          });

          if (response.data === 'success') {
            setFilterItems(objects => objects.map(item => {
              if (item.id === id) {
                return { ...item, status: status };
              }
              return item;
            }));

            toast.success('Saved!', { position: 'top-center' });
          } else {
            toast.error('error updating', { position: 'top-center' });
          }
    } catch (error) {
      console.error('Error updating status', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event, id, user) => {
    const { name, value } = event.target;
    setFilterItems(prev => prev.map(item => {
      if (parseInt(item.id) === id) {
        const updateDelivery = { ...item, [name]: value };
        bodyDataNotes.data = updateDelivery;
        updateDelivery.employee_name = `${user.first_name} ${user.last_name}`;
        return updateDelivery;
      } else {
        return item;
      }
    }));
    setBodyDataNotes(prev => ({
      ...prev,
      employee_id: user.id,
      data: { ...prev.data, [name]: value }
    }));
  };

  const handleSearchClick = async () => {
    if (!searchTerm) {
      toast.info('Search bar empty', { position: 'top-center' });
    } else {
      setLoading(true);

      const bodyData = {
        status: 'searchSevProposal',
        data: { search: searchTerm }
      };

      try {
        const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
          headers: { 'Content-Type': 'application/json' }
        });
        if (response.data && Array.isArray(response.data)) {
          setFetchItems(response.data);
          setFilterItems(response.data);
        } else {
          handleSearchChange();
          setFetchItems([]);
          setFilterItems([]);
          toast.error(`Not found: ${searchTerm}`, { position: 'top-center' });
        }
      } catch (error) {
        console.error('Error searching deliveries', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value === '') {
      setFilterItems(fetchItems);
    } else {
      const filtered = fetchItems.filter(object =>
        (object.description && object.description.toLowerCase().includes(value.toLowerCase())) ||
        (object.job_number && object.job_number.toLowerCase().includes(value.toLowerCase())) ||
        (object.job_site && object.job_site.toLowerCase().includes(value.toLowerCase()))
      );
      setFilterItems(filtered);
    }
  };

  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setnewServProposal((prev) => ({ ...prev, [name]: value }));
  };

  const handleNewServiceProposal = async (e) => {
    e.preventDefault();
    const bodyData = {
      data: newServProposal,
      status: 'submit_service_proposal'
    };
    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        toast.success("Delivery ticket successfully saved!", { position: "top-center" });
        setFilterItems((prev) => [response.data.success, ...prev]);
        setNewDelivery(false);
      } else {
        toast.error(response.data.error || "Failed to save delivery ticket.", { position: "top-center" });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.", { position: "top-center" });
    }
    
  };

  const handleDelete = async (service_proposal_id) => {
    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, {
        status: 'delete_service_proposal',
        data: { dt_id: service_proposal_id},
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data == 'success') {
        toast.success("Receipt deleted successfully!", {
          position: "top-center"
        });
          setFilterItems(filterItems.filter(item => item.id !== service_proposal_id));   
      } else {
        toast.error(response.data.message || "failed deleting receipt!", {
          position: "top-center"
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.", {
        position: "top-center"
      });
    }
  } 

  useEffect(() => {
    setLoading(true);
    const fetchDeliveryData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/service_crud.php?getServProposal=true`, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        });
        const service_proposal = Array.isArray(response.data) ? response.data : [];

        if (service_proposal.length === 0) {
          toast.error("No Proposal found!", { position: "top-center" });
        }
        setFilterItems(service_proposal);
        setFetchItems(service_proposal);
      } catch (error) {
        toast.error("Oops minor issue with the data.", { position: "top-center" });

        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", { position: "top-center" });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: "top-center" });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchDeliveryData();
  }, [apiUrl, navigate]);

  return (
    <div className="mx-auto max-w-6xl  sm:px-6 lg:px-8  py-10">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 py-4">Service Proposals</h1>
        </div>
      </div>

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">

          <div className="sm:flex sm:items-center py-6">
            <div className="flex w-full items-center mt-5 sm:mt-0">
              <button
                type="button"
                onClick={() => setNewDelivery(true)}
                className="mr-2 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                New Proposal
              </button>

              {newDelivery && (
                <ModalWindow job={
                       <div className="flex items-center justify-center py-5 fit-content">
                          <form
                            onSubmit={handleNewServiceProposal}
                            className="w-full rounded-lg"
                          >
                            <div className="space-y-12">
                              <FormHeader type={'New'} what={'Service Proposal'} />
                              <div className="sm:col-span-2">
                                <FormVarH label={'Job Site'} inside={'job_site'} val={newServProposal.job_site || ''} onChange={handleNewInputChange} required={true} />
                                <br/>
                              </div>
                              <div className="sm:col-span-2">
                                <FormMoney label={'Price'} inside={'price'} val={newServProposal.price || ''} onChange={handleNewInputChange} required={true} />
                                <br/>
                              </div>
                            
                              <div className="sm:col-span-6">
                                <label htmlFor="notes" className="block text-sm font-medium leading-6 text-gray-900">
                                  Service
                                </label>
                                <div className="mt-2">
                                  <textarea
                                    rows={4}
                                    name="notes"
                                    id="notes"
                                    value={newServProposal.notes || ''}
                                    onChange={handleNewInputChange}
                                    className="text-slate-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>

                              <div className="mt-6 flex items-center justify-end gap-x-6">
                                <button
                                  type="button"
                                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                  onClick={() => setNewDelivery(false)}
                                >
                                  Close
                                </button>
                                <SubmitButton>Submit</SubmitButton>
                              </div>
                            </div>
                          </form>
                        </div>
                } closeNotes={() => setNewDelivery(false)} />
              )}

              <div className="flex-grow">
                <SearchBarCP search={searchTerm} handleChange={handleSearchChange} onClick={handleSearchClick} />
              </div>
            </div>
          </div>

          <div className="inline-block min-w-full py-2 align-middle">
            {loading ? (
              <LoadingSpinner children={'Loading...'}/>
            ) : (
              <div className="shadow ring-1 ring-black ring-opacity-5">
                <table className="min-w-full border-separate border-spacing-0">
                  <thead className=" top-0 z-30 bg-white">
                    <tr>
                      <th className=" top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                        ID/edit
                      </th>

                      <th className=" top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                        Service Number
                      </th>

                      <th className=" top-0 z-30 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">
                        Service
                      </th>

                      <th className=" top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                        Status
                      </th>

                      <th className=" top-0 z-30 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                        Price
                      </th>                      

                      <th className=" top-0 z-30 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                        Signed
                      </th>

                      <th className=" top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                        Employee Name
                      </th>

                      <th className=" top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                        Date
                      </th>                      
                    </tr>
                  </thead>
                  <tbody>
                    {filterItems.map((object, objectIdx) => (
                      <tr key={object.id} className={`${objectIdx % 2 === 0 ? 'bg-white' : 'bg-gray-100'} divide-x`}>

                        <td className={classNames(
                          objectIdx !== fetchItems.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
                        )}>
                          <SubMenu label={object.id} idItem={object.id} links={getLinksForJob(object)} />
                        </td>

                        <td className={classNames(
                          objectIdx !== fetchItems.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
                        )}>
                          <span className='text-gray-800'>{object.job_number}</span><br />
                          <span className='text-gray-500'>{object.job_site}</span>
                        </td>

                        <td className={classNames(
                          objectIdx !== fetchItems.length - 1 ? 'border-b border-gray-200' : '',
                          'hidden px-3 py-4 text-sm text-gray-500 lg:table-cell',
                        )}>
                          {object.notes}
                           {notesIdOpen === object.id && (
                            <ModalWindow job={
                              <ModalServiceProposal
                                onChange={(e) => handleChange(e, object.id, userData?.users)}
                                api={api}
                                inside={'notes'}
                                val={object}
                                bodyData={bodyDataNotes}
                                // redirectPath={'/delivery_tracking'}
                                closeNotes={() => setNotesIdOpen(null)}
                              />
                            } closeNotes={() => setNotesIdOpen(null)} />
                          )}
                        </td>

                        <td className={classNames(
                          objectIdx !== fetchItems.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
                        )}>
                          {object.status}
                        </td>

                        <td className="px-4">
                          <CurrencyFormat value={object.price}/>
                        </td>                        

                        <td className="px-4">
                          <FileLocation expFile={object.file} where={'delivery_receipt'}/>
                        </td>                        

                        <td className={classNames(
                          objectIdx !== fetchItems.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
                        )}>
                          {object.employee_name}
                        </td>

                        <td className={classNames(
                          objectIdx !== fetchItems.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
                        )}>
                          <DateFormat date={object.date} />
                        </td>                        

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={isModalFileOpen} onClose={() => setIsModalFileOpen(false)}>
        <h2 className="text-lg font-semibold text-gray-900">Upload Delivery Receipt</h2>
        <form onSubmit={handleUploadFile}>
          <div className="form-group">
            <input
              type="file"
              name="file"
              id="file"
              onChange={handleFileChange}
              required
              className="mt-1 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
            />
          </div>
          <div className="mt-9 float-right">
            <button
              type='submit'
              className={`inline-flex justify-center rounded-md border border-transparent
                        py-2 px-4 text-sm font-medium text-white shadow-sm bg-green-600
                        focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}>
              Upload
            </button>
          </div>
        </form>
      </Modal>
      
    </div>
  );
}

export default ServiceProposalPage;
