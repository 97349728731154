import React from 'react';
import { HomeIcon } from '@heroicons/react/20/solid';
import { ListBulletIcon } from '@heroicons/react/24/outline';

const EstimateBar = ({ id, name }) => {

  // Create a dynamic list of pages with current page highlighted
  const pages = [
    { name: 'Estimate', href: `/estimate_pro/${id}`, current: name === 'Estimate' },
    { name: 'Install', href: `/installation_details/${id}`, current: name === 'Install' },
    { name: 'Equipment', href: `/estimate_equipment/${id}`, current: name === 'Equipment' },
    { name: 'Wire', href: `/estimate_wire/${id}`, current: name === 'Wire' },
    { name: 'Elect Contractor', href: `/estimate_contr/${id}`, current: name === 'Elect Contractor' },
    { name: 'Notes', href: `/estimate_notes/${id}`, current: name === 'Notes' },
    { name: 'Miscellaneous', href: `/estimate_misc/${id}`, current: name === 'Miscellaneous' },
    { name: 'Detail', href: `/estimate_detail/${id}`, current: name === 'Detail' },
    { name: 'Proposal', href: `/estimate_proposal/${id}`, current: name === 'Proposal' },
  ];

  return (
    <nav aria-label="Breadcrumb" className="flex border-b border-gray-200 bg-white mb-8">
      <ol role="list" className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
        <li className="flex">
          <div className="flex items-center">
            <a href="/estimate" className="text-gray-400 hover:text-gray-500">
              <ListBulletIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0" />
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name} className="flex">
            <div className="flex items-center">
              <svg
                fill="currentColor"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                aria-hidden="true"
                className="h-full w-6 flex-shrink-0 text-gray-200"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <a
                href={page.href}
                aria-current={page.current ? 'page' : undefined}
                className={`ml-4 text-sm font-medium ${page.current ? 'text-blue-700 px-3 py-1 rounded' : 'text-gray-500 hover:text-gray-700'}`}
              >
                {page.name}
              </a>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default EstimateBar;
