import React, { forwardRef, useState } from 'react';
import FormHeader from './FormHeader';
import useSubmitPost from '../hooks/handleSubmitPost';
import SubmitButton from './SubmitButton';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getTodaysDateUtil } from '../utils/DateFormatTodayUtil';
import FormVarH from './FormVarH';

const ModalVendorSection = ({ api, val, inside, onChange, bodyData, redirectPath, closeNotes }) => {
  const handleSubmit = useSubmitPost();
  const initialDate = val.start_date ? val.start_date : getTodaysDateUtil() ;
  const [todayDate, setTodayDate] = useState(initialDate);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="bg-blue-400 p-1 rounded-md text-white border-l" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  return (
    <>
      <div className="flex items-center justify-center py-5 fit-content">
        <form
          onSubmit={(event) => handleSubmit(event, {
            bodyData,
            api,
            errorLabel: 'Project',
            redirectPath: redirectPath,
          })}
          className="w-full rounded-lg"
        >
          <div className="space-y-12">
            <FormHeader type={'Edit'} what={'Vendor'} />

            <div className="sm:col-span-2">

            <FormVarH label={'Vendor Name'} inside={'vendor_name'} val={val.vendor_name || ''} onChange={onChange} required={true} />
            <br/>

            <FormVarH label={'Invoice Number'} inside={'adv_inv_num'} val={val.adv_inv_num || ''} onChange={onChange} required={false} />
            <br/>
            
            <label htmlFor="vendor_rate_paid" className="block text-sm font-medium leading-6 text-gray-900">
                Paid % <span className='text-red-500'>*</span>
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="vendor_rate_paid"
                  id="vendor_rate_paid"
                  maxLength="3"
                  minLength="1"
                  value={val.vendor_rate_paid || ''} 
                  onChange={onChange}
                  required={true}
                  className="text-slate-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
           
            <div className="sm:col-span-6">
              <label htmlFor={inside} className="block text-sm font-medium leading-6 text-gray-900">
                Notes
              </label>
              <div className="mt-2">
                <textarea
                  rows={4}
                  name={inside}
                  id={inside}
                  value={val.notes || ''}
                  onChange={onChange}
                  className="text-slate-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={closeNotes}
              >
                Close
              </button>
              <SubmitButton>Submit</SubmitButton>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ModalVendorSection;
