import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import SearchBarCP from '../components/SearchBarCP';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DateFormat from '../components/DateFormat';
import LoadingSpinner from '../components/LoadingSpinner';

const InvHistory = () => {
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState([]);
  const [filteredInvoice, setFilteredInvoice] = useState([]);
  
  const [bodyDataNotes, setBodyDataNotes] = useState({
                                                        status: 'submit_notes',
                                                        user_id: '',
                                                        data: {}
                                                      });

  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const { key } = useParams();
  
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/inv_history_crud.php`;

  useEffect(() => {
    fetchInvoice();
  }, []);

  const fetchInvoice = async () => {
    try {
      const response = await axios.get(api, {
        params: {
          getList: key
        }
      });
      const data = response.data;
      if (data.error) {
        toast.error(data.error, {
          position: 'top-center'
        });
      } else {
        // setInvoice(Array.isArray(data) ? data : []);
        // setFilteredInvoice(Array.isArray(data) ? data : []);
          if (data && Array.isArray(data)) {
          setInvoice(data);
          setFilteredInvoice(data);
        } else {
          handleSearchChange();
          setInvoice([]);
          setFilteredInvoice([]);
          toast.error('Error found ', {
            position: 'top-center'
          });
        }
      }
    } catch (error) {
      console.log('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value === '') {
      setFilteredInvoice(invoice);
    } else {
      const filtered = invoice.filter(inv =>
        (inv.description && inv.job_site.toLowerCase().includes(value.toLowerCase())) ||
        (inv.job_site && inv.job_number.toLowerCase().includes(value.toLowerCase()))
      );
      setFilteredInvoice(filtered);
    }
  };

  const handleSearchClick = async () => {
    setLoading(true);
    const bodyData = {
      status: 'search',
      data: { search: searchTerm }
    };

    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });

      const data = response.data;
      if (data && Array.isArray(data)) {
        setInvoice(data);
        setFilteredInvoice(data);
      } else {
        setInvoice([]);
        setFilteredInvoice([]);
        toast.error('Not found', {
          position: 'top-center'
        });
      }
    } catch (error) {
      console.error('Error searching active jobs', error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      
      <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-2xl font-semibold text-gray-500 mb-8">Invoice History</h1>
        <br />
        <br />
        <div className="flex space-x-3 items-center mb-10">
          <SearchBarCP search={searchTerm} handleChange={handleSearchChange} onClick={handleSearchClick} />
        </div>

        {loading ? (
          <LoadingSpinner children={'Loading...'}/>
        ) : (
          <table className="min-w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg">
            <thead>
              <tr className='divide-x divide-gray-400'>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-2">
                  ID
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                  Employee Name
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-2">
                  Invoiced Rate
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-2">
                  Paid Rate
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Proposal #
                </th>
                <th scope="col" className="hidden pl-2 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Job Site
                </th>
                <th scope="col" className="hidden pl-2 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredInvoice.map((invoice, index) => (
                <tr key={invoice.id} className={`${invoice.job_status == 'void' ? 'line-through text-red-600' : ''} 
                                                  ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} 
                                                  ${invoice.approved_paperwork === 1 ? 'bg-green-300' :''} 
                                                  divide-x`}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:w-auto sm:max-w-none sm:pl-2">
                    <br />
                    {invoice.id}
                    <dl className="font-normal lg:hidden">
                      <dd className="mt-1 truncate text-gray-700">{invoice.employee_name}</dd>
                      <dd className="mt-1 truncate text-gray-500 sm:hidden">{invoice.job_address}</dd>
                      <dd className="mt-1 truncate text-gray-500 sm:hidden">{invoice.date}</dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{invoice.by_user}</td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {invoice.invoice_rate}%
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    <span className='text-gray-400'>{invoice.paid_rate}%</span>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {invoice.job_number}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    <span className='text-gray-400'>{invoice.job_site}</span>
                  </td>
                  <td className="hidden pl-2 py-4 text-sm text-gray-500 sm:table-cell">{<DateFormat date={invoice.date} />}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
}

export default InvHistory;
