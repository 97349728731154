import React from 'react'
import { Outlet } from 'react-router-dom';
import TechNavBar from '../components/TechNavBar';
import { UserProvider } from '../context/UserData';

const TechLayout = () => {
  return (
    <UserProvider>
      <TechNavBar />
      <div className='px-4 mt-6'>{<Outlet />}</div>
    </UserProvider>
  )
}

export default TechLayout;
