import { useMemo } from 'react';

const useDateRequestColors = () => {
  return useMemo(() => ({
    'Vacation Day': 'text-blue-600',
    'in': 'text-green-600',
    'No Pay': 'text-red-600',
    'Excused Pay': 'text-yellow-600', 
    'Partial Hour': 'text-purple-600',
    'Sick Day': 'text-pink-600'
  }), []);
};

export default useDateRequestColors;
