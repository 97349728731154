import React from 'react';
import { format, parseISO } from 'date-fns';

const ShortDateFormat = ({ date }) => {
  if (!date) {
    return null;
  }
  
  const formattedDate = format(parseISO(date), 'MM-dd-yy');
  
  return <span>{formattedDate}</span>;
};

export default ShortDateFormat;
