import { TrashIcon, PencilIcon, CheckIcon, PlusIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from "react";
import DateFormat from "../components/DateFormat";
import { useNavigate } from "react-router-dom";
import { dateToUnixTimestamp } from "../utils/dateToUnixTimestamp";
import { getTodaysDateUtil } from "../utils/DateFormatTodayUtil";
import HeadingCp from "../components/HeadingCp";


const OfficeTimesheet = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [week, setWeek] = useState(dateToUnixTimestamp(getTodaysDateUtil()));
  const [timesheets, setTimesheets] = useState([]);
  const [payperiod, setPayperiod] = useState([]);
  const [editMode, setEditMode] = useState({});
  const navigate = useNavigate();
  const [newTimesheet, setNewTimesheet] = useState({
    event: "",
    time_in: "",
    time_out: "",
    overtime: "",
    date: "",
  });

  useEffect(() => {
    const fetchTimesheet = async () => {
      try {
        const response = await axios.get(`${apiUrl}/time_attendance_crud.php`, {
          params: { office_timesheet: week },
        });
        if (response.data[0] == 'success') {
          setTimesheets(response.data.list || []);
          setPayperiod(response.data.pay_period);
        } else {
          toast.error('no data found', {
            position: 'top-center',
          });
        }
      } catch (error) {
        toast.error("Oops minor issue with the data.", {
        position: "top-center"
        });
        
        if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
        navigate('/login');
        } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
        });
        } 
      }
    }

    fetchTimesheet();
  }, [apiUrl]);

  const handleDeleteTimesheet = async (id) => {
    try {
      const response = await axios.post(`${apiUrl}/time_attendance_crud.php`, {
        status: 'delete_office_timesheet',
        data: { id },
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        toast.success("Timesheet entry deleted successfully!", {
          position: "top-center"
        });
        setTimesheets(timesheets.filter(timesheet => timesheet.id !== id));
      } else {
        toast.error(response.data.message || "Failed to delete timesheet entry.", {
          position: "top-center"
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.", {
        position: "top-center"
      });
    }
  }

  const handleEditToggle = (id) => {
    setEditMode((prevEditMode) => ({
      ...prevEditMode,
      [id]: !prevEditMode[id]
    }));
  };

  const handleInputChange = (id, name, value) => {
    setTimesheets((prevTimesheets) => 
      prevTimesheets.map((timesheet) =>
        timesheet.id === id ? { ...timesheet, [name]: value } : timesheet
      )
    );
  };

  // handlesave
  const handleSave = async (timesheet) => {
    try {
      const response = await axios.post(`${apiUrl}/time_attendance_crud.php`, {
        status: 'office_timesheet_add',
        data: timesheet,
        headers: { 'Content-Type': 'application/json' }
      });
      
      if (response.data[0] == 'success') {
        toast.success("Timesheet entry updated successfully!", {
          position: "top-center"
        });
        setEditMode((prevEditMode) => ({
          ...prevEditMode,
          [timesheet.id]: false
        }));
      } else {
        toast.error(response.data.message || "Failed to update timesheet entry.", {
          position: "top-center"
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.", {
        position: "top-center"
      });
    }
  };

  const formatTime12Hour = (time) => {
    let [hours, minutes] = time.split(':');
    const suffix = hours >= 12 ? "PM" : "AM";
    hours = ((hours % 12) || 12); // Convert to 12-hour format
    return `${hours}:${minutes} ${suffix}`;
  };

  const handleNewTimesheetChange = (e) => {
    const { name, value } = e.target;
    setNewTimesheet((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddNewTimesheet = async () => {
      const week1Date = payperiod.week1[0]; // Start of the pay period
      const week2Date = payperiod.week2[6]; // End of the pay period
      const submittedDate = newTimesheet.date;

      // Check if the submitted date is within the valid range
      if (submittedDate < week1Date || submittedDate > week2Date) {
          toast.error(`Date must be between ${week1Date} and ${week2Date}.`, {
              position: "top-center"
          });
          return; // Stop the submission
      }

      try {
          const response = await axios.post(`${apiUrl}/time_attendance_crud.php`, {
              status: 'office_timesheet_add',
              data: newTimesheet,
              headers: { 'Content-Type': 'application/json' }
          });

          if (response.data[0] == 'success') {
              toast.success("Timesheet entry added successfully!", {
                  position: "top-center"
              });

              setTimesheets([...timesheets, { ...response.data[1], id: response.data[1].id }]);
              setNewTimesheet({
                  event: "",
                  time_in: "",
                  time_out: "",
                  overtime: "",
                  date: "",
              });
          } else {
              toast.error(response.data.message || "Failed to add timesheet entry.", {
                  position: "top-center"
              });
          }
      } catch (error) {
          toast.error("An error occurred. Please try again.", {
              position: "top-center"
          });
      }
  };


  return (
    <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8 mt-12">
      <HeadingCp label={"Office Timesheet"} />
      <h6 className="py-8">
        Next Payroll Date: <DateFormat date={payperiod.nextPayPeriod}/>
      </h6>
      <div className="overflow-hidden">
        <table className="min-w-full text-left mb-20">
          <thead className="bg-gray-50 border-b border-gray-200">
            <tr>
              <th className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">Edit</th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Event</th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Time In</th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Time Out</th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Overtime</th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Date</th>
              <th className="py-3.5 pl-3 text-left text-sm font-semibold text-gray-900">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {timesheets.map((timesheet, index) => (
              <tr key={timesheet.id} className={`divide-y ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}>
               
                <td className="py-4 pr-3">
                  <TrashIcon
                    className="h-5 text-red-700 hover:cursor-pointer hover:text-red-500"
                    onClick={() => handleDeleteTimesheet(timesheet.id)}
                  />
                </td>

                <td className="px-3 py-4 text-sm text-gray-900">
                  {editMode[timesheet.id] ? (
                    <input
                      type="text"
                      name="event"
                      value={timesheet.event}
                      onChange={(e) => handleInputChange(timesheet.id, e.target.name, e.target.value)}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  ) : (
                    timesheet.event
                  )}
                </td>
                <td className="px-3 py-4 text-sm text-gray-900">
                  {editMode[timesheet.id] ? (
                    <input
                      type="time"
                      name="time_in"
                      required={true}
                      value={timesheet.time_in}
                      onChange={(e) => handleInputChange(timesheet.id, e.target.name, e.target.value)}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  ) : (
                    formatTime12Hour(timesheet.time_in)
                  )}
                </td>
                <td className="px-3 py-4 text-sm text-gray-900">
                  {editMode[timesheet.id] ? (
                    <input
                      type="time"
                      name="time_out"
                      required
                      value={timesheet.time_out}
                      onChange={(e) => handleInputChange(timesheet.id, e.target.name, e.target.value)}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  ) : (
                    formatTime12Hour(timesheet.time_out)
                  )}
                </td>
                <td className="px-3 py-4 text-sm text-gray-900">
                  {editMode[timesheet.id] ? (
                    <input
                      type="number"
                      name="overtime"
                      step="0.01"
                      required
                      value={timesheet.overtime}
                      onChange={(e) => handleInputChange(timesheet.id, e.target.name, e.target.value)}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  ) : (
                    timesheet.overtime
                  )}
                </td>
                <td className="px-3 py-4 text-sm text-gray-900">
                  {editMode[timesheet.id] ? (
                    <input
                      type="date"
                      name="date"
                      value={timesheet.date}
                      onChange={(e) => handleInputChange(timesheet.id, e.target.name, e.target.value)}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  ) : (
                    <DateFormat date={timesheet.date} />
                  )}
                </td>
                <td className="py-4 pl-3">
                  {editMode[timesheet.id] ? (
                    <CheckIcon
                      className="h-5 text-green-700 hover:cursor-pointer hover:text-green-500"
                      onClick={() => handleSave(timesheet)}
                    />
                  ) : (
                    <PencilIcon
                      className="h-5 text-blue-700 hover:cursor-pointer hover:text-blue-500"
                      onClick={() => handleEditToggle(timesheet.id)}
                    />
                  )}
                </td>
              </tr>
            ))}
              <tr className="divide-y bg-gray-100">
                <td className="py-4 pr-3">

                </td>
                <td className="px-3 py-4 text-sm">
                  <input
                    type="text"
                    name="event"
                    required
                    value={newTimesheet.event}
                    onChange={handleNewTimesheetChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </td>
                <td className="px-3 py-4 text-sm">
                  <input
                    type="time"
                    name="time_in"
                    required
                    value={newTimesheet.time_in}
                    onChange={handleNewTimesheetChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </td>
                <td className="px-3 py-4 text-sm">
                  <input
                    type="time"
                    name="time_out"
                    required
                    value={newTimesheet.time_out}
                    onChange={handleNewTimesheetChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </td>
                <td className="px-3 py-4 text-sm">
                  <input
                    type="number"
                    name="overtime"
                    step="0.01"
                    required={true}
                    value={newTimesheet.overtime}
                    onChange={handleNewTimesheetChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </td>
                <td className="px-3 py-4 text-sm">
                  <input
                    type="date"
                    name="date"
                    value={newTimesheet.date}
                    onChange={handleNewTimesheetChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </td>
                <td className="py-4 pl-3">
                  <CheckIcon
                    className="h-5 text-green-700 hover:cursor-pointer hover:text-green-500"
                    onClick={handleAddNewTimesheet}
                  />
                </td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OfficeTimesheet;
