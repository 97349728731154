import React, { useState, useEffect, forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { RadioGroup } from '@headlessui/react'
import DateFormat from '../components/DateFormat';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { UserCircleIcon } from '@heroicons/react/24/outline';
import AvatarLocation from '../components/AvatarLocation';


const EmployeeEditPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="bg-blue-400 p-1 rounded-md text-white border-l" onClick={onClick} ref={ref}>
      {value}
    </button>
   ));

  const [startDate, setStartDate] = useState(null);
  const [location, setLocation] = useState([]);
  const [department, setDepartment] = useState();

  // Handler function to update state based on the radio button selection
  const handleLocationChange = (event) => {
    setLocation(event.target.value || ''); 
    // setLocation(event.target.value || ''); 
  };

  const { id } = useParams();
  const [employee, setEmployee] = useState({
                                              id: '',
                                              user_id: '',
                                              first_name: '',
                                              last_name: '',
                                              date_hire: '',
                                              o_f_type: '',
                                              title: '',
                                              email: '',
                                              phone: '',
                                              department: '',
                                              pm: '',
                                              dob: '',
                                              avatar: '',
                                              });
  useEffect( () => {
const fetchEmployee = async () => {
      try {
        const res = await axios.get(`${apiUrl}/employee_crud.php`, {
          params: {
            getDetail: id
          },
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        });

        const data = res.data;
        setEmployee(data['employee_details']);
        setLocation(data['employee_details'].o_f_type);
        setDepartment(data['employee_details'].department);
        setStartDate(data['employee_details'].date_hire);
      } catch (error) {
        console.log('Error fetching data', error);
      } finally {
        // setLoading(false);
      }
    };

    fetchEmployee();
  }, [id]);

  const handleChange = (e) => {
      const { name, type, checked, value } = e.target;
      setEmployee(prev => ({
          ...prev,
          [name]: type === 'checkbox' ? checked : value
      }));
  };


  const handleSubmit = async (event) => {
      event.preventDefault();  // Prevent the default form submit behavior

      const bodyData = {
          status: 'submit',
          data: employee
      };

      try {
          const response = await axios.post('http://irlmainframe.local:8081/public/admin/post_request/wip_crud.php', bodyData, {
              headers: { 'Content-Type': 'application/json' }
          });

          const data = response.data;  // Axios automatically parses the JSON, so no need to call JSON.parse()
          window.location.href ="/wip-list"
          // navigate('/wip-list');
      } catch (error) {
          if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error('Error submitting wip', error.response.data);
          } else if (error.request) {
              // The request was made but no response was received
              console.error('Error submitting wip', error.request);
          } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error', error.message);
          }
      }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <form onSubmit={handleSubmit} className='w-[800px] px-2 bg-white border rounded-lg py-8 px-8'>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Edit:. {employee.first_name} {employee.last_name}</h2>
            <p className="mt-1 text-sm leading-6 text-red-600">
             Date Hire: {employee.date_hire ? <DateFormat date={employee.date_hire} /> : ""}
            </p>
          </div>

          <div className="border-b border-gray-900/10 pb-12 ">
            <h6 className="text-base font-semibold leading-7 text-gray-600">ID:  {employee.id}</h6>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

            <div className="col-span-full">
              <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                Avatar
              </label>
              <div className="mt-2 flex items-center gap-x-3">
                {/* <UserCircleIcon className="h-12 w-12 text-gray-300" aria-hidden="true" /> */}
                <AvatarLocation className="inline-block h-14 w-14 rounded-full" avatar={employee.avatar}/>
                <button>
                  <input
                  type="file"
                  name='Upload Avatar'
                  className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold 
                  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"/>
                </button>
              </div>
            </div>
              
              <div className="sm:col-span-2">
                <label htmlFor="company" className="block text-sm font-medium leading-6 text-gray-900">
                  First Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    value={employee.first_name || ''}
                    onChange={handleChange}
                    autoComplete="given-name"
                    className="text-slate-500 required:border-red-500 block w-full 
                                rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset 
                                ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                                focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required/>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                  Last Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="last_name"
                    id="last_name"
                    value={employee.last_name || ''}
                    onChange={handleChange}
                    autoComplete="given-name"
                    className="text-slate-500 required:border-red-500 block w-full 
                                rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset 
                                ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                                focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required/>
                </div>
              </div>

              <div className="sm:col-span-1">
                <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                  PM Initials
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="pm"
                    id="pm"
                    value={employee.pm || ''}
                    onChange={handleChange}
                    autoComplete="given-name"
                    className="text-slate-500 required:border-red-500 block w-full 
                                rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset 
                                ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                                focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="company" className="block text-sm font-medium leading-6 text-gray-900">
                  Title
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="title"
                    id="title"
                    value={employee.title || ''}
                    onChange={handleChange}
                    autoComplete="given-name"
                    className="text-slate-500 required:border-red-500 block w-full 
                                rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset 
                                ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                                focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required/>
                </div>
              </div>

              {/* select department */}
              <div className='sm:col-span-3'>
                <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900 ">
                  Department
                </label>
                <select
                  id="location"
                  name="location"
                  onChange={handleChange}
                  value={department}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={department}>
                  <option>Select Department</option>
                  <option>Office</option>
                  <option>Sales</option>
                  <option>Finance</option>
                  <option>Engineering</option>
                  <option>Purchasing</option>
                  <option>Operations</option>
                  <option>Install</option>
                  <option>Service</option>
                  <option>Maintenance</option>
                </select>
              </div>

            </div>
          </div>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-2">
              <label htmlFor="company" className="block text-sm font-medium leading-6 text-gray-900">
                Phone
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  value={employee.phone || ''}
                  onChange={handleChange}
                  autoComplete="given-name"
                  className="text-slate-500 required:border-red-500 block w-full 
                              rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset 
                              ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                              focus:ring-indigo-600 sm:text-sm sm:leading-6"
                required/>
              </div>
            </div>
          </div>


   <br/>
        <label className='mt-2'>
          <span className='pr-4 mt-3'>DOB</span>
          {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)}  */}
            <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            customInput={<ExampleCustomInput />}

          className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"'
          />
        </label>


          {/* Radio Buttons for Location */}
          <div className="border-b border-gray-900/10 pb-12 sm:col-span-3 flex flex-col space-y-4">
            <span>Position </span>
            <label className="flex items-center space-x-2 cursor-pointer hover:text-blue-500">
              <input
                type="radio"
                value="Office"
                name='o_f_type'
                checked={location === 'Office'}
                onChange={handleLocationChange}
                className="text-blue-600 focus:ring-blue-500 border-gray-300"
              />
              <span>Office</span>
            </label>

            <label className="flex items-center space-x-2 cursor-pointer hover:text-blue-500">
              <input
                type="radio"
                value="Field"
                name='o_f_type'
                checked={location === 'Field'}
                onChange={handleLocationChange}
                className="text-blue-600 focus:ring-blue-500 border-gray-300"
              />
              <span>Field</span>
            </label>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <NavLink to="/employee_list">
          <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
            Cancel
          </button>
          </NavLink>
          <button
            type="submit"
            className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline
                       focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Save
          </button>
        </div>
      </form>
    </div>
  )


}

export default EmployeeEditPage