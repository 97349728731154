import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormHeader from '../components/FormHeader';
import SubmitButton from '../components/SubmitButton';
import EstimateTabs from '../components/EstimateTabs';
import EstimateBar from '../components/EstimateBar';
import HeadingCp from '../components/HeadingCp';

const EstimatingProcessPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState('Estimate');
  const [loading, setLoading] = useState(false);
  const [submitObject, setSubmitObject] = useState({
    system_type: '',
    building_type: '',
    job_description: '',
    bidding: '',
    att: '',
    contact_name: '',
    contact_address: '',
    contact_city: '',
    contact_state: '',
    contact_zip: '',
    contact_email: '',
    contact_phone: '',
    instruction_notes: '',
    allBidders: false
  });

  const handleTabClick = (href) => {
    setActiveTab(href);  // Set the active tab
    navigate(`/${href}`); // Then navigate to the new route
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSubmitObject((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const bodyData = {
      data: submitObject,
      estimate_id: id,
      status: 'addEsSystem'
    };

    if(submitObject.allBidders) {
      submitObject.att = 'All Bidders'
    }
    try {
      const response = await axios.post(`${apiUrl}/estimate_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        toast.success('Estimate successfully saved!', {
          position: 'top-center'
        });
        navigate(`/installation_details/${response.data.success.id}`)
      } else {
        toast.error(response.data.error || 'Failed updating.', {
          position: 'top-center'
        });
      }
    } catch (error) {
      toast.error('Oops minor issue with the data.', {
        position: 'top-center'
      });

      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center'
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error('Oops!! no access to edit.', {
          position: 'top-center'
        });
      }
    }

  };

  useEffect(() => {
    setLoading(true);
    const fetchEstimates = async () => {
      try {
        const response = await axios.get(`${apiUrl}/estimate_crud.php?id=${id}`, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        });
        const data = Array.isArray(response.data) && response.data[1] ? response.data[1] : {};
        setSubmitObject((prev) => ({
          ...prev,
          system_type: data.system_type || '',
          building_type: data.building_type || '',
          job_description: data.job_description || '',
          bidding: data.bidding_address || '',
          att: data.att || '',
          contact_name: data.contact_name || '',
          contact_address: data.contact_address || '',
          contact_city: data.contact_city || '',
          contact_state: data.contact_state || '',
          contact_zip: data.contact_zip || '',
          contact_email: data.contact_email || '',
          contact_phone: data.contact_phone || '',
          instruction_notes: data.instruction_notes || '',
          allBidders: data.att === 'All bidders' 
        }));
        
      } catch (error) {
        toast.error("Oops minor issue with the data.", { position: "top-center" });

        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", { position: "top-center" });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: "top-center" });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchEstimates();
  }, [apiUrl, navigate, id]);

 const tabs = [
  { name: 'List', href: '/estimate', current: false },
  { name: 'Estimate', href: '#', current: true },
  { name: 'Install', href: `/installation_details/${id}`, current: false },
  { name: 'Equipment', href: '#', current: false },
  { name: 'WIRE', href: '#', current: false },
  { name: 'Elect Contractor', href: '#', current: false },
  { name: 'Notes', href: '#', current: false },
  { name: 'Miscellaneous', href: '#', current: false },
  { name: 'Detail', href: '#', current: false },
  { name: 'Proposal', href: '#', current: false },
]

  return (
  <>
    <div className="mx-auto max-w-[1200px] px-4 sm:px-6 lg:px-8 py-8 bg-white">
      <EstimateBar id={id} name={'Estimate'}/>
      <HeadingCp label={'Estimates'} />
      <form onSubmit={handleSubmit} className="w-[800px] px-8 mx-auto">
        <div className="space-y-10">
          <div className="border-b border-gray-900/10 pb-12">

            <div className="relative flex gap-x-3 col-span-full">
              <h6>Type: </h6>
              <div className="flex items-center">
                <input
                  id="allBidders"
                  name="allBidders"
                  checked={submitObject.allBidders}
                  onChange={handleChange}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label htmlFor="allBidders" className="ml-3 text-sm font-medium text-gray-900">
                  To All Bidders
                </label>
              </div>
            </div>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="relative flex gap-x-3 col-span-full">
                <p className="text-sm font-medium text-blue-900">System Type</p>
                <div className="flex items-center">
                  <input
                    id="voiceSystem"
                    name="system_type"
                    value="Voice System"
                    checked={submitObject.system_type === 'Voice System'}
                    onChange={handleChange}
                    type="radio"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label htmlFor="voiceSystem" className="ml-3 text-sm font-medium text-gray-900">
                    Voice System
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id="nonVoiceSystem"
                    name="system_type"
                    value="Non Voice System"
                    checked={submitObject.system_type === 'Non Voice System'}
                    onChange={handleChange}
                    type="radio"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label htmlFor="nonVoiceSystem" className="ml-3 text-sm font-medium text-gray-900">
                    Non Voice System
                  </label>
                </div>
              </div>

              <div className="col-span-full bg-gray-50 border rounded p-3">
                <p className="text-sm font-medium text-blue-900 mb-4">Occupancy Type</p>
                <div className="flex flex-wrap gap-4">
                  {[
                    'Data Center',
                    'Educational',
                    'Governmental',
                    'Hospitality',
                    'Industrial',
                    'Medical',
                    'Office Building',
                    'Public Assembly',
                    'Residential',
                    'Retail',
                    'Other'
                  ].map((type) => (
                    <div key={type} className="flex items-center">
                      <input
                        id={type}
                        name="building_type"
                        value={type}
                        checked={submitObject.building_type === type}
                        onChange={handleChange}
                        type="radio"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label htmlFor={type} className="ml-3 text-sm font-medium text-gray-900">
                        {type}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-span-full">
                <label htmlFor="job_description" className="block text-sm font-medium text-gray-900">
                  Job Description:
                </label>
                <input
                  type="text"
                  id="job_description"
                  name="job_description"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={submitObject.job_description}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="col-span-full">
                <label htmlFor="bidding" className="block text-sm font-medium text-gray-900">
                  Bidding:
                </label>
                <input
                  type="text"
                  id="bidding"
                  name="bidding"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={submitObject.bidding}
                  onChange={handleChange}
                  required
                />
              </div>

              {!submitObject.allBidders && (
                <>
                  <div className="col-span-2">
                    <label htmlFor="att" className="block text-sm font-medium text-gray-900">
                      Attention to:
                    </label>
                    <input
                      type="text"
                      id="att"
                      name="att"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      value={submitObject.att}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-span-4">
                    <label htmlFor="contact_name" className="block text-sm font-medium text-gray-900">
                      Company Name:
                    </label>
                    <input
                      type="text"
                      id="contact_name"
                      name="contact_name"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      value={submitObject.contact_name}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-span-full">
                    <label htmlFor="contact_address" className="block text-sm font-medium text-gray-900">
                      Address:
                    </label>
                    <input
                      type="text"
                      id="contact_address"
                      name="contact_address"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      value={submitObject.contact_address}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-span-4">
                    <label htmlFor="contact_city" className="block text-sm font-medium text-gray-900">
                      City:
                    </label>
                    <input
                      type="text"
                      id="contact_city"
                      name="contact_city"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      value={submitObject.contact_city}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-span-1">
                    <label htmlFor="contact_state" className="block text-sm font-medium text-gray-900">
                      State:
                    </label>
                    <input
                      type="text"
                      id="contact_state"
                      name="contact_state"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      maxLength="2"
                      value={submitObject.contact_state}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-span-1">
                    <label htmlFor="contact_zip" className="block text-sm font-medium text-gray-900">
                      Zip:
                    </label>
                    <input
                      type="text"
                      id="contact_zip"
                      name="contact_zip"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      maxLength="5"
                      value={submitObject.contact_zip}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-span-4">
                    <label htmlFor="contact_email" className="block text-sm font-medium text-gray-900">
                      Contact Email:
                    </label>
                    <input
                      type="email"
                      id="contact_email"
                      name="contact_email"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      value={submitObject.contact_email}
                      onChange={handleChange}
                      placeholder="email@email.com"
                    />
                  </div>

                  <div className="col-span-2">
                    <label htmlFor="contact_phone" className="block text-sm font-medium text-gray-900">
                      Phone:
                    </label>
                    <input
                      type="tel"
                      id="contact_phone"
                      name="contact_phone"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      maxLength="10"
                      value={submitObject.contact_phone}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </>
              )}

              <div className="col-span-full">
                <label htmlFor="instruction_notes" className="block text-sm font-medium text-gray-900">
                  Instruction Notes:
                </label>
                <textarea
                  id="instruction_notes"
                  name="instruction_notes"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  rows="5"
                  value={submitObject.instruction_notes}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={() => navigate('/estimate')}
          >
            Cancel
          </button>
          <SubmitButton children={'Save'} />
        </div>
      </form>
    </div>
  </>
  );
};

export default EstimatingProcessPage;
