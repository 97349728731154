import React, { useEffect, useState } from 'react';
import GeoAddressLocCp from '../components/GeoAddressLocCp';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const TechCheckinOut = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [showGeoLoc, setShowGeoLoc] = useState(false);
  const [address, setAddress] = useState('');
  const [lastLocation, setLastLocation]= useState(null);

  const handleCheckIn = () => {
    setShowGeoLoc(true);
  };

  const handleAddressFetched = (fetchedAddress) => {
    setAddress(fetchedAddress);
  };

const formatTimestamp = (timestamp) => {
  // Check if the timestamp is in seconds and convert to milliseconds
  if (timestamp < 10000000000) { 
    timestamp *= 1000;
  }

  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0');
  
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  
  // Determine AM/PM suffix
  const ampm = hours >= 12 ? 'PM' : 'AM';
  
  // Convert hours from 24-hour to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'
  hours = String(hours).padStart(2, '0');
  
  return `${month}-${day}-${year} @ ${hours}:${minutes} ${ampm}`;
};


  useEffect(() => {
    const fetchLastLocation = async () => {
        try {
          const response = await axios.get(
            `${apiUrl}/tech_crud.php?getLastLocation=${true}`
          );
          // console.log(response.data);
          
          if (response.data) {
            setLastLocation(response.data);
          }
          else
          {
            setLastLocation('');
          }
        } catch (error) {
        //  toast.error("Oops minor issue with the data.", {
        //  position: "top-center"
        //  });
         
         if (error.response && error.response.status === 401) {
        //  toast.error("Session expired. Please login again.", {
        //  position: "top-center"
        //  });
         navigate('/login');
         } else if (error.response && error.response.status === 403) {
        //  setAccess(true);
        //  toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        //  position: "top-center"
        //  });
         }
      }
    };

    fetchLastLocation();
  }, [apiUrl]);


  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Check</h1>
      <button
        className="btn btn-default bg-gray-200 text-gray-700 px-4 py-2 rounded-md shadow-md mb-4"
        onClick={handleCheckIn}
      >
        Check
      </button>
      {lastLocation?(
        <div className="mt-4">
          <h2 className="text-lg font-semibold">Last check: <br/>
              {lastLocation.job_location}
          </h2>
          <p className="mt-2 text-gray-700">
            {formatTimestamp(lastLocation.time)}
          </p>
        </div>
      ): ('')}
      {showGeoLoc && <GeoAddressLocCp onAddressFetched={handleAddressFetched} />}
    </div>
  );
};

export default TechCheckinOut;
