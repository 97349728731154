import { useMemo } from 'react';

const useDateRequestColors = () => {
  return useMemo(() => ({
    'Vacation Day': 'bg-blue-600',
    'in': 'bg-green-600',
    'No Pay': 'bg-red-600',
    'Excused Pay': 'bg-yellow-600', 
    'Partial Hour': 'bg-purple-600',
    'Sick Day': 'bg-pink-600'
  }), []);
};

export default useDateRequestColors;
