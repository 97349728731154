import { ArrowPathIcon } from '@heroicons/react/24/outline';
import React from 'react'
import { NavLink } from 'react-router-dom'

const SearchBarCP = ({search, handleChange, onClick}) => {

  const handleReset =() =>{
    window.location.reload();
  }
  
  return (
    <>
      <div className="relative rounded-md shadow-sm flex-grow">
            <input
            type="text"
            name="search"
            value={search}
            required={true}
            onChange={handleChange}
            className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
            placeholder="Enter a search term"
          />
          <button
            onClick={onClick}
            disabled={!search}
            className={`absolute right-24 top-0 bottom-0 text-white px-3 py-2 ${!search ? 'bg-gray-500' : 'bg-green-600 hover:bg-green-700'}`}>
            Search
          </button>

          <button
            onClick={handleReset}
            className="absolute right-0 top-0 bottom-0 bg-orange-600 hover:bg-orange-700 text-white px-3 py-2 rounded-r-md">
            <span className='flex items-center gap-1'><ArrowPathIcon className='h-5'/> Reset</span>
          </button>
        </div>
    </>
  )

}

export default SearchBarCP

  // const handleSearchChange = (event) => {
  //   const value = event.target.value;
  //   setSearchTerm(value);
  //   const filtered = proposals.filter(proposal => 
  //     proposal.description.toLowerCase().includes(value.toLowerCase()) ||
  //     proposal.company.toLowerCase().includes(value.toLowerCase()) ||
  //     proposal.job_address.toLowerCase().includes(value.toLowerCase()) ||
  //     proposal.jobnum.toLowerCase().includes(value.toLowerCase())
  //   );
  //   setFilteredProposals(filtered);
  // };

  // const handleSearchClick = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.get(`${apiUrl}/proposal_list_crud.php?search=${searchTerm}`);
  //     setProposals(response.data);
  //     setFilteredProposals(response.data);
  //   } catch (error) {
  //     console.error('Error searching proposals', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };