import React, { useState, useMemo } from 'react';
import { UtilConvertDateTodayDate } from '../utils/UtilConvertDateTodayDate';
import Modal from '../components/Modal';
import FileLocationCp from '../components/FileLocationCp';
import { ArrowUpCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DateFormat from './DateFormat';

const CertificationsListCp = ({ certs, searchTerm, onEditCert, onAddCert, access, onDeleteCert, onFileUpload }) => {
  const [editMode, setEditMode] = useState(null);
  const [editedCert, setEditedCert] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalFileOpen, setIsModalFileOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [selectedCertId, setSelectedCertId] = useState(null); // Add state for selected certificate ID
  const [newCert, setNewCert] = useState({
    certification: '',
    issued: '',
    expires: '',
    file_path: '',
    note: ''
  });

  const handleEditClick = (cert) => {
    setEditMode(cert.id);
    setEditedCert({ ...cert });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedCert((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveClick = () => {
    onEditCert(editedCert);
    setEditMode(null);
  };

  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setNewCert((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddClick = () => {
    onAddCert(newCert);
    setNewCert({
      certification: '',
      issued: '',
      expires: '',
      file_path: '',
      note: ''
    });
    setIsModalOpen(false);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      toast.error("No file selected.", { position: 'top-center' });
      setFile(null);
      return;
    }

    const validFileTypes = ['application/pdf', 'image/png', 'image/jpeg'];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (!validFileTypes.includes(selectedFile.type)) {
      toast.error("Invalid file type. Only PDF, PNG, and JPEG are allowed.", { position: 'top-center' });
      setFile(null);
      return;
    }

    if (selectedFile.size > maxSize) {
      toast.error("File size exceeds 2MB.", { position: 'top-center' });
      setFile(null);
      return;
    }

    setFile(selectedFile);
  };

  const handleUploadFile = (e) => {
    e.preventDefault();
    onFileUpload(file, selectedCertId); // Pass the selected certificate ID
    setFile(null);
    setIsModalFileOpen(false);
  };

  const handleDelete = (id) => {
    onDeleteCert(id);
  };

  const filteredCerts = useMemo(() => {
    return certs.filter(cert =>
      cert.certification.toLowerCase().includes(searchTerm.toLowerCase()) ||
      cert.employee_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [certs, searchTerm]);

  return (
    <>
      <div className={`sm:flex sm:items-left ${filteredCerts.length > 0 ? '' : 'float-left'}`}>
        <div className={`sm:flex-auto ${filteredCerts.length > 0 ? '' : 'hidden'}`}>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm
                      hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
                      focus-visible:outline-indigo-600"
            onClick={() => setIsModalOpen(true)}>
            Add Certification
          </button>
        </div>
      </div>
      <br/>
      {filteredCerts ? (
        <>
          <div className="px-4 sm:px-6 lg:px-8 mt-8 flow-root">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <table className="min-w-full max-w-4xl mx-auto divide-y divide-gray-300 border">
                  <thead>
                    <tr>
                      <th scope="col" className="py-3.5 bg-gray-200 pl-4 pr-3 text-center border border-gray-300 text-sm font-semibold text-gray-900 sm:pl-0">
                        ID
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900 sm:table-cell"
                      >
                        Certificate | Name
                      </th>
                      <th
                        scope="col"
                        className="hidden px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900 lg:table-cell"
                      >
                        Issued
                      </th>
                      <th scope="col" className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900">
                        Expires
                      </th>
                      <th scope="col" className="hidden px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900 lg:table-cell">
                        File
                      </th>
                      <th scope="col" className="hidden px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900 lg:table-cell max-w-xs">
                        Notes
                      </th>
                      {access !== 'Admin' ? ('') : (
                        <th scope="col" className="hidden px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900 lg:table-cell">
                          Mode
                        </th>
                      )}
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 bg-white">
                    {filteredCerts.map((certificate, index) => (
                      <tr key={certificate.id} className={`divide-x divide-gray-200 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                        {editMode === certificate.id ? (
                          <>
                            <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 sm:pl-3">
                              <TrashIcon className='h-5 text-red-600 hover:cursor-pointer' onClick={() => handleDelete(certificate.id)} />
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                              <input
                                type="text"
                                name="certification"
                                value={editedCert.certification || ''}
                                onChange={handleInputChange}
                                className="border-gray-300 rounded-md"
                              />
                            </td>
                            <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                              <input
                                type="date"
                                name="issued"
                                value={editedCert.issued || ''}
                                onChange={handleInputChange}
                                className="border-gray-300 rounded-md"
                              />
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="date"
                                name="expires"
                                value={editedCert.expires || ''}
                                onChange={handleInputChange}
                                className="border-gray-300 rounded-md"
                              />
                            </td>
                            <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">

                            </td>
                            <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell max-w-xs break-words">
                              <input
                                type="text"
                                name="note"
                                value={editedCert.note || ''}
                                onChange={handleInputChange}
                                className="border-gray-300 rounded-md"
                              />
                            </td>
                            <td className="hidden whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium lg:table-cell">
                              <button
                                onClick={handleSaveClick}
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                Save
                              </button>
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 sm:pl-3">
                              {certificate.id}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                              <span className='font-extrabold text-gray-700'>{certificate.employee_name}</span>
                              <br />{certificate.certification}
                            </td>
                            <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                              {<DateFormat date={certificate.issued} />}
                            </td>
                            <td className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500 
                              ${certificate.expires ? (new Date() > new Date(certificate.expires) ? 'bg-red-500 text-white' : '') : ''
                              }`}>
                              {
                                <DateFormat date={certificate.expires} />
                                // UtilConvertDateTodayDate(certificate.expires)
                              }
                            </td>

                            <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                              <FileLocationCp FileName={certificate.file_path}
                                where={'certification_files'}
                                notFoundButton={<ArrowUpCircleIcon className={`h-5 text-green-600 hover:cursor-pointer hover:text-blue-600`}
                                  onClick={() => { setIsModalFileOpen(true); setSelectedCertId(certificate.id) }} />
                                }
                              />
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell max-w-xs break-words">
                              {certificate.note}
                            </td>
                            {access !== 'Admin' ? ('') : (
                              <td className="hidden whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium lg:table-cell">
                                <button
                                  onClick={() => handleEditClick(certificate)}
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  Edit
                                </button>
                              </td>
                            )}
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : ('')}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-lg font-semibold text-gray-900">Add New Certification</h2>
        <form>
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">Certification</label>
            <input
              type="text"
              name="certification"
              value={newCert.certification}
              required={true}
              onChange={handleNewInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">Issued</label>
            <input
              type="date"
              name="issued"
              value={newCert.issued}
              required={true}
              onChange={handleNewInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">Expires</label>
            <input
              type="date"
              name="expires"
              value={newCert.expires}
              onChange={handleNewInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">Notes</label>
            <input
              type="text"
              name="note"
              value={newCert.note}
              onChange={handleNewInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mt-6">
            <button
              disabled={!newCert.certification || !newCert.issued ? true : false}
              onClick={handleAddClick}
              className={`inline-flex justify-center rounded-md border border-transparent
                      ${!newCert.certification || !newCert.issued ? ' bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'}
                      py-2 px-4 text-sm font-medium text-white shadow-sm 
                      focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}>
              Add
            </button>
          </div>
        </form>
      </Modal>

      <Modal isOpen={isModalFileOpen} onClose={() => setIsModalFileOpen(false)}>
        <h2 className="text-lg font-semibold text-gray-900">Upload Certification File</h2>
        <form onSubmit={handleUploadFile}>
          <div className="form-group">
            <input
              type="file"
              name="file"
              id="file"
              onChange={handleFileChange}
              required
              className="mt-1 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
            />
          </div>
          <div className="mt-9 float-right">
            <button
              type='submit'
              className={`inline-flex justify-center rounded-md border border-transparent
                      py-2 px-4 text-sm font-medium text-white shadow-sm bg-green-600
                      focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}>
              Upload
            </button>
          </div>
        </form>
      </Modal>

      <br />
      <br />
    </>
  );
};

export default CertificationsListCp;
