import React from 'react'

const FormHeader = ({type, what}) => {

return (
        <>
        <div className="space-y-12"></div>
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">{type} {what}</h2>
            <p className="mt-1 text-sm leading-6 text-red-600">* Denotes Required Fields</p>
          </div>
        </>
      )
}


export default FormHeader