import { DocumentTextIcon, NoSymbolIcon } from '@heroicons/react/24/outline';
import React from 'react';

const FileLocation = ({ expFile,  where}) => {
   const appRoot = process.env.REACT_APP_ROOT_URL;

  if(expFile){
     var expFileUrl = `${appRoot}${where}/${expFile}`;
     return <a href={`${expFileUrl}`} target='_blank'><DocumentTextIcon className='h-5'/></a>
  } else {
     return <NoSymbolIcon className="h-5 text-red-500" />
  }
  // const expFileUrl = `http://localhost:8081/irlmainframe/back-end/public/user_avatar/${expFile}`;

  // Correctly pass className to the img element
  // return <span className={className} src={expFileUrl} alt="User Avatar" /></span>;
   
};

export default FileLocation;
