import React, { useState, useEffect } from 'react'
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react';
import axios from 'axios';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SelectCustomers = ({ apiUrl, selectedItem, setSelectedItem}) => {
  const [query, setQuery] = useState('');
  const [items, setItems] = useState([]); // Items now hold dynamic data from API

  useEffect(() => {
    const fetchData = async () => {
      if (query.length >= 3) { // Check if the query length is at least 3
        try {
          const response = await axios.post(apiUrl, {
             data: {search: query}, 
             status:'search',  
             headers: { 'Content-Type': 'application/json' } 
            });
          // console.log(response.data); // Add this line to check the API response
          setItems(response.data.search_results); // Set the fetched data
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    const timeoutId = setTimeout(fetchData, 300); // Debounce the API request
    return () => clearTimeout(timeoutId); // Cleanup the timeout

  }, [query, apiUrl]);
  
  return (
     <Combobox
      as="div"
      value={selectedItem}
      onChange={setSelectedItem}
    >
      <Label className="block text-sm font-medium leading-6 text-gray-900">
        Company <span className='text-red-500'>*</span>
      </Label>
      <div className="relative mt-2">
        <ComboboxInput
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={event => setQuery(event.target.value)}
          onBlur={() => setQuery('')}
          displayValue={item => item ? item.customer_name : ''} />
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>
        <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {Array.isArray(items) ? items.map((item) => (
              <ComboboxOption
                  key={item.id}
                  value={item}
                  className={({ active }) => classNames(
                      'relative cursor-default select-none py-2 pl-8 pr-4',
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )}>
                  {({ selected, active }) => (
                      <>
                          <span className={classNames('block truncate', selected && 'font-semibold')}>
                              {item.customer_name}
                          </span>
                          {selected && (
                              <span
                                  className={classNames(
                                      'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                      active ? 'text-white' : 'text-indigo-600'
                                  )}
                              >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                          )}
                      </>
                  )}
              </ComboboxOption>
          )) : 'customer not found with typed criteria'}
        </ComboboxOptions>
      </div>
    </Combobox>
  )
}

export default SelectCustomers