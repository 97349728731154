import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { UtilConvertDateTodayDate } from '../utils/UtilConvertDateTodayDate';
import FileLocationCp from '../components/FileLocationCp';
import { ArrowUpCircleIcon, DocumentTextIcon, TrashIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useNavigate } from 'react-router-dom';
import {UtilGetToDate} from '../utils/UtilGetToDate'
import HeadingCp from '../components/HeadingCp';
import CurrencyFormat from '../components/CurrencyFormat';

const CreditHold = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [access, setAccess] = useState();
  const [creditHoldList, setCreditHoldList] = useState([]);
  const [editMode, setEditMode] = useState([]);
  const [editObj, setEditObj] = useState([]);

  useEffect(() => {
    const fetchHolidays = async () => {
      try {
        const response = await axios.get(`${apiUrl}/wip_crud.php?creditHoldList=1`, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        });
        
        if (response.data) {
          const holdList = Array.isArray(response.data) ? response.data : [];
          setCreditHoldList(holdList);

        } else {
          toast.error("No days found!", {
              position: "top-center"
            });
        }
      } catch (error) {
        setAccess(true)
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
            position: "top-center"
          });
          navigate('/login')
        }
        else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you dont have access to this area speak to the CFO.", {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchHolidays();
  }, [apiUrl, navigate]);

  return (
       <>
       
      <div className="mx-auto max-w-6xl mt-20">
       <HeadingCp label={"Credit Hold Projects"} />
        <div>
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full max-w-4xl mx-auto divide-y divide-gray-300 border">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    P# | Customer | Job Site
                  </th>
                  <th scope="col" className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900">
                    Date
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900 lg:table-cell max-w-xs">
                    Notes
                  </th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200 bg-white">
                {creditHoldList?.map((credit, index) => (
                  <tr key={credit.id} className={`divide-x divide-gray-200 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                      <>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                          <p className='font-bold text-gray-800 font-bold'>
                              {credit.job_number}
                            </p>
                          <p className='text-gray-800 text-sm hover:cursor-pointer'>{credit.customer_name}</p>
                          <p className='text-gray-500 text-sm'>{credit.job_site}</p>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {UtilConvertDateTodayDate(credit.date)}
                        </td>
                        <td className=" hidden px-3 py-4 text-sm text-gray-500 lg:table-cell max-w-xs break-words">
                          {credit.notes}
                        </td>
                      </>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <br/>
      <br/>
    </>
  )
}

export default CreditHold