import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TrashIcon } from '@heroicons/react/24/outline';
import HeadingCp from '../components/HeadingCp';
import EstimateBar from '../components/EstimateBar';

const EstimateMisc = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { id } = useParams();
  const [miscItems, setMiscItems] = useState([]);
  
  const [submitObject, setSubmitObject] = useState({
    description: '',
    quantity: '',
    price: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/estimate_crud.php`, {
          params: { getMisc: id },
        });
        if (response.data[0] === 'success') {
          setMiscItems(response.data[1]);
        }
      } catch (error) {
        toast.error("Oops minor issue with the data.", {
          position: "top-center"
        });

        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
            position: "top-center"
          });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
            position: "top-center"
          });
        }
      }
    };

    if (id) {
      fetchData();
    }
  }, [id, apiUrl]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubmitObject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addMisc = async () => {
    const bodyData = {
      data: submitObject,
      status: 'addMisc',
      estimate_id: id,
    };
    try {
      const response = await axios.post(`${apiUrl}/estimate_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data[0] === 'success') {
        toast.success('Item added!', {
          position: 'top-center',
        });
        setMiscItems((prev) => [...prev, { 
          id: response.data[1].id,
          estimate_id: response.data[1].estimate_id,
          description: response.data[1].description,
          quantity: response.data[1].quantity,
          price: response.data[1].price,
          ...submitObject, 
        }]);
        setSubmitObject({
          description: '',
          quantity: '',
          price: '',
        });
      } else {
        toast.error(response.data.error || 'Failed to add item.', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error('Oops minor issue with the data.', {
        position: 'top-center',
      });

      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center',
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: 'top-center',
        });
      }
    }
  };

  const delMiscItem = async (itemId) => {
    try {
      const response = await axios.post(`${apiUrl}/estimate_crud.php`, {
        status: 'delMisc',
        data: { id: itemId },
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data[0] === 'success') {
        toast.success('Item deleted!', {
          position: 'top-center',
        });
        setMiscItems((prevList) => prevList.filter((item) => item.id !== itemId));
      } else {
        toast.error(response.data.message || 'Failed to delete item.', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error("Oops minor issue with the data.", {
        position: "top-center"
      });

      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      }
    }
  };

  return (
    <>
      <div className="mx-auto max-w-[1200px] sm:px-6 lg:px-6 py-8 bg-white">
        <EstimateBar id={id} name={'Miscellaneous'}/>
        <HeadingCp label={'Miscellaneous Items'} />
        <div className='max-w-[800px] mx-auto'>
          <form onSubmit={(e) => { e.preventDefault(); addMisc(); }} className="space-y-6 pb-7">
            <div className="form-group flex justify-between">
              <div className="w-7/12 pr-2">
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                  Description:
                </label>
                <input
                  type="text"
                  id="description"
                  name="description"
                  className="form-control mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={submitObject.description}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="w-2/12 pr-2">
                <label htmlFor="quantity" className="block text-sm font-medium text-gray-700">
                  Quantity:
                </label>
                <input
                  type="number"
                  id="quantity"
                  name="quantity"
                  className="form-control mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={submitObject.quantity}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="w-2/12">
                <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                  Price:
                </label>
                <input
                  type="number"
                  id="price"
                  name="price"
                  className="form-control mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={submitObject.price}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-primary mt-4 inline-flex justify-center rounded-md border border-transparent 
                      bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none 
                      focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              Add
            </button>
          </form>

          <div className='pb-6'>
            <ul className="max-w-[800px] mx-auto">

            <p className='pb-3'>
              {miscItems.length > 0 ? 'List:' : 'No items added yet.'}
            </p>

              {miscItems.map((row) => (
                <li key={row.id} className="py-1">
                  <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-t
                                  border-gray-200 bg-white">
                  
                    <div className="flex-1 truncate px-4 py-2 text-sm">
                      <p className="inline-block text-gray-800 font-bold">
                        {row.description}:
                      </p>
                      <span className="inline-block ml-4 font-light text-gray-900">
                        Quantity: {row.quantity} | Price: {row.price}
                      </span>
                    </div>

                    <div className="flex-shrink-0 pr-2">
                      <button
                        type="button"
                        onClick={() => delMiscItem(row.id)}
                        className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span className="sr-only">Delete</span>
                        <TrashIcon className="h-5 w-5 text-red-600" aria-hidden="true" />
                      </button>
                    </div>

                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default EstimateMisc;
