import { Fragment, useState, useEffect } from 'react';
import {
  ArrowUpCircleIcon,
  FaceFrownIcon,
  FaceSmileIcon,
  FireIcon,
  HandThumbUpIcon,
  HeartIcon,
  PaperClipIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { Listbox, Transition } from '@headlessui/react';
import AvatarLocation from './AvatarLocation';
import { useUserData } from '../context/UserData';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format, parseISO } from 'date-fns';
import { ArrowRightCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import DateFormat from './DateFormat';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const PostComment = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const user = useUserData();
  const navigate = useNavigate();
  const [submitObject, setSubmitObject] = useState({ comment: '' });
  const [comments, setComments] = useState([]);
  const [replyObject, setReplyObject] = useState({ commentId: null, reply: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.get(`${apiUrl}/post_crud.php?getList=1`, {
          headers: { 'Content-Type': 'application/json' }
        });
        // console.log(response.data);
        
        if (response.data) {
          setComments(Array.isArray(response.data) ? response.data : []);
        } else {
          toast.error("no post added", {
            position: "top-center"
          });
          setComments([]);
        }
      } catch (error) {
        toast.error("Oops, minor issue with the data.", {
          position: "top-center"
        });

        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
            position: "top-center"
          });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
            position: "top-center"
          });
        }
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchComments();
  }, [apiUrl, navigate, user]);

  const handleChange = (e) => {
    setSubmitObject({ ...submitObject, comment: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const bodyData = {
      data: submitObject,
      status: 'addComment'
    };
    try {
      const response = await axios.post(`${apiUrl}/post_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.data.success) {
        // toast.success("Post added!", {
        //   position: "top-center"
        // });
        setComments([response.data.comment, ...comments]); // assuming response contains the new comment
        setSubmitObject({ comment: '' });
      } else {
        toast.error(response.data.error || "Failed adding post.", {
          position: "top-center"
        });
      }
    } catch (error) {
      toast.error("Oops minor issue with the data.", {
        position: "top-center"
      });

      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! no access to edit.", {
          position: "top-center"
        });
      }
    }
  };

  const handleReplyChange = (e, commentId) => {
    setReplyObject({ commentId, reply: e.target.value });
  };

  const handleReplySubmit = async (e, commentId) => {
    e.preventDefault();
    const bodyData = {
      data: { reply: replyObject.reply },
      status: 'addReply',
      post_id: commentId
    };
    try {
      const response = await axios.post(`${apiUrl}/post_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.data.success) {
        // toast.success("Reply added!", {
        //   position: "top-center"
        // });
        setComments(comments.map(comment => comment.id === commentId
          ? { ...comment, reply: [response.data.reply, ...comment.reply || []] }
          : comment
        ));
        setReplyObject({ commentId: null, reply: '' });
      } else {
        toast.error(response.data.error || "Failed updating.", {
          position: "top-center"
        });
      }
    } catch (error) {
      toast.error("Oops minor issue with the data.", {
        position: "top-center"
      });

      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! no access to edit.", {
          position: "top-center"
        });
      }
    }
  };

  const handleDelete = async (commentId) => {
    try {
      const response = await axios.post(`${apiUrl}/post_crud.php`, {
        data: { post_id: commentId },
        status: 'delete_comment'
      }, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.data.success) {
        toast.success("Comment deleted!", {
          position: "top-center"
        });
        setComments(comments.filter(comment => comment.id !== commentId));
      } else {
        toast.error(response.data.error || "Failed to delete comment.", {
          position: "top-center"
        });
      }
    } catch (error) {
      toast.error("Oops, minor issue with the data.", {
        position: "top-center"
      });

      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! no access to delete.", {
          position: "top-center"
        });
      }
    }
  };

  return (
    <>
      <div className="flex items-start space-x-4 bg-white p-4 border-2 rounded-lg">
        <div className="flex-shrink-0"></div>

        <div className="min-w-0 flex-1">
          <form onSubmit={handleSubmit} className="relative">
            <div className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
              <label htmlFor="comment" className="sr-only">
                Add your comment
              </label>
              <textarea
                rows={2}
                name="comment"
                id="comment"
                value={submitObject.comment}
                onChange={handleChange}
                className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="Add your comment..."
              />

              <div className="py-2" aria-hidden="true">
                <div className="py-px">
                  <div className="h-9" />
                </div>
              </div>
            </div>

            <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
              <div className="flex items-center space-x-5">
                <div className="flex items-center">
                  {/* <button
                    type="button"
                    className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                  >
                    <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="sr-only">Attach a file</span>
                  </button> */}
                </div>
              </div>

              <div className="flex-shrink-0">
                <button
                  type="submit"
                  className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Post
                </button>
              </div>
            </div>
          </form>
        </div>

        {/* <div className="relative mb-6">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="bg-white px-2 text-gray-500"></span>
          </div>
        </div> */}
      </div>
      <br />
      <div className="..">
        {comments.map(comment => (
          <div key={comment.id} className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow mb-4">
            {user?.users?.id === comment.employee_id ? (
              <TrashIcon className='h-5 float-end mr-5 mt-5 text-red-600 hover:cursor-pointer hover:text-blue-500' onClick={() => handleDelete(comment.id)} />
            ) : ('')}
            <div className="px-4 py-5 sm:px-6">
              <div className="flex items-center">
                <AvatarLocation className="h-10 w-10 rounded-full" avatar={comment.avatar} />
                <div className="pl-2">
                  <p className="text-sm font-semibold text-gray-900">
                    <a href={`/profile/${comment.employee_id}`} className="hover:underline">{comment.author}</a></p>
                  <p className="text-sm text-gray-500">{format(parseISO(comment.date), 'MMMM d, yyyy')}</p>
                </div>
              </div>
            </div>
            <div className="sm:p-6 sm:text-gray-500 p-3">
              <p>{comment.content}</p>
              <div className='mt-4'>
                {comment.reply ? (
                  <>
                    {comment.reply.map(reply_post => (
                      <div key={reply_post.id} className='p-4 bg-[#fafafa] rounded-3xl border mb-3'>
                        <div className="flex items-center">
                          <AvatarLocation className="h-7 w-7 rounded-full" avatar={reply_post.avatar} />
                          <div className="pl-2">
                            <p className="text-xs font-semibold text-indigo-700"> {reply_post.author} </p>
                            <p className="text-xs text-gray-500">{reply_post.content}</p>
                          </div>
                        </div>
                        <p className="text-xs text-gray-500 pl-8 mt-2">
                          {format(parseISO(reply_post.date), 'MMMM d, yyyy')}
                        </p>
                      </div>
                    ))}
                  </>
                ) : ('')}
              </div>
            </div>
            <div className="p-4 flex items-center">
              <AvatarLocation className="h-7 w-7 rounded-full mr-3" avatar={user?.avatar} />
              <input
                type="text"
                name="reply"
                id="reply"
                placeholder='Reply...'
                value={replyObject.commentId === comment.id ? replyObject.reply : ''} 
                onChange={(e) => handleReplyChange(e, comment.id)}
                className="text-slate-500 block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 
                           ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                           focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
              <ArrowUpCircleIcon className='h-8 text-green-600 hover:cursor-pointer hover:text-blue-600' onClick={(e) => handleReplySubmit(e, comment.id)} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default PostComment;
