import { Fragment, React, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellAlertIcon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from '../assets/images/logo.png'
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AvatarLocation from './AvatarLocation';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUserData } from '../context/UserData';
import LoadingSpinner from './LoadingSpinner';


const currentUrl = window.location.href;
const url = currentUrl.split('/')[3];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const NavBar = () => {
  const navigate = useNavigate()
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [alertCount, setAlertCount] = useState(0);
  const userData = useUserData();

  //  console.log(userData);
  const handleSignOut = async () => {

    const bodyData = {
      status: 'signout',
      data: { }
    };

    try {
      const response = await axios.post(`${apiUrl}/users_login.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      
      if (response.data.success) {
          navigate('/login');
      } 
    } catch (error) {
      console.error("Authentication error:", error);
      toast("An error occurred while trying to authenticate.", {
        position: "top-center",
        autoClose: 5000
      });
    }
  };

  useEffect(() => {
      fetchCount(userData?.users?.pm);
  }, [userData]);

  const fetchCount = async () => {
    setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/alert_crud.php?getPmCount=${userData?.users?.pm}`);
        setCount(response.data.records.record_count || 0);
        setAlertCount(response.data.alert_count);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoading(false);
      }
    };

  return (
    <Disclosure as="nav" className="bg-gray-800 sticky top-0 z-50">
      {({ open }) => (
        <div>
          <div className="mx-auto max-w-[1280px] px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    className="h-8 w-auto"
                    src={logo}
                    alt="IRL-Mainframe"
                  />
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    <NavLink to="/" className={({ isActive }) => isActive ? 'rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white ' :
                      'rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white cursor-pointer' }>
                      Dashboard
                    </NavLink>

                    <NavLink to="/service_dashboard" className={({ isActive }) => isActive ? 'rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white ' :
                      'rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white' }>
                      Service
                    </NavLink>

                    <NavLink to="/customers" className={({ isActive }) => isActive ? 'rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white ' :
                      'rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white' }>
                      Customers
                    </NavLink>

                     <NavLink to="/wip_list/aj" className={({ isActive }) => isActive ? 'rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white ' :
                      'rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white' }>
                      WIP
                    </NavLink>

                  </div>
                </div>
              </div>

              <div className="hidden sm:ml-6 sm:block">
                <div className="flex items-center">
                  <NavLink to={`/invoice_update`}>
                  <button
                    type="button"
                    className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>

                    <span className="float-start items-center rounded-full bg-red-50 px-1 py-1 text-xs font-medium
                                  text-red-700 ring-1 ring-inset ring-red-600/10">
                      {loading ? (
                        <LoadingSpinner />
                      ) : (
                        <>
                         {
                          userData?.users?.pm ? count : ''
                         }
                        </>
                      )}

                    </span>
                  </button>
                  </NavLink>
                
                  <NavLink to={`/alerts_view`}>
                  <button
                    type="button"
                    className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    {/* <BellAlertIcon className="h-6 w-6 animate-ping text-red-700" aria-hidden="true" /> */}
                    {alertCount >= 1 ? (
                      <>
                        <BellAlertIcon className="animate-ping absolute inline-flex h-6 w-6 text-red-600 opacity-75"/>
                        <BellAlertIcon className="relative inline-flex h-6 w-6 text-red-600"/>
                      </>
                    ) : (
                        <BellIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                    )}

                  </button>
                  </NavLink>

                  <Menu as="div" className="relative ml-3">
                    <div>
                     <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <AvatarLocation className="h-8 w-8 rounded-full" avatar={userData?.avatar} />
                    </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink
                              to={`/profile/${userData?.users?.id}`}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Your Profile
                            </NavLink>
                          )}
                        </Menu.Item>

                        <Menu.Item>
                          {({ active }) => (
                            <NavLink
                              to="/settings/holidays"
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Settings
                            </NavLink>
                          )}
                        </Menu.Item>
                        
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink
                              to="#"
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              System
                            </NavLink>
                          )}
                        </Menu.Item>

                        <Menu.Item>
                          {({ active }) => (
                            <NavLink
                              onClick={handleSignOut}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Sign out
                            </NavLink>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>

              <div className="-mr-2 flex sm:hidden">
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

            </div>
          </div>

          {/****************** this section below is for mobile mode *************************************************/}
          {/****************** this section below is for mobile mode *************************************************/}
          {/****************** this section below is for mobile mode *************************************************/}
          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              <Disclosure.Button
                as="a"
                to="proposal_list"
                className="block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white">
                <NavLink to="/"> Posts </NavLink>
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                to="#"
                className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
              >
                <NavLink to="#">Service</NavLink>
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                to="#"
                className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
              >
                <NavLink to="customers">Customers</NavLink>
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                to="#"
                className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
              >
                <NavLink to="wip_list/aj">WIP</NavLink>
              </Disclosure.Button>
            </div>
            <div className="border-t border-gray-700 pb-3 pt-4">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <AvatarLocation avatar={userData?.users.avatar} className="h-10 w-10 rounded-full"/> 
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-white">
                    {userData?.users.first_name} {userData?.users.last_name}
                  </div>
                  <div className="text-sm font-medium text-gray-400">
                    {userData?.users.title} | {userData?.users.department}
                  </div>
                </div>

                <button
                  type="button"
                  className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

              </div>
              <div className="mt-3 space-y-1 px-2">
                
                <Disclosure.Button
                  as={NavLink}
                  to={`/profile/${userData?.users?.id}`}
                  className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                >
                  Profile
                </Disclosure.Button>

                <Disclosure.Button
                  as={NavLink}
                  to={`/vacation_req`}
                  className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                >
                  Vacation Request
                </Disclosure.Button>

                <Disclosure.Button
                  as={NavLink}
                   onClick={handleSignOut}
                  className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                >
                  Sign out
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}

export default NavBar
