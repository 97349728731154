import React from 'react';

const Badge = ({ feed, color, num }) => {
    const allowedBackgroundColors = {
        'gray-50': 'bg-gray-100',//
        'yellow-50': 'bg-yellow-100',//
        'indigo-50': 'bg-indigo-100',//
        'green-50': 'bg-green-100',//
        'purple-50': 'bg-purple-100',
        'pink-50': 'bg-pink-100',
        // Add more as needed
    };

    const allowedTextColors = {
        'gray-500': 'text-gray-500',
        'yellow-600': 'text-yellow-600',
        'indigo-500': 'text-indigo-500',
        'green-600': 'text-green-600',
        'purple-800': 'text-purple-800',
        'pink-800': 'text-pink-800',
        // Add more as needed
    };

    const finalBgColor = allowedBackgroundColors[`${color}-50`] || 'bg-gray-50';
    const finalTextColor = allowedTextColors[`${color}-${num}`] || 'text-gray-500';

    return (
        <span className={`inline-flex items-center rounded-md ${finalBgColor} px-2 py-1 text-xs font-medium ${finalTextColor} ring-1 ring-inset ring-gray-500/10`}>
            {feed}
        </span>
    );
};

export default Badge;
