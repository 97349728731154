import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import holidaysImage from '../assets/images/holidays.gif';
import independenceDayImage from '../assets/images/independence_day.gif';
import thanksgiving1 from '../assets/images/thanksgiving1.gif';
import thanksgiving2 from '../assets/images/thanksgiving2.gif';
import laborday from '../assets/images/laborday.gif';
import memorialDay from '../assets/images/memorial_day.gif';
import '../custom.css';
import TopTabs from '../components/TopTabs';

const OfficeCalendar = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [days, setDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [loading, setLoading] = useState(true);
  const [access, setAccess] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const key = useParams();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [holidays, setHolidays] = useState([]);
  

const getHolidayBackgroundImage = (day) => {
  // Check if the current day.date matches any holiday from the holidays array
  const holiday = holidays?.find(holiday => holiday.date === day.date);
  
  // Styles to apply for all holidays with background images
  const backgroundStyles = {
    backgroundSize: '100%',      // Ensures the image covers the entire box
    backgroundPosition: 'center', // Centers the background image
    backgroundRepeat: 'no-repeat' // Prevents image repetition
  };

  // Apply specific images based on the holiday
  if (day.date === `${new Date().getFullYear()}-07-04`) {
    return { ...backgroundStyles, backgroundImage: `url(${independenceDayImage})` };
  } else if (day.date === `${new Date().getFullYear()}-12-25`) {
    return { ...backgroundStyles, backgroundImage: `url(${holidaysImage})` };
  } else if (holiday) {
    switch (holiday.name) {
      case 'Thanksgiving Day':
        return { ...backgroundStyles, backgroundImage: `url(${thanksgiving1})` };
      case 'Day after Thanksgiving Day':
        return { ...backgroundStyles, backgroundImage: `url(${thanksgiving2})` };
      case 'Labor Day':
        return { ...backgroundStyles, backgroundImage: `url(${laborday})` };
      case 'Memorial Day':
        return { ...backgroundStyles, backgroundImage: `url(${memorialDay})` };
      default:
        return { backgroundColor: 'bg-green-400' }; // Default for other holidays
    }
  }
  
  return {}; // Return empty object if no holiday matches
};


  const fetchDates = async (year, month) => {
    try {
      const response = await axios.get(`${apiUrl}/time_attendance_crud.php?getWeeks=vacations&&year=${year}&&month=${month}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      });
      if (response.data.error === 'Authentication required') {
        navigate('/Login');
      } else {
        const datesList = Array.isArray(response.data[0]) ? response.data[0] : [];
        if (datesList.length === 0) {
          toast.error("No days found!", {
            position: "top-center"
          });
        }
        setDays(datesList);
        setHolidays(response.data.holidays)
      }
    } catch (error) {
      setAccess(true);
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area speak to the CFO.", {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth() + 1;
    fetchDates(year, month);
  }, [currentMonth]);

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };

  const handlePreviousMonth = () => {
    const newMonth = new Date(currentMonth.setMonth(currentMonth.getMonth() - 1));
    setCurrentMonth(newMonth);
  };

  const handleNextMonth = () => {
    const newMonth = new Date(currentMonth.setMonth(currentMonth.getMonth() + 1));
    setCurrentMonth(newMonth);
  };

  const getColorForEmployee = (employeeId) => {
    const colors = [
      'bg-blue-500', 'bg-green-500', 'bg-red-500', 'bg-yellow-500', 
      'bg-purple-500', 'bg-pink-500', 'bg-indigo-500', 'bg-teal-500'
    ];
    return colors[employeeId % colors.length];
  };

  const generateCalendarGrid = () => {
    const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
    const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
    const startDate = startOfMonth.getDay() === 0 ? startOfMonth : new Date(startOfMonth.setDate(startOfMonth.getDate() - startOfMonth.getDay()));
    const endDate = endOfMonth.getDay() === 6 ? endOfMonth : new Date(endOfMonth.setDate(endOfMonth.getDate() + (6 - endOfMonth.getDay())));

    const dates = [];
    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
      const dateString = d.toISOString().split('T')[0];
      const dayObj = days.find(day => day.date === dateString) || { date: dateString, events: [] };
      dates.push(dayObj);
    }
    return dates;
  };

  const calendarDays = generateCalendarGrid();
  const vacation_cal = { name: 'Vacations Cal', href:'#', current:true };
  const overtime = { name: 'YTD', href: '/vacation_ytd', current: false };

  const tabs = [vacation_cal, overtime];

  return (
    <>
      <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8">
        <br/>
        <br/>
          <TopTabs tabs={tabs}  />
        <br/>
        <br/>

        <header className="flex items-center justify-between border-b border-gray-200 px-6 py-4 lg:flex-none">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            <time dateTime={currentMonth.toISOString().split('T')[0]}>
            Days Off for: {currentMonth.toLocaleString('default', { month: 'long' })} {currentMonth.getFullYear()}
            </time>
          </h1>
          <div className="flex items-center">
            <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
              <button
                type="button"
                onClick={handlePreviousMonth}
                className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
              >
                <span className="sr-only">Previous month</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>

              <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
              <button
                type="button"
                onClick={handleNextMonth}
                className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
              >
                <span className="sr-only">Next month</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </header>

        <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
          <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
              <div className="bg-white py-2">
                S<span className="sr-only sm:not-sr-only">un</span>
              </div>
              <div className="bg-white py-2">
                M<span className="sr-only sm:not-sr-only">on</span>
              </div>
              <div className="bg-white py-2">
                T<span className="sr-only sm:not-sr-only">ue</span>
              </div>
              <div className="bg-white py-2">
                W<span className="sr-only sm:not-sr-only">ed</span>
              </div>
              <div className="bg-white py-2">
                T<span className="sr-only sm:not-sr-only">hu</span>
              </div>
              <div className="bg-white py-2">
                F<span className="sr-only sm:not-sr-only">ri</span>
              </div>
              <div className="bg-white py-2">
                S<span className="sr-only sm:not-sr-only">at</span>
              </div>
          </div>

          <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
            <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-5 lg:gap-px">
              {calendarDays.map((day) => (
                <div
                  key={day.date}
                  className={classNames(
                    new Date(day.date).getMonth() === currentMonth.getMonth()
                      ? (day.date === '2024-07-04' || day.date === '2024-12-25')
                        ? 'bg-cover bg-center' // Tailwind classes for background cover and center
                        : 'bg-white'
                      : 'bg-gray-50 text-gray-500',
                    'relative px-4 py-6' // Adjusted padding for larger boxes
                  )}
                  style={getHolidayBackgroundImage(day)}>
                  <time
                    dateTime={day.date}
                    className={
                      day.date === new Date().toISOString().split('T')[0]
                        ? 'flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white' // Adjusted size for larger boxes
                        : 'flex h-8 w-8 items-center justify-center rounded-full' // Adjusted size for larger boxes
                    }
                  >
                    {day.date.split('-').pop().replace(/^0/, '')} {day.events.length > 3 && <span className="text-gray-500 pl-1">+more</span>}
                  </time>

                  {day.events.length > 0 && (
                    <ol className="mt-2 max-h-20 overflow-y-auto">
                      {day.events.map((event, index) => (
                        <li key={event.id} className="flex items-center">
                          <span className={classNames('h-2 w-2 rounded-full mr-1', getColorForEmployee(event.employee_id))}></span>
                          <span className="truncate font-medium text-gray-900 group-hover:text-indigo-600">
                            {event.employee_name}
                          </span>
                        </li>
                      ))}
                    </ol>
                  )}
                </div>
              ))}
            </div>

            {/******************************** this is for the calendar mobile style ******************************/}
            <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
              {calendarDays.map((day) => (
                <button
                  key={day.date}
                  type="button"
                  className={classNames(
                    new Date(day.date).getMonth() === currentMonth.getMonth() ? 'bg-white' : 'bg-gray-50',
                    (day.isSelected || day.date === new Date().toISOString().split('T')[0]) && 'font-semibold',
                    day.isSelected && 'text-white',
                    !day.isSelected && day.date === new Date().toISOString().split('T')[0] && 'text-indigo-600',
                    !day.isSelected && new Date(day.date).getMonth() === currentMonth.getMonth() && day.date !== new Date().toISOString().split('T')[0] && 'text-gray-900',
                    !day.isSelected && new Date(day.date).getMonth() !== currentMonth.getMonth() && day.date !== new Date().toISOString().split('T')[0] && 'text-gray-500',
                    'flex h-20 flex-col px-4 py-6 hover:bg-gray-100 focus:z-10' // Adjusted height and padding for larger boxes
                  )}
                  onClick={() => setSelectedDay(day)}
                >
                  <time dateTime={day.date}
                    className={classNames(
                      day.isSelected && 'flex h-8 w-8 items-center justify-center rounded-full',
                      day.isSelected && day.date === new Date().toISOString().split('T')[0] && 'bg-indigo-600',
                      day.isSelected && day.date !== new Date().toISOString().split('T')[0] && 'bg-gray-900',
                      'ml-auto'
                    )}
                  >
                    {day.date.split('-').pop().replace(/^0/, '')}
                  </time>
                  <span className="sr-only">{day.events.length} events</span>
                  {day.events.length > 0 && (
                    <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                      {day.events.map((event) => (
                        <span key={event.id} className={classNames('rounded-full mx-0.5 mb-1 h-1.5 w-1.5', getColorForEmployee(event.employee_id))}></span>
                      ))}
                    </span>
                  )}
                </button>
              ))}
            </div>
            
          </div>

        </div>
        
        {/* this section below is for the mobile section */}
        {selectedDay?.events.length > 0 && (
          <div className="px-4 py-10 sm:px-6 lg:hidden">
            <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5 max-h-96 overflow-y-auto">
              {selectedDay.events.map((event) => (
                <li key={event.id} className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50">
                  <div className="flex-auto">
                    <p className="font-semibold text-gray-900">{event.employee_name}</p>
                  </div>
                  <span clspanssName="ml-6 flex-none self-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 opacity-0 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:opacity-100 group-hover:opacity-100"
                  >
                    <span className="sr-only">, {event.name}</span>
                  </span>
                </li>
              ))}
            </ol>
          </div>
        )}
      </div>
      <br/>
      <br/>
      <br/>
    </>
  );
};

export default OfficeCalendar;
