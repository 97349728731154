import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchBarCP from '../components/SearchBarCP';
import LegendCP from '../components/LegendCP';
import SubMenu from '../components/SubMenu';
import ModalWindow from '../components/ModalWindow';
import ModalVendorSection from '../components/ModalVendorSection';
import Truncate from '../components/Truncate';
import DateFormat from '../components/DateFormat';
import { useUserData } from '../context/UserData';
import LoadingSpinner from '../components/LoadingSpinner';
import FileLocation from '../components/FileLocation';

const AccountingVendorPage = () => {
  const navigate = useNavigate();
  const [accounting, setAccountingVendor] = useState([]);
  const [filteredAccountingVendor, setFilteredAccountingVendor] = useState([]);
  const [showApproved, setShowApproved] = useState(true);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [openModalId, setOpenModalId] = useState(null);
  const [notesIdOpen, setNotesIdOpen] = useState(null);
  const [file, setFile] = useState(null);
  const userData = useUserData();
  const [bodyDataNotes, setBodyDataNotes] = useState({
    status: 'submit',
    user_id: null,
    data: {}
  });
  const [selectAccountingFile, setSelectAccountingFile] = useState(null);
  const [isModalFileOpen, setIsModalFileOpen] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/accounting_crud.php`;

  useEffect(() => {
    fetchAccountingVendor();
  }, []);

  const toggleShowApproved = () => {
    setShowApproved(!showApproved);
  };

  const fetchAccountingVendor = async () => {
    try {
      const response = await axios.get(api, { params: { getList: 'list' } });
      if (response.data && Array.isArray(response.data)) {
        setAccountingVendor(response.data);
        setFilteredAccountingVendor(response.data);
      } else {
        toast.error("Error getting array", { position: 'top-center' });
      }
    } catch (error) {
      //console.log('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value === '') {
      setFilteredAccountingVendor(accounting);
    } else {
      const filtered = accounting.filter(accounting =>
        (accounting.description && accounting.description.toLowerCase().includes(value.toLowerCase())) ||
        (accounting.job_site && accounting.job_site.toLowerCase().includes(value.toLowerCase())) ||
        (accounting.job_number && accounting.job_number.toLowerCase().includes(value.toLowerCase()))
      );
      setFilteredAccountingVendor(filtered);
    }
  };

  const handleSearchClick = async () => {
    setLoading(true);
    const bodyData = {
      status: 'search',
      data: { search: searchTerm }
    };

    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data && Array.isArray(response.data)) {
        setAccountingVendor(response.data);
        setFilteredAccountingVendor(response.data);
      } else {
        setFilteredAccountingVendor([]);
        toast.error('Not found: ' + searchTerm, { position: 'top-center' });
      }
    } catch (error) {
      //console.error('Error searching active jobs', error);
    } finally {
      setLoading(false);
    }
  };

  const handleNotes = (var_test) => {
    setOpenModalId(var_test.idItem);
  };

 const handleChange = (event, id, user) => {
    bodyDataNotes.user_id = user.user_id;
    const { name, value } = event.target;
    setFilteredAccountingVendor(prev => prev.map(accounting => {
      if (parseInt(accounting.id) === id) {
        const updatedAccounting = { ...accounting, [name]: value };
        bodyDataNotes.data = updatedAccounting;
        updatedAccounting.employee_name = `${user.first_name} ${user.last_name}`;
        return updatedAccounting;
      } else {
        return accounting;
      }
    }));
  };

  const handleComplete = async (e) => {
    try {
      await axios.post(api, { status: 'submit_complete', id: e.idItem }, {
        headers: { 'Content-Type': 'application/json' }
      });

      toast.info('Saved!', { position: 'top-center' });
    } catch (error) {
      //console.error('Error submitting', error);
    }
  };

  const voidJobSubmit = async (id, currentVoidState) => {
    setLoading(true);
    const newVoidStatus = currentVoidState === 'void' ? '' : 'void';
    const bodyData = {
      status: 'void',
      data: { voidID: id, void: newVoidStatus }
    };

    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.data === 'Success') {
        setFilteredAccountingVendor(accounting => accounting.map(acc => {
          if (acc.id === id) {
            return { ...acc, status: newVoidStatus };
          }
          return acc;
        }));

        toast.success('Saved!', { position: 'top-center' });
      } else {
        toast.error('Error updating', { position: 'top-center' });
      }
    } catch (error) {
      //console.error('Error searching active projects', error);
    } finally {
      setLoading(false);
    }
  };

  const handleNotesView = (id) => {
    setNotesIdOpen(id);
  };

  const getLinksForJob = (accounting) => [
    { name: 'Edit Vendor', click: handleNotes },
    { name: 'Completed', click: handleComplete },
    { name: 'Upload File', click: () => { setIsModalFileOpen(true); setSelectAccountingFile(accounting.id) }},
    { name: accounting.status === 'void' ? 'Remove void' : 'Void vendor', click: (e, tvoid, index) => voidJobSubmit(accounting.id, accounting.status, index) },
  ];

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      toast.error("No file selected.", { position: 'top-center' });
      setFile(null);
      return;
    }

    const validFileTypes = ['application/pdf'];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (!validFileTypes.includes(selectedFile.type)) {
      toast.error("Invalid file type. Only PDF is allowed.", { position: 'top-center' });
      setFile(null);
      return;
    }

    if (selectedFile.size > maxSize) {
      toast.error("File size exceeds 2MB.", { position: 'top-center' });
      setFile(null);
      return;
    }

    setFile(selectedFile);
  };

  const handleUploadFile = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.error("Please select a file before uploading.", { position: 'top-center' });
      return;
    }
    
    const formData = new FormData();
    formData.append('status', 'submit_accounting_file');
    formData.append('id', selectAccountingFile); // Include the accounting vendor ID
    formData.append('file', file);

    //console.log("FormData before upload:", formData.get('status'), formData.get('id'), formData.get('file')); // Debugging

    try {
      const response = await axios.post(`${apiUrl}/accounting_file.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      //console.log("Upload response:", response.data); // Debugging

      if (response.data.success) {
        toast.info('File uploaded successfully!', { position: 'top-center' });

        // Update the state with the new file information
        setFilteredAccountingVendor(prev => 
            prev.map(accounting =>
                accounting.id === selectAccountingFile ? 
                { ...accounting, account_file: response.data.accounting.file } : accounting
            )
        );

        setFile(null);
        setIsModalFileOpen(false);
      } else {
        toast.error(response.data.error || "Error uploading file", { position: 'top-center' });
      }
    } catch (error) {
      toast.error("An error occurred during file upload.", { position: 'top-center' });
      //console.error('Error uploading file', error);
    }
  };

  return (
    <>
      <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-2xl font-semibold text-gray-500 mb-8">Vendor Projects</h1>
        <LegendCP color="fill-green-300" label="Complete" />
        <br />
        <br />

        <div className="flex space-x-3 items-center mb-10">
          <button
            onClick={toggleShowApproved}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
            {showApproved ? 'Hide Complete' : 'Show Complete'}
          </button>
          <SearchBarCP search={searchTerm} handleChange={handleSearchChange} onClick={handleSearchClick} />
        </div>

        {loading ? (
          <LoadingSpinner children={'Loading...'}/>
        ) : (
          <table className="min-w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg">
            <thead>
              <tr className='divide-x divide-gray-400'>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-2">
                  ID/Edit
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                  Proposal#
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-2">
                  Employee Name
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Vendor Name
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Vendor Paid % 
                </th>
                <th scope="col" className="hidden pl-2 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Adv Invoice#
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Notes
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Date
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  File
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredAccountingVendor.filter(acc => showApproved || acc.status !== 'complete').map((acc, index) => (
                <tr key={acc.id} className={`${acc.status === 'void' ? 'line-through text-red-600' : ''} 
                                            ${acc.status === 'complete' ? 'bg-green-300' : ''} 
                                            ${index % 2 === 0 && acc.status !== 'complete' ? 'bg-white' : 'bg-gray-100'} 
                                            divide-x`}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:w-auto sm:max-w-none sm:pl-2">
                    <SubMenu label={acc.id} links={getLinksForJob(acc)} idItem={acc.id} />
                    {openModalId === acc.id && (
                      <ModalWindow job={
                        <ModalVendorSection
                          onChange={(e) => handleChange(e, acc.id, userData?.users)}
                          api={api}
                          inside={'notes'}
                          bodyData={bodyDataNotes}
                          val={acc}
                          closeNotes={() => setOpenModalId(null)}
                        />
                      } closeNotes={() => setOpenModalId(null)} />
                    )}
                    <br />
                    <dl className="font-normal lg:hidden">
                      <dd className="mt-1 truncate text-gray-700">{acc.employee_name}</dd>
                      <dd className="mt-1 truncate text-gray-500 sm:hidden">{acc.job_address}</dd>
                      <dd className="mt-1 truncate text-gray-500 sm:hidden">{acc.date}</dd>
                    </dl>
                  </td>

                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    <NavLink to={`/wip_detail/${acc.projectID}`}>
                    <span className='text-blue-600'>
                      {acc.job_number}
                    </span>
                    </NavLink>
                    <br/>
                    <span>{acc.job_site}</span>
                  </td>

                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {acc.employee_name}
                  </td>

                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {acc.vendor_name}
                  </td>

                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {acc.vendor_rate_paid ? acc.vendor_rate_paid + '%' : ''}
                  </td>

                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {acc.adv_inv_num}
                  </td>

                  <td className="hidden pl-2 py-4 text-sm text-gray-500 sm:table-cell max-w-[350px]">
                    {acc.notes && 
                      <Truncate text={acc.notes} handleNotesView={() => handleNotesView(acc.id)} />
                    }
                    {notesIdOpen === acc.id && (
                      <ModalWindow job={acc.notes} closeNotes={() => setNotesIdOpen(null)} activeButton={true} />
                    )}
                  </td>

                  <td className="hidden pl-2 py-4 text-sm text-gray-500 sm:table-cell">
                    {<DateFormat date={acc.date} />}
                  </td>

                  <td className="hidden pl-2 py-4 text-sm text-gray-500 sm:table-cell">
                    <FileLocation expFile={acc.account_file} where={'accounting_file'}/>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {isModalFileOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="bg-white p-8 rounded-md shadow-md min-w-[500px]">
              <h2 className="text-xl font-semibold mb-4">Upload File</h2>
              <form onSubmit={handleUploadFile}>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Select a PDF file (Max size 2MB)</label>
                  <input
                    type="file"
                    accept="application/pdf"
                    onChange={handleFileChange}
                    className="mt-2"
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => setIsModalFileOpen(false)}
                    className="px-4 py-2 mr-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                  >
                    Upload
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AccountingVendorPage;
