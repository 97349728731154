import React from 'react'
import SideNav from '../components/SideNav';
import Users from '../components/Users';
import Comment from '../components/Comment';
import { MinusIcon } from '@heroicons/react/24/outline';
import DayOff from '../components/DayOff';
import Alerts from '../components/Alerts';
import { NavLink } from 'react-router-dom';
// import { Nav } from 'react-bootstrap';

const HomePage = () => {
  return (

    <>
    <div className="max-w-[1220px] mx-auto grid grid-cols-1 md:grid-cols-[200px_minmax(540px,_1fr)_260px] gap-x-4">
      {/* <!-- Side Nav: 200px, hidden on small screens --> */}
      <div className="hidden md:block">
        <div className='pt-6 pr-3'>
          <SideNav />
        </div>
      </div>

      {/* <!-- Main Content: Expand on small screens --> */}
      <div className=" p-4">

        <div className="relative mb-6">
          <h1>Users</h1>
          <br/>
          {/* search bar */}
          <div className="mt-5 sm:mt-0 sm flex space-x-3 items-center mb-10">

            <NavLink to="/user_new">
              <button type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-400 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                New User
              </button>
            </NavLink>

          <div className="flex-grow relative rounded-md shadow-sm">
            <input
              type="text"
              name="search"
              id="search"
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
              placeholder="Enter a search term"
            />
            <button
              type="button"
              className="absolute right-0 top-0 bottom-0 px-3 py-2 bg-green-600 text-white text-sm rounded-r-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500">
              Search
            </button>
          </div>

          </div>
          {/* search bar */}
          <div className="w-full border-t border-gray-300" />
        </div>

        <div className='..'>
          <Users/>
        </div>

      </div>
      
      {/* <!-- Widgets: 200px, hidden on small screens --> */}
      <div className="hidden md:block ">
        <div className='pt-4'>
          <div className='border-2'>
            <DayOff />
          </div> 
        <br/>
          <Alerts />
        </div>
      </div>

    </div>
    </>
  )
} 

export default HomePage