import React from 'react';

// Assuming value is passed as an object property for better flexibility
const CurrencyFormat = ({ value, style = 'decimal', currency = 'USD' }) => {
  return '$'+new Intl.NumberFormat('en-US', {
    style,  // 'decimal' for numbers, 'currency' for monetary values
    currency,  // Used only if style is 'currency'
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
}

export default CurrencyFormat;
