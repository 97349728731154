import React, { useState, useEffect } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import HeadingCp from '../components/HeadingCp';
import Modal from '../components/Modal';

const statuses = {
  Complete: 'text-green-700 bg-green-50 ring-green-600/20',
  'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
  Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const TechWipPage = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [isModalFileOpen, setIsModalFileOpen] = useState(false);
  const [selectDeliveryId, setSelectDeliveryId] = useState(null);
  const [file, setFile] = useState(null);
  
  const handleSearchClick = async () => {
    //console.log('Search button clicked, term:', searchTerm);
    setLoading(true);

    const bodyData = {
      status: 'search_job',
      data: { search: searchTerm }
    };

    try {
      const response = await axios.post(`${apiUrl}/tech_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.data && Array.isArray(response.data)) {
        setJobs(response.data);
      } else {
        // handleSearchChange()
        setJobs([]);
        toast.error('not found '+searchTerm, {
          position: 'top-center'
        });
      }
    } catch (error) {
             toast.error("Oops minor issue with the data.", {
          position: "top-center"
        });

        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
            position: "top-center"
          });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
            position: "top-center"
          });
        }
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
      const value = event.target.value;
      setSearchTerm(value);

      if (value === '') {
          setJobs(jobs);
      } else {
          const filtered = jobs.filter(jobs =>
              (jobs.customer_name && jobs.customer_name.toLowerCase().includes(value.toLowerCase())) ||
              (jobs.job_number && jobs.job_number.toLowerCase().includes(value.toLowerCase())) ||
              (jobs.job_site && jobs.job_site.toLowerCase().includes(value.toLowerCase()))
          );
          setJobs(filtered);
      }
  };

  const handleUploadFile = (e) => {
    e.preventDefault();
    handleFormSubmit(file, selectDeliveryId); 
    setFile(null);
    setIsModalFileOpen(false);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      toast.error("No file selected.", { position: 'top-center' });
      setFile(null);
      return;
    }

    const validFileTypes = ['application/pdf'];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (!validFileTypes.includes(selectedFile.type)) {
      toast.error("Invalid file type. Only PDF is allowed.", { position: 'top-center' });
      setFile(null);
      return;
    }

    if (selectedFile.size > maxSize) {
      toast.error("File size exceeds 2MB.", { position: 'top-center' });
      setFile(null);
      return;
    }

    setFile(selectedFile);
    //console.log(selectedFile, 'selected file');
  };

  const handleFormSubmit = async (file, selectDeliveryId) => {
      if (!file) {
          toast.error("Please upload a valid file.", { position: 'top-center' });
          return;
        }
        const formData = new FormData();
        formData.append('status', 'submit_delivery_receipt');
        formData.append('id', selectDeliveryId); // Include certificate ID
        formData.append('file', file);
        try {
          const response = await axios.post(`${apiUrl}/tech_delivery_receipt.php`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          });
          
          // setFilterItems(object => object.map(item => {
          //   if (item.id === parseInt(response.data.id)) {
          //     return { ...item, file: response.data.delivery_receipt.file, 
          //                       status: response.data.delivery_receipt.status};
          //   }
          //   return item;
          // }));

          if (response.data.success) {
            toast.info('Saved!', { position: 'top-center' });
          } else {
            toast.error(response.data.error, { position: 'top-center' });
          }
        } catch (error) {
          toast.error("Oops minor issue with the data.", { position: "top-center" });
          if (error.response && error.response.status === 401) {
            toast.error("Session expired. Please login again.", { position: "top-center" });
            navigate('/login');
          } else if (error.response && error.response.status === 403) {
            toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: "top-center" });
          }
        }
  };  

 return (
    <>
    <HeadingCp label={"Proposal# Lookup"}/>
    <ul role="list" className="divide-y divide-gray-100">

    <div className="relative rounded-md shadow-sm flex-grow">
        <input
        type="text"
        name="search"
        value={searchTerm}
        onChange={handleSearchChange}
        className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
        placeholder="Search for Proposal # | Job Site"
      />
      <button
        onClick={handleSearchClick}
        className="absolute right-0 top-0 bottom-0 bg-green-600 hover:bg-green-700 text-white px-3 py-2 rounded-r-md">
        Search
      </button>
    </div>

      {jobs.map((job) => (
        <li key={job.id} className="flex items-center justify-between gap-x-6 py-5">
          <div className="min-w-0">
            <div className="flex items-start gap-x-3">
              <p className="text-sm font-semibold leading-6 text-gray-900">{job.job_site}</p>
              <p
                className={classNames(
                  statuses[job.status],
                  'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                )}
              >
                {job.f_p_type}
              </p>
            </div>
            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              <p className="whitespace-nowrap">
                {job.customer_name}
              </p>
              <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                <circle cx={1} cy={1} r={1} />
              </svg>
              <p className="whitespace-nowrap">
                {job.job_number}
                {/* <DateFormat date={job.date}/> */}
              </p>
              <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                <circle cx={1} cy={1} r={1} />
              </svg>
              <p className="truncate">Project Manager: {job.pm}</p>
            </div>
          </div>

          <div className="flex flex-none items-center gap-x-4">
            <Menu as="div" className="relative flex-none">
              <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
              </MenuButton>
              <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
               >
                <MenuItem>
                  {({ focus }) => (
                    <span
                      onClick={ () => { setIsModalFileOpen(true); setSelectDeliveryId(job.job_number)}}
                      className={classNames(
                        focus ? 'bg-gray-50' : '',
                        'block px-3 py-1 text-sm leading-6 text-gray-900',
                      )}>
                      Upload Delivery Receipt<span className="sr-only">, {job.name}</span>
                    </span>
                    // { name: object.file ? 'Update Delivery Receipt' : 'Upload Delivery Receipt', 
                    // click: () => { setIsModalFileOpen(true); setSelectDeliveryId(object.id) }, key: `upload-delivery-${object.id}` },               
                  )}
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>
        </li>
      ))}
    </ul>
        <Modal isOpen={isModalFileOpen} onClose={() => setIsModalFileOpen(false)}>
        <h2 className="text-lg font-semibold text-gray-900">Upload Delivery Receipt</h2>
        <form onSubmit={handleUploadFile}>
          <div className="form-group">
            <input
              type="file"
              name="file"
              id="file"
              onChange={handleFileChange}
              required
              className="mt-1 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
            />
          </div>
          <div className="mt-9 float-right">
            <button
              type='submit'
              className={`inline-flex justify-center rounded-md border border-transparent
                        py-2 px-4 text-sm font-medium text-white shadow-sm bg-green-600
                        focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mb-2`}>
              Upload
            </button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default TechWipPage