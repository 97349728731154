import React, { useState, useEffect } from 'react';
import { ArrowUpCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from './LoadingSpinner';

const FleetCp = ({ fleets, searchTerm, onEditCert, onAddCert, access, employees }) => {
  const [editMode, setEditMode] = useState(null);
  const [editedCert, setEditedCert] = useState(null); // Fleet being edited
  const [selectedEmployee, setSelectedEmployee] = useState(null); // Employee selected during editing
  const [isModalOpen, setIsModalOpen] = useState(false);  // Add this line at the top with other states

  const [newFleet, setNewFleet] = useState({
    id: '',
    vnumber: '',
    employee_id: '',
    make: '',
    model: '',
    year: '',
    plate: '',
    vin: '',
    ezpass: '',
    tag_color: '',
    tag_number: '',
    inspection_expires: '',
    type: '',
    account: '',
    purchase_date: '',
    irl_insuranc: ''
  });

  const handleEditClick = (fleet) => {
    setEditMode(fleet.id); // Enable edit mode
    setEditedCert({ ...fleet }); // Set current fleet details
    setSelectedEmployee(fleet.employee_id); // Set current assigned employee
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedCert((prev) => ({ ...prev, [name]: value }));
  };

  const handleEmployeeChange = (e) => {
    setSelectedEmployee(e.target.value); // Update selected employee
  };

const handleSaveClick = () => {
  // Find the selected employee in the employees array
  
  const selectedEmp = employees.find(emp => emp.id === selectedEmployee);
  console.log(employees);

  // Safely construct the employee_name if the employee is found
  const employeeName = selectedEmp
    ? `${selectedEmp.first_name} ${selectedEmp.last_name}`
    : 'Spare';  // Fallback if no employee is selected or found

  // Save the selected employee along with the rest of the fleet details
  const updatedCert = {
    ...editedCert,
    employee_id: selectedEmployee,
    employee_name: employeeName
  };

  onEditCert(updatedCert); // Pass updated fleet to parent
  setEditMode(null); // Exit edit mode
};


  const handleCancelClick = () => {
    setEditMode(null); // Exit edit mode without saving
    setEditedCert(null);
    setSelectedEmployee(null); // Reset selected employee
  };

  const filteredFleets = fleets.filter((fleet) =>
    fleet.vin.includes(searchTerm) ||
    fleet.tag_number.includes(searchTerm) ||
    fleet.make.toLowerCase().includes(searchTerm.toLowerCase()) ||
    fleet.employee_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    fleet.model.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const months = [
    "January", "February", "March", "April", "May", "June", 
    "July", "August", "September", "October", "November", "December"
  ];

  return (
    <>
      <div className={`sm:flex sm:items-left ${filteredFleets.length > 0 ? '' : 'float-left'}`}>
        <div className={`sm:flex-auto ${filteredFleets.length > 0 ? '' : 'hidden'}`}></div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm
                      hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
                      focus-visible:outline-indigo-600"
            onClick={() => setIsModalOpen(true)}>
            Add Fleet
          </button>
        </div>
      </div>
      <br/>

      {filteredFleets.length > 0 ? (
        <div className="px-4 sm:px-6 lg:px-8 mt-8 flow-root">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full max-w-4xl mx-auto divide-y divide-gray-300 border">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 bg-gray-200 pl-4 pr-3 text-center border border-gray-300 text-sm font-semibold text-gray-900 sm:pl-0">
                      ID
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      Yr | Make | Model | #
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900 lg:table-cell"
                    >
                      Plate
                    </th>
                    <th scope="col" className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900">
                      Tag
                    </th>
                    <th scope="col" className="hidden px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900 lg:table-cell">
                      Exp. | Type | Accnt
                    </th>
                    <th scope="col" className="hidden px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900 lg:table-cell max-w-xs">
                      Assigned
                    </th>
                    {access === 'Admin' && (
                      <th scope="col" className="hidden px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900 lg:table-cell">
                        Mode
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {filteredFleets.map((fleet, index) => (
                    <tr key={fleet.id} className={`divide-x divide-gray-200 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                      {editMode === fleet.id ? (
                        <>

                          <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 sm:pl-3">
                            {fleet.id}
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                            <input
                              type="text"
                              name="make"
                              value={editedCert.make || ''}
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            <input
                              type="text"
                              name="model"
                              value={editedCert.model || ''}
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            <input
                              type="number"
                              name="year"
                              value={editedCert.year || ''}
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            <input
                              type="text"
                              name="vin"
                              value={editedCert.vin || ''}
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </td>

                          <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                            <input
                              type="text"
                              name="plate"
                              value={editedCert.plate || ''}
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </td>

                          <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                            <input
                              type="text"
                              name="ezpass"
                              value={editedCert.ezpass || ''}
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />                             
                            <input
                              type="text"
                              name="tag_color"
                              value={editedCert.tag_color || ''}
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            <input
                              type="text"
                              name="tag_number"
                              value={editedCert.tag_number || ''}
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />                           
                          </td>

                          <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                            <select
                              name="inspection_expires"
                              value={editedCert.inspection_expires || ''}
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 py-1.5 
                                text-gray-900 shadow-sm ring-1 ring-inset 
                                ring-gray-300 focus:ring-2 focus:ring-inset 
                                focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                              {months.map((month, index) => (
                                <option key={index} value={month}>{month}</option>
                              ))}
                            </select>
                            <input
                              type="text"
                              name="type"
                              value={editedCert.type || ''}
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 py-1.5 
                                text-gray-900 shadow-sm ring-1 ring-inset 
                                ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                                focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            <input
                              type="text"
                              name="account"
                              value={editedCert.account || ''}
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 py-1.5 
                                text-gray-900 shadow-sm ring-1 ring-inset 
                                ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                                focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </td>

                          <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                            <select
                              value={selectedEmployee || ''}
                              onChange={handleEmployeeChange}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                              <option value="">Select Employee</option>
                              {employees && employees.length > 0 ? (
                                employees.map((employee) => (
                                  <option key={employee.id} value={employee.id}>
                                    {employee.first_name} {employee.last_name}
                                  </option>
                                ))
                              ) : (
                                <option value="" disabled>
                                  No employees found
                                </option>
                              )}
                            </select>
                          </td>

                          <td className="hidden whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium lg:table-cell">
                            <button
                              type="button"
                              className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                              onClick={handleSaveClick}
                            >
                              Save
                            </button>
                            <button
                              type="button"
                              className="block rounded-md bg-red-600 px-3 py-2 mt-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                              onClick={handleCancelClick}
                            >
                              Cancel
                            </button>
                          </td>

                        </>
                      ) : (
                        <>
                          <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 sm:pl-3">
                            {fleet.id}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                            <span className="font-extrabold text-gray-700 flex float-end">
                              {fleet.vnumber ? `#${fleet.vnumber}` : ''}
                            </span>
                            <p>{fleet.make} {fleet.year}</p>
                            <p>{fleet.model}</p>
                            <p>{fleet.vin}</p>
                          </td>
                          <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                            {fleet.plate}
                          </td>
                          <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                            <p>{fleet.ezpass}</p>
                            <p>{fleet.tag_color}</p>
                            <p>{fleet.tag_number}</p>
                          </td>
                          <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                            <p>{fleet.inspection_expires}</p>
                            <p>{fleet.type}</p>
                            <p>{fleet.account}</p>
                          </td>
                          <td className={`hidden whitespace-nowrap px-3 py-4 text-sm lg:table-cell`}>
                            <p>{fleet.employee_name || 'Spare'}</p>
                          </td>
                          {access === 'Admin' && (
                            <td className="hidden whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium lg:table-cell">
                              <span
                                className="px-3 py-2 text-center text-sm font-semibold shadow-sm text-blue-600 hover:cursor-pointer"
                                onClick={() => handleEditClick(fleet)}
                              >
                                Edit
                              </span>
                            </td>
                          )}
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <LoadingSpinner>Loading...</LoadingSpinner>
      )}
    </>
  );
};

export default FleetCp;
