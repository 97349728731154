const LegendCP = ({ color, label }) => (
  <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
    <svg className={`h-2 w-2 ${color}`} viewBox="0 0 6 6" aria-hidden="true">
      <circle cx={3} cy={3} r={3} />
    </svg>
    {label}
  </span>
);

export default LegendCP;


{/* <LegendCP color="fill-red-500" label="Badge" />
<LegendCP color="fill-yellow-500" label="Badge" />
<LegendCP color="fill-green-500" label="Badge" />
<LegendCP color="fill-blue-500" label="Badge" />
<LegendCP color="fill-indigo-500" label="Badge" />
<LegendCP color="fill-purple-500" label="Badge" />
<LegendCP color="fill-pink-500" label="Badge" /> */}