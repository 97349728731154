import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { UtilConvertDateTodayDate } from '../utils/UtilConvertDateTodayDate';
import FileLocationCp from '../components/FileLocationCp';
import { ArrowUpCircleIcon, DocumentTextIcon, TrashIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useNavigate } from 'react-router-dom';
import {UtilGetToDate} from '../utils/UtilGetToDate'
import HeadingCp from '../components/HeadingCp';
import CurrencyFormat from '../components/CurrencyFormat';

const OnCallList = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [access, setAccess] = useState();
  const [onCallList, setOnCallList] = useState([]);
  const [editMode, setEditMode] = useState([]);
  const [editObj, setEditObj] = useState([]);

  useEffect(() => {
    const fetchServiceOnCall = async () => {
      try {
        const response = await axios.get(`${apiUrl}/service_crud.php?service_on_call=1`, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        });
        
        if (response.data) {
          const onCallListFeed = Array.isArray(response.data) ? response.data : [];
          setOnCallList(onCallListFeed);

        } else {
          toast.error("No days found!", {
              position: "top-center"
            });
        }
      } catch (error) {
        // setAccess(true)
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
            position: "top-center"
          });
          navigate('/login')
        }
        else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you dont have access to this area speak to the CFO.", {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchServiceOnCall();
  }, [apiUrl, navigate]);

  const handleDelete = async(id) => {
    const bodyData = {
      id: id,
      status: 'delete_credit_hold'
    };
      try {
        const response = await axios.post(`${apiUrl}/wip_crud.php`, bodyData, {
          headers: { 'Content-Type': 'application/json' }
        });
        if (response.data[0] === 'success') {
          toast.success("Hold Removed!", {
            position: "top-center"
          });
          setOnCallList((prevCerts) => prevCerts.filter(onCall => onCall.id !== id));
        } else {
          toast.error(response.data.error || "Failed deleting.", {
            position: "top-center"
          });
        }
      } catch (error) {
          toast.error("Oops minor issue with the data.", {
          position: "top-center"
          });
          
          if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
          position: "top-center"
          });
          navigate('/login');
          } else if (error.response && error.response.status === 403) {
            setAccess(true);
          toast.error("Oops!! no access to edit.", {
          position: "top-center"
          });
          }
      }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditObj((prev) => ({ ...prev, [name]: value }));
  }

  const handleSaveClick = async(e, job_number) => {
    // e.preventDefault()
    // Implement the form submission logic here
    const bodyData = {
      status: 'on_call_list',
      data: editObj,
      jobNumber: editObj.job_number
    }; 
    try {
      const response = await axios.post(`${apiUrl}/wip_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
        // console.log(response.data[0]);
        
      if (response.data[0]==='success') {
        toast.success('Credit Hold Added!', {
          position: 'top-center'
        });
          setEditMode(null);
          setOnCallList(prevList =>
            prevList.map(credit =>
              credit.id === response.data.credit_hold?.id ? { ...credit, ...response.data.credit_hold } : credit
            )
          );
      } else {
        toast.error('Error adding credit hold.', {
          position: 'top-center'
        });
      }
    } catch (error) {
      console.error('Error adding credit hold', error);
      toast.error('Error adding credit hold.', {
        position: 'top-center'
      });
    }
  }

  const handleEditClick = (obj) => {
    setEditMode(obj.id);
    setEditObj({ ...obj });
  };



  return (
       <>
       
      <div className="mx-auto max-w-6xl mt-20">
       <HeadingCp label={"On Service Call"} />
        <br/>
{/* ('id', 'employee_id', 'start_date', 'end_date', 'phone'); */}
        <br/>
        <div>
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full max-w-4xl mx-auto divide-y divide-gray-300 border">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 bg-gray-200 pl-4 pr-3 text-center border border-gray-300 text-sm font-semibold text-gray-900 sm:pl-0">
                    ID
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Employee Name
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Start Date
                  </th>
                  <th scope="col" className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900">
                    End Date
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900 lg:table-cell max-w-xs">
                    Phone
                  </th>
                  {/* {access !== 'Admin' ? ('') : ( */}
                    <th scope="col" className="hidden px-3 py-3.5 bg-gray-200 text-center border border-gray-300 text-sm font-semibold text-gray-900 lg:table-cell">
                      Mode
                    </th>
                  {/* )} */}
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200 bg-white">
                {onCallList?.map((onCall, index) => (
                  <tr key={onCall.id} className={`divide-x divide-gray-200 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
               
                        <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 sm:pl-3">
                          {onCall.id}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                         {onCall.employee_name}
                        </td>
                        <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                          {UtilConvertDateTodayDate(onCall.start_date)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {UtilConvertDateTodayDate(onCall.end_date)}
                        </td>
                        <td className=" hidden px-3 py-4 text-sm text-gray-500 lg:table-cell max-w-xs break-words">
                          {onCall.phone}
                        </td>
                        {/* {access !== 'Admin' ? ('') : ( */}
                          <td className="hidden whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium lg:table-cell">
                            <button
                              onClick={() => handleEditClick(onCall)}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              Edit
                            </button>
                          </td>
                        {/* )} */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <br/>
      <br/>
    </>
  )
}

export default OnCallList