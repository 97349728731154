import React from 'react';

const AvatarLocation = ({ avatar, className }) => {
   const appRoot = process.env.REACT_APP_ROOT_URL;

  const avatarUrl = avatar ? `${appRoot}/user_avatar/${avatar}` : `${appRoot}/user_avatar/no_pic.png`;

  return <img className={className} src={avatarUrl} alt="User Avatar" />;
};

export default AvatarLocation;
