export const UtilDateToFullDay = (dateString) => {
  const date = new Date(dateString + 'T00:00:00Z'); // Ensuring the date is treated as UTC
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const dayOfWeek = days[date.getUTCDay()];
  const month = months[date.getUTCMonth()];
  const dayOfMonth = date.getUTCDate();
  const year = date.getUTCFullYear();
  
  return `${dayOfWeek} ${month} ${dayOfMonth} ${year}`;
}
