import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react'
import { BarsArrowDownIcon } from '@heroicons/react/20/solid'


const SubMenu = ({label, links, idItem, href}) => {
  return (
    <Popover className="relative">
      <PopoverButton className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
        <BarsArrowDownIcon className="h-5 w-5" aria-hidden="true" />
        <span>{label}</span>
      </PopoverButton>

      <Transition
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4">
          <div className="w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
            {links.map((item) => (
              <a key={item.name} href={href} onClick={() => item.click({idItem})} className="block p-2 hover:text-indigo-600 hover:cursor-pointer">
                {item.name}
              </a>
            ))}
          </div>
        </PopoverPanel>
      </Transition>
    </Popover>
  )
}

export default SubMenu