// src/utils/dateUtils.js

export const dateToUnixTimestamp = (dateString) => {
    // Create a Date object from the date string
    var date = new Date(dateString);

    // Get the Unix timestamp in milliseconds
    var timestampMillis = date.getTime();

    // Convert to seconds
    var timestampSeconds = Math.floor(timestampMillis / 1000);

    return timestampSeconds;
  }
// utils/dateToUnixTimestamp.js
export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};