import React, { useState, useEffect, useRef } from 'react';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import axios from 'axios';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import ReactDOM from 'react-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SelectParts = ({ apiUrl, selectedItem, setSelectedItem }) => {
  const [query, setQuery] = useState('');
  const [items, setItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const comboboxRef = useRef(null);  // Ref to track the Combobox
  const inputRef = useRef(null);
  const [inputPosition, setInputPosition] = useState({ top: 0, left: 0, width: 0 });

  useEffect(() => {
    const fetchData = async () => {
      if (query.length >= 3) {
        try {
          const response = await axios.post(apiUrl, {
            data: { search: query },
            status: 'search',
            headers: { 'Content-Type': 'application/json' }
          });
          console.log(response.data, 'from the search part');
          setItems(response.data.search_results);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    const timeoutId = setTimeout(fetchData, 300);
    return () => clearTimeout(timeoutId);
  }, [query, apiUrl]);

  // Update input position for portal dropdown placement
  useEffect(() => {
    if (inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      setInputPosition({ top: rect.bottom + window.scrollY, left: rect.left + window.scrollX, width: rect.width });
    }
  }, [inputRef, query]);

  // Handle closing Combobox when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (comboboxRef.current && !comboboxRef.current.contains(event.target)) {
        setIsOpen(false);  // Close the combobox when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside); // Cleanup event listener
    };
  }, [comboboxRef]);

  return (
    <Combobox
      as="div"
      value={selectedItem}
      onChange={setSelectedItem}
      ref={comboboxRef}  // Attach ref to the Combobox container
    >
      <div className="relative">
        <ComboboxInput
          ref={inputRef}
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => {
            setQuery(event.target.value);
            setIsOpen(true);  // Open combobox on input change
          }}
          placeholder="Part Name"
          displayValue={(item) => (item ? item.part : '')}
        />
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>

        {isOpen && ReactDOM.createPortal(
          <div
            className="absolute z-50 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            style={{ top: inputPosition.top, left: inputPosition.left, width: inputPosition.width }}
          >
            {Array.isArray(items) ? (
              items.map((item) => (
                <ComboboxOption
                  key={item.id}
                  value={item}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-8 pr-4',
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                    )
                  }
                >
                  {({ selected, active }) => (
                    <>
                      <span className={classNames('block truncate', selected && 'font-semibold')}>
                        {item.part}
                      </span>
                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 left-0 flex items-center pl-1.5',
                            active ? 'text-white' : 'text-indigo-600'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </ComboboxOption>
              ))
            ) : (
              <div className="py-2 px-4 text-gray-500">Parts not found</div>
            )}
          </div>,
          document.body // Portaling the dropdown outside of the accordion
        )}
      </div>
    </Combobox>
  );
};

export default SelectParts;
