import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EstimateBar from '../components/EstimateBar';
import SubmitButton from '../components/SubmitButton';
import HeadingCp from '../components/HeadingCp';

const EstimateWire = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { id } = useParams();
  const [submitObject, setSubmitObject] = useState({
    w14_1pr: '',
    w14_2pr: '',
    w16_1pr: '',
    w16_2pr: '',
    ci_cable: '',
    // add other fields if necessary
  });

  useEffect(() => {
    // Fetch existing data for editing
    const fetchWireDetail = async () => {
      try {
        const response = await axios.get(`${apiUrl}/estimate_crud.php`, {
          params: { get_wire: id },
        });
        if (response.data[0] === 'success') {
          setSubmitObject(response.data[1]);
        }
      } catch (error) {
        toast.error('Error fetching data.', {
          position: 'top-center',
        });
      }
    };

    if (id) {
      fetchWireDetail();
    }
  }, [id, apiUrl]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubmitObject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const bodyData = {
      data: submitObject,
      status: 'addEswire',
      estimate_id: id
    };
    try {
      const response = await axios.post(`${apiUrl}/estimate_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data[0] === 'success') {
        toast.success('Wire quantity saved!', {
          position: 'top-center',
        });
        // navigate(`/installation_details/${id}`);
      } else {
        toast.error(response.data.error || 'Failed updating.', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error("Oops minor issue with the data.", {
      position: "top-center"
      });
      
      if (error.response && error.response.status === 401) {
      toast.error("Session expired. Please login again.", {
      position: "top-center"
      });
      navigate('/login');
      } else if (error.response && error.response.status === 403) {
      toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
      position: "top-center"
      });
      }
    }
  };

  return (
    <>
      <div className="mx-auto max-w-[1200px] sm:px-6 lg:px-6 py-8 bg-white">
        <EstimateBar id={id} name={'Wire'} />
        <HeadingCp label={"Wire"} />
        <form onSubmit={handleSubmit} className="w-[800px] px-8 mx-auto">
          <div className="space-y-10">
            <div className="border-b border-gray-900/10 pb-12">
              <div className="flex flex-wrap gap-4">
                {/* Inputs for wires */}
                <div className="flex-1 flex flex-col space-y-1">
                  <label htmlFor="w14_1pr" className="text-sm font-medium text-gray-900">
                    #14 1PR @ $1,150
                  </label>
                  <input
                    type="number"
                    id="w14_1pr"
                    name="w14_1pr"
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={submitObject.w14_1pr}
                    onChange={handleChange}
                    required
                  />
                  {submitObject.w14_1pr === '' && (
                    <p className="text-red-600 text-sm">Required</p>
                  )}
                </div>

                <div className="flex-1 flex flex-col space-y-1">
                  <label htmlFor="w14_2pr" className="text-sm font-medium text-gray-900">
                    #14 2PR @ $1,725
                  </label>
                  <input
                    type="number"
                    id="w14_2pr"
                    name="w14_2pr"
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={submitObject.w14_2pr}
                    onChange={handleChange}
                    required
                  />
                  {submitObject.w14_2pr === '' && (
                    <p className="text-red-600 text-sm">Required</p>
                  )}
                </div>

                <div className="flex-1 flex flex-col space-y-1">
                  <label htmlFor="w16_1pr" className="text-sm font-medium text-gray-900">
                    #16 1PR @ $1,150
                  </label>
                  <input
                    type="number"
                    id="w16_1pr"
                    name="w16_1pr"
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={submitObject.w16_1pr}
                    onChange={handleChange}
                    required
                  />
                  {submitObject.w16_1pr === '' && (
                    <p className="text-red-600 text-sm">Required</p>
                  )}
                </div>

                <div className="flex-1 flex flex-col space-y-1">
                  <label htmlFor="w16_2pr" className="text-sm font-medium text-gray-900">
                    #16 2PR @ $1,725
                  </label>
                  <input
                    type="number"
                    id="w16_2pr"
                    name="w16_2pr"
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={submitObject.w16_2pr}
                    onChange={handleChange}
                    required
                  />
                  {submitObject.w16_2pr === '' && (
                    <p className="text-red-600 text-sm">Required</p>
                  )}
                </div>

                <div className="flex-1 flex flex-col space-y-1">
                  <label htmlFor="ci_cable" className="text-sm font-medium text-gray-900">
                    CI CABLES @ $3,450
                  </label>
                  <input
                    type="number"
                    id="ci_cable"
                    name="ci_cable"
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={submitObject.ci_cable}
                    onChange={handleChange}
                    required
                  />
                  {submitObject.ci_cable === '' && (
                    <p className="text-red-600 text-sm">Required</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900"
              onClick={() => navigate('/installation_details')}
            >
              Cancel
            </button>
            <SubmitButton children={'Save'} />
          </div>
        </form>
      </div>
    </>
  );
};

export default EstimateWire;
