import React, { useState } from 'react';
import EstimateFCSAdd from '../components/EstimateFCSAdd';
import EstimateDeviceAdd from '../components/EstimateDeviceAdd';
import EstimateDGPAdd from '../components/EstimateDGPAdd';
import CustomTabs from '../components/CustomTabs';
import EstimateBar from '../components/EstimateBar';
import { useParams } from 'react-router-dom';
import HeadingCp from '../components/HeadingCp';

const EstimateEquipmentAdd = () => {
  const [activeTab, setActiveTab] = useState('Panel'); // State to track the active tab
  const {id} = useParams();

  const tabs = [
    { label: 'Panel', onClick: () => setActiveTab('Panel') },
    { label: 'DGP', onClick: () => setActiveTab('DGP') },
    { label: 'Field Devices', onClick: () => setActiveTab('Field Devices') },
  ];

  return (
    <>
      <div className="mx-auto max-w-[1200px] sm:px-6 lg:px-6 py-8 bg-white">
        <EstimateBar id={id} name={'Equipment'}/>
        <HeadingCp label={'Equipments'} />
        <CustomTabs tabs={tabs} />
        {activeTab === 'Panel' && <EstimateFCSAdd />}
        {activeTab === 'DGP' && <EstimateDGPAdd />}
        {activeTab === 'Field Devices' && <EstimateDeviceAdd />}
      </div>
    </>
  );
};

export default EstimateEquipmentAdd;
