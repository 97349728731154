import { Fragment, useEffect, useState } from 'react';
import { useUserData } from '../context/UserData';
import { CheckCircleIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid'; // Import edit and delete icons
import AvatarLocation from '../components/AvatarLocation';
import DateFormat from './DateFormat';
import useSubmitPost from '../hooks/handleSubmitPost';
import { getTodaysDateUtil } from '../utils/DateFormatTodayUtil';
import { dateToUnixTimestamp } from '../utils/dateToUnixTimestamp';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const WipComments = ({ notes, api, redirectPath, val, bodyData, id, handleNoteChangeParent }) => {

  const handleSubmit = useSubmitPost();
  const userData = useUserData();
  const [noteText, setNoteText] = useState('');
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [filterNote, setFilterNote] = useState([]);

  useEffect(() => {
    setFilterNote(notes)
  }, [notes]);

  const formatName = (fullName) => {
    const parts = fullName.toLowerCase().split(' ');
    if (parts.length < 2) {
      return '';
    }
    const firstName = parts[0];
    const lastName = parts[1];
    return `${firstName[0]}${lastName}`;
  };

  const handleNoteChange = (event) => {
    setNoteText(event.target.value);
  };

  const name = userData?.users.first_name + " " + userData?.users.last_name;

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const updatedBodyData = {
    ...bodyData,
    data: {
      ...bodyData.data,
      notes: noteText,
      wip_id: id,
      user_id: userData?.users.id,
      employee_name: name
    }
  };

    try {
      // Making the API call with axios
      const response = await axios.post(api, updatedBodyData, {
        headers: { 'Content-Type': 'application/json' }
      });

      // Assuming the backend returns the newly created/updated note with an ID
      const newNote = {
        id: response.data.id,  // Use the ID returned by the backend
        employee_name: name,
        date: new Date().toISOString(),
        notes: noteText,
        wip_id: id,
        user_id: userData?.users.id
      };

      // Call the parent callback to update the notes
      handleNoteChangeParent(id, newNote);

      // Provide user feedback
      toast.info('Saved!', {
        position: 'top-center'
      });

      // Reset the form and state after submission
      // setBodyData({
      //   status: 'submit_days',
      //   current_date: '',
      //   data: {}
      // });
      setNoteText('');  // Clear the textarea
      setEditingNoteId(null);  // Reset the editing state

    } catch (error) {
      const errorMsg = `Error submitting note`;
      if (error.response) {
        console.error(`${errorMsg}`, error.response.data);
      } else if (error.request) {
        console.error(`${errorMsg}`, error.request);
      } else {
        console.error('Error', error.message);
      }
    }
  };

  const handleDelete = async(noteId, noteIdx) => {
    // Logic to handle delete action
    // setLoading(true);
    const bodyData = {
      status: 'delete_note',
      data: { id:noteId }
    };
    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data[0] === 'success') {
        setFilterNote(notes.filter(item => item.id !== noteId));  
  
        toast.success('Saved!', { position: 'top-center' });
      } else {
        toast.error('error updating', { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error updating status', error);
    } finally {
      // setLoading(false);
    }
  };

  const handleEdit = (note) => {
    // Prefill the textarea with the note text for editing
    setNoteText(note.notes);
    setEditingNoteId(note.id);
  };

  return (
    <>
      <ul role="list" className="space-y-6">
        {
          filterNote.map((note, noteIdx) => (
            <li key={note.id} className="relative flex gap-x-4">
              <div className={classNames(
                noteIdx === notes.length - 1 ? 'h-6' : '-bottom-6',
                'absolute left-0 top-0 flex w-6 justify-center')}>
                <div className="w-px bg-gray-200" />
              </div>
              <>
                <AvatarLocation avatar={formatName(note.employee_name) + ".jpeg"} className={"relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"} />
                <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                  <div className="flex justify-between gap-x-4">
                    <div className="py-0.5 text-xs leading-5 text-gray-500">
                      <span className="font-medium text-gray-900">{note.employee_name}</span> commented
                    </div>
                    <span className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                      <DateFormat date={note.date} />
                    </span>
                  </div>
                  <p className="text-sm leading-6 text-gray-500">{note.notes}</p>
                  {note.date.split('T')[0] === getTodaysDateUtil() && (
                    <>
                      {name === note.employee_name && (
                        <div className="flex space-x-2 mt-2">
                          {/* <button onClick={() => handleEdit(note)} className="text-blue-500 hover:text-blue-700">
                            <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
                          </button> */}
                          <button onClick={() => handleDelete(note.id, noteIdx)} className="text-red-500 hover:text-red-700">
                            <TrashIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            </li>
          ))
        }
      </ul>

      {/* New comment form */}
      <div className="mt-6 flex gap-x-3">
        <AvatarLocation className="h-6 w-6 flex-none rounded-full bg-gray-50" avatar={userData?.avatar ? userData?.avatar : ''} />
        <form className="relative flex-auto" onSubmit={handleFormSubmit}>
          <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label htmlFor="notes" className="sr-only">
              {editingNoteId ? 'Edit your comment' : 'Add your comment'}
            </label>
            <textarea
              rows={2}
              name="notes"
              id="notes"
              value={noteText}
              onChange={handleNoteChange}
              className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder={editingNoteId ? 'Edit your comment...' : 'Add your comment...'}
              required={true}
            />
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <div className="flex items-center space-x-5"></div>
            <button
              type="submit"
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              {editingNoteId ? 'Update' : 'Comment'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default WipComments;
