import React from 'react'

const FormZip = ({val, onChange}) => {
  return (
    <> 
        <div className="sm:col-span-2">
          <label htmlFor="zip_code" className="block text-sm font-medium leading-6 text-gray-900">
            ZIP <span className='text-red-500'>*</span>
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="zip_code"
              id="zip_code"
              value={val || ''} 
              onChange={onChange} 
              autoComplete="zip_code"
              required={true}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 onChange-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
    </>
  )
}

export default FormZip