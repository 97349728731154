import React from 'react'
import PhoneNumber from './PhoneNumber'

const CustomerCardBillto = ({customer}) => {
  return (
    <>
        <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
          <div className="flex flex-wrap gap-x-4 py-3 divide-y gap-y-4">
            {customer.billto && customer.billto.map((bill, index) => (
                <address key={index} className={` pl-6 pt-2 not-italic text-gray-600 w-full ${index > 1 ? 'mt-4' : ''}`}>
                  <p className="font-bold">{bill.name}</p>
                  <p>{bill.primary_contact} <PhoneNumber number={bill.main_phone} /></p>
                  <p>{bill.main_phone_contact}</p>
                  <p>{bill.address_1}, {bill.address_2}</p>
                  <p>{bill.city}, {bill.state} {bill.zip_code}</p>
                  <p>{bill.main_email}</p>
                </address>
              ))}
          </div>
        </dl>
    </>
  )
}

export default CustomerCardBillto