import React from 'react';

const TechTimeEntryCp = ({ handleChange, timesheetLog }) => {
  return (
    <div className="space-y-4 sm:col-span-6">
      <div className="form-group flex space-x-4">
        <div className="flex-1">
          <label htmlFor="time_in" className="block text-sm font-medium text-gray-700">
            Time IN:
          </label>
          <input
            type="time"
            id="time_in"
            name="time_in"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={timesheetLog.time_in || ''}
            onChange={handleChange}
            placeholder="HH:mm:ss"
            min="01:00:00"
            max="24:00:00"
            required
          />
          {timesheetLog.time_in === '' && <p className="text-red-500 text-xs mt-1">Required</p>}
        </div>

        <div className="flex-1">
          <label htmlFor="time_out" className="block text-sm font-medium text-gray-700">
            Time OUT:
          </label>
          <input
            type="time"
            id="time_out"
            name="time_out"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={timesheetLog.time_out || ''}
            onChange={handleChange}
            placeholder="HH:mm:ss"
            min="01:00:00"
            max="24:00:00"
            required
          />
          {timesheetLog.time_out === '' && <p className="text-red-500 text-xs mt-1">Required</p>}
        </div>
      </div>

      <div className="form-group flex space-x-4">
        <div className="flex-1 flex items-center space-x-2">
          <label htmlFor="reg_hrs" className="block text-sm font-medium text-gray-700">
            Regular Hrs:
          </label>
          <input
            type="number"
            id="reg_hrs"
            name="reg_hrs"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={timesheetLog.reg_hrs || ''}
            onChange={handleChange}
            max="10"
            required
          />
        </div>

        <div className="flex-1 flex items-center space-x-2">
          <label htmlFor="over_hrs" className="block text-sm font-medium text-gray-700">
            Overtime Hrs:
          </label>
          <input
            type="number"
            id="over_hrs"
            name="over_hrs"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={timesheetLog.over_hrs || ''}
            onChange={handleChange}
            max="10"
          />
        </div>
      </div>
      
      <hr className="my-4" />

      <div className="form-group">
        <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
          Notes:
        </label>
        <textarea
          id="notes"
          name="notes"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          rows="3"
          value={timesheetLog.notes || ''}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default TechTimeEntryCp;
