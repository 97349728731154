import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import SearchBarCP from '../components/SearchBarCP';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomerCardHeading from '../components/CustomerCardHeading';
import CustomerCardBillto from '../components/CustomerCardBillto';
import LoadingSpinner from '../components/LoadingSpinner';


const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [holdCustomers, setHoldCustomers] = useState([]);
  const [filterCustomers, setFilterCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewHoldList, setViewHoldList] = useState(false);
  const searchTermRef = useRef('');
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchCustomers();
  }, [viewHoldList]);

  useEffect(() => {
    const filtered = searchTermRef.current
      ? customers.filter(customer => customer.customer_name.toLowerCase().includes(searchTermRef.current.toLowerCase()))
      : customers;
    setFilterCustomers(filtered);
  }, [customers]);

  const fetchCustomers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/customer_crud.php?getList=1`);
      if (response.data && !response.data.error) {
        setCustomers(response.data);
        setFilterCustomers(response.data);
      } else {
        toast.error('No Customer Found!!', {
          position: 'top-center'
        });
      }
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    searchTermRef.current = value;
    const filtered = value
      ? customers.filter(customer => customer.customer_name.toLowerCase().includes(value.toLowerCase()))
      : customers;
    setFilterCustomers(filtered);
  };

  const handleSearchClick = async () => {
    if(searchTermRef.current === '')
    {
         toast.info('entry is empty', {
          position: 'top-center'
        });
        return
    }
    setLoading(true);    
    const bodyData = {
      status: 'search',
      data: { search: searchTermRef.current },
    };

    try {
      const response = await axios.post(`${apiUrl}/customer_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data.search_results && Array.isArray(response.data.search_results)) {
        setCustomers(response.data.search_results);
        setFilterCustomers(response.data.search_results);
      } else {
        setCustomers([]);
        setFilterCustomers([]);
        toast.error('No results found for ' + searchTermRef.current, {
          position: 'top-center'
        });
      }
    } catch (error) {
      console.error('Error searching customers', error);
    } finally {
      setLoading(false);
    }
  };

  const holdListButton = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/customer_crud.php?getListHold=1`);
      if (response.data && !response.data.error) {
        setHoldCustomers(response.data.holdList);
        setFilterCustomers(response.data.holdList);
        setViewHoldList(true);
      } else {
        toast.error('No data found on credit hold', {
          position: 'top-center'
        });
      }
    } catch (error) {
      console.error('Error fetching hold list', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMainCustomerList = () => {
    setViewHoldList(false);
    fetchCustomers();
  };

  return (
    <>
      <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-2xl font-semibold text-gray-500 mb-8">Customer List</h1>

        <div className="flex space-x-3 items-center mb-10">
          <NavLink to="/customer/edit/new">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white 
                       bg-blue-400 hover:bg-blue-700 focus:outline-none
                         focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              New Customer
            </button>
          </NavLink>

          {viewHoldList ? (
            <button
              type="button"
              onClick={fetchMainCustomerList}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm
                text-white bg-green-400 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
              Customer List
            </button>
          ) : (
            <button
              type="button"
              onClick={holdListButton}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm
                text-white bg-orange-400 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
              On Hold List
            </button>
          )}
          <SearchBarCP search={searchTermRef.current} handleChange={handleSearchChange} onClick={handleSearchClick} />
        </div>

        {loading ? (
          <LoadingSpinner children={'Loading...'} />
        ) : (
          <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
            {filterCustomers.map((customer, index) => (
              <li key={index} className="bg-white rounded-lg shadow p-6">
                <CustomerCardHeading customer={customer} />
                <div className="mt-4">
                  {customer.active ? (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-white bg-green-500">
                      <svg className="h-1.5 w-1.5 fill-white" viewBox="0 0 6 6" aria-hidden="true">
                        <circle cx={3} cy={3} r={3} />
                      </svg>
                      Active
                    </span>
                  ) : (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-white bg-red-500">
                      <svg className="h-1.5 w-1.5 fill-white" viewBox="0 0 6 6" aria-hidden="true">
                        <circle cx={3} cy={3} r={3} />
                      </svg>
                      Not Active
                    </span>
                  )}
                </div>
                <CustomerCardBillto customer={customer} />
              </li>
            ))}
          </ul>
        )}
        
      </div>
    </>
  );
};

export default CustomerList;
